import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const query = gql`
  query Provinces {
    list: provinces {
      name
    }
  }
`

export default (params?: any) =>
  useQuery(query, { fetchPolicy: 'no-cache', ...params })
