import * as y from 'yup'
import { useFormik } from 'formik'
import { cpf, cnpj, select } from 'utils/validation'

const useSignUp: FormTypes.Hook = ({ onSubmit, initialValues }) => {
  const formik = useFormik({
    onSubmit,
    initialValues,
    enableReinitialize: true,
    validationSchema: y.object().shape({
      contractorKind: select,
      name: y.string().required('Obrigatório'),
      document: y
        .string()
        .test('valid-cpf', 'CPF Inválido', function(value) {
          return value ? cpf(value) : true
        })
        .required('Obrigatório'),
      email: y
        .string()
        .email('E-mail inválido')
        .required('Obrigatório'),
      mobilePhone: y
        .string()
        .min(14, 'Celular inválido')
        .required('Obrigatório'),
      password: y.string().required('Obrigatório'),
      passwordConfirmation: y
        .string()
        .oneOf([y.ref('password')], 'As senhas não são iguais')
        .required('Obrigatório'),
      companyName: y.string().required('Obrigatório'),
      companyDocument: y
        .string()
        .test('valid-cnpj', 'CNPJ Inválido', function(value) {
          return value ? cnpj(value) : true
        })
        .required('Obrigatório'),
      companyPhone: y.string().required('Obrigatório'),
      fantasyName: y.string().required('Obrigatório')
    })
  })

  return formik
}

export default useSignUp
