import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const mutation = gql`
  mutation CreateProfile(
    $companyId: ID
    $name: String!
    $email: String!
    $document: String!
    $mobilePhone: String
  ) {
    createdProfile: createProfile(
      name: $name
      email: $email
      companyId: $companyId
      document: $document
      mobilePhone: $mobilePhone
    ) {
      id
    }
  }
`

export default () => {
  const [createProfile, result] = useMutation(mutation, { onError: () => null })

  return [createProfile, result]
}
