import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const mutation = gql`
  mutation UpdateFullVehicle(
    $vehicle: UpdateVehicleAttributes
    $owner: UpdateOwnerAttributes
    $lessee: UpdateOwnerAttributes
  ) {
    updated: updateFullVehicle(
      vehicle: $vehicle
      owner: $owner
      lessee: $lessee
    ) {
      id
    }
  }
`

export default () => {
  const [updateFullVehicle, result] = useMutation(mutation, {
    fetchPolicy: 'no-cache'
  })

  return [updateFullVehicle, result]
}
