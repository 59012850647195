import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const query = gql`
  query FreightOffers($freightId: ID) {
    offers(freightId: $freightId, filter: { status: [accepted] }) {
      id
      type: offerType
      value: offerValue
      status
      histories {
        action
        position
        createdAt
        message
      }
      freight {
        waypoints {
          avariesUrls
          deliveryProofUrl
        }
        responsible {
          futurePosition
          approvedDocuments {
            url
          }
        }
      }
      vehicleAssembly {
        company {
          address
          city {
            id
            name
            state {
              initials
            }
          }
          district
          complement
          document
          fantasyName
          name
          kind
          phone
          number
          zipCode
        }
        truck {
          chassis
          axis
          capacityKg
          capacityM3
          document
          licensePlate
          nationality
          renavam
          tare
          vehicleFloor
          year
          anttDueDate
          anttRntrc
          city {
            id
            name
            state {
              initials
            }
          }
          bodywork: vehicleBodywork {
            value: id
            label: description
          }
          brand: vehicleBrand {
            value: id
            label: description
          }
          category: vehicleCategory {
            value: id
            label: description
            kind
          }
          color: vehicleColor {
            value: id
            label: description
          }
          vehicleDocument {
            url
            status
          }
          model: vehicleModel {
            value: id
            label: description
          }
          tracker: vehicleTracker {
            value: id
            label: description
          }
          owners: vehicleOwners {
            name
            phone
            kind
            email
            document
          }
        }
        implement1 {
          chassis
          axis
          capacityKg
          capacityM3
          document
          licensePlate
          nationality
          renavam
          tare
          vehicleFloor
          year
          anttDueDate
          anttRntrc
          city {
            id
            name
            state {
              initials
            }
          }
          bodywork: vehicleBodywork {
            value: id
            label: description
          }
          brand: vehicleBrand {
            value: id
            label: description
          }
          category: vehicleCategory {
            value: id
            label: description
            kind
          }
          color: vehicleColor {
            value: id
            label: description
          }
          vehicleDocument {
            url
            status
          }
          model: vehicleModel {
            value: id
            label: description
          }
          tracker: vehicleTracker {
            value: id
            label: description
          }
          owners: vehicleOwners {
            name
            phone
            kind
            email
            document
          }
        }
        implement2 {
          chassis
          axis
          capacityKg
          capacityM3
          document
          licensePlate
          nationality
          renavam
          tare
          vehicleFloor
          year
          anttDueDate
          anttRntrc
          city {
            id
            name
            state {
              initials
            }
          }
          bodywork: vehicleBodywork {
            value: id
            label: description
          }
          brand: vehicleBrand {
            value: id
            label: description
          }
          category: vehicleCategory {
            value: id
            label: description
            kind
          }
          color: vehicleColor {
            value: id
            label: description
          }
          vehicleDocument {
            url
            status
          }
          model: vehicleModel {
            value: id
            label: description
          }
          tracker: vehicleTracker {
            value: id
            label: description
          }
          owners: vehicleOwners {
            name
            phone
            kind
            email
            document
          }
        }
        implement3 {
          chassis
          axis
          capacityKg
          capacityM3
          document
          licensePlate
          nationality
          renavam
          tare
          vehicleFloor
          year
          anttDueDate
          anttRntrc
          city {
            id
            name
            state {
              initials
            }
          }
          bodywork: vehicleBodywork {
            value: id
            label: description
          }
          brand: vehicleBrand {
            value: id
            label: description
          }
          category: vehicleCategory {
            value: id
            label: description
            kind
          }
          color: vehicleColor {
            value: id
            label: description
          }
          vehicleDocument {
            url
            status
          }
          model: vehicleModel {
            value: id
            label: description
          }
          tracker: vehicleTracker {
            value: id
            label: description
          }
          owners: vehicleOwners {
            name
            phone
            kind
            email
            document
          }
        }
        implement4 {
          chassis
          axis
          capacityKg
          capacityM3
          document
          licensePlate
          nationality
          renavam
          tare
          vehicleFloor
          year
          anttDueDate
          anttRntrc
          city {
            id
            name
            state {
              initials
            }
          }
          bodywork: vehicleBodywork {
            value: id
            label: description
          }
          brand: vehicleBrand {
            value: id
            label: description
          }
          category: vehicleCategory {
            value: id
            label: description
            kind
          }
          color: vehicleColor {
            value: id
            label: description
          }
          vehicleDocument {
            url
            status
          }
          model: vehicleModel {
            value: id
            label: description
          }
          tracker: vehicleTracker {
            value: id
            label: description
          }
          owners: vehicleOwners {
            name
            phone
            kind
            email
            document
          }
        }
        driver {
          address
          complement
          district
          document
          driverLicense
          driverLicenseDueDate
          driverLicenseFirstDate
          driverLicenseType {
            value: id
            label: description
          }
          email
          employeeTime
          employeeTimeKind
          identityCard
          mobilePhone
          name
          nationality
          number
          phone
          rating
          zipCode
          city {
            id
            name
            state {
              initials
            }
          }
          birthDate
          driverLicenseIssuingState
          driverLicenseRenach
          fatherName
          issuingState
          motherName
          personalContactKind
          personalContactName
          personalContactPhone
          professionalContactCompany
          professionalContactKind
          professionalContactName
          professionalContactPhone
          status
        }
      }
    }
  }
`
export default (variables?: Record<string, any>) =>
  useQuery(query, {
    variables,
    fetchPolicy: 'no-cache'
  })
