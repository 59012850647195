import './style.css'
import React from 'react'
import navbar from 'config/navbar'
import { useCurrentUser } from 'queries'
import { useLocation, useHistory } from 'react-router-dom'

const Sidebar: React.FC<SidebarType.Props> = () => {
  const history = useHistory()
  const location = useLocation()
  const { data } = useCurrentUser()

  const navItem = (item: any) => {
    const active = location.pathname === item.url ? 'active' : ''

    return (
      <li className='nav-item' key={item.url}>
        <a
          aria-current='page'
          className={`nav-link ${active}`}
          onClick={() => history.push(item.url)}
        >
          <div className='nav-link-container'>
            <img
              className={`custom-icon ${active}`}
              src={item.icon}
              alt={`${item.alt}`}
            />
            {item.name}
          </div>
        </a>
      </li>
    )
  }

  const navItems = () => {
    return navbar.items.map((item: SidebarType.NavbarItem) => {
      const backOfficeUser = data?.currentUser?.role === 'back_office'

      const backOfficeRoutes = [
        '/dashboard',
        '/usuarios',
        '/fretes',
        '/motoristas',
        '/veiculos',
        '/analises-de-risco'
      ]

      const regularRoutes = [
        '/empresa',
        '/usuarios',
        '/fretes',
        '/configuracoes'
      ]

      return !backOfficeUser
        ? regularRoutes.includes(item.url) && navItem(item)
        : backOfficeRoutes.includes(item.url) && navItem(item)
    })
  }

  return <ul className='nav'>{navItems()}</ul>
}

export default Sidebar
