import Form from './Form'
import React, { useEffect } from 'react'
import { useInsuranceConfig } from 'queries'
import { useTecnoriskConfigForm } from 'forms'
import { useUpdateTecnoriskConfig } from 'mutations'
import { Card, CardHeader, CardBody } from 'reactstrap'
import { notify } from 'services'

const Config: React.FC = () => {
  const { data, loading } = useInsuranceConfig()
  const [
    updateConfig,
    { data: updated, loading: updating }
  ]: any = useUpdateTecnoriskConfig()

  const initialValues: FormTypes.TecnoriskConfig = {
    id: data?.company?.id || '',
    login: '',
    password: '',
    transporter: '',
    transportingUnit:
      data?.company?.insuranceConfig?.tecnorisk?.transporting_unit || '',
    ...data?.company?.insuranceConfig?.tecnorisk
  }

  // @ts-ignore
  delete initialValues['transporting_unit']

  const formik = useTecnoriskConfigForm({
    onSubmit: (values) => {
      const tecnorisk = { ...values }

      delete tecnorisk['id']
      delete tecnorisk['tecnorisk']

      !values.password && delete tecnorisk['password']

      updateConfig({ variables: { tecnorisk, id: values.id } })
    },
    initialValues
  })

  useEffect(() => {
    if (updated?.config?.id) {
      notify({
        type: 'success',
        description: 'Configurações atualizadas'
      })
    }
  }, [updated])

  return (
    <Card>
      <CardHeader>Gerenciamento de risco (Tecnorisk)</CardHeader>
      <CardBody>
        <Form formik={formik} loading={updating} />
      </CardBody>
    </Card>
  )
}

export default Config
