const mod11 = (num: any) => num % 11
const NOT = (x: any) => !x
const isEqual = (a: any) => (b: any) => b === a
const mergeDigits = (num1: any, num2: any) => `${num1}${num2}`
const getTwoLastDigits = (cpf: any) => `${cpf[9]}${cpf[10]}`
const getCpfNumeral = (cpf: any) => cpf.substr(0, 9).split('')

const isRepeatingChars = (str: any) =>
  str.split('').every((elem: any) => elem === str[0])

const toSumOfProducts = (multiplier: any) => (result: any, num: any, i: any) =>
  result + num * multiplier--

const getSumOfProducts = (list: any, multiplier: number) =>
  list.reduce(toSumOfProducts(multiplier), 0)

const getValidationDigit = (multiplier: any) => (cpf: any) =>
  getDigit(mod11(getSumOfProducts(cpf, multiplier)))

const getDigit = (num: any) => (num > 1 ? 11 - num : 0)

const isRepeatingNumbersCpf = isRepeatingChars

const isValidCPF = (cpf: string) => {
  const CPF = getCpfNumeral(cpf)
  const firstDigit = getValidationDigit(10)(CPF)
  const secondDigit = getValidationDigit(11)(CPF.concat(firstDigit))

  return isEqual(getTwoLastDigits(cpf))(mergeDigits(firstDigit, secondDigit))
}

const isCPF = (CPF: string) =>
  NOT(isRepeatingNumbersCpf(CPF.replace(/([.-])/g, ''))) &&
  isValidCPF(CPF.replace(/([.-])/g, ''))

export default isCPF
