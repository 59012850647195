import React from 'react'
import Spinner from '../Spinner'
import ShouldRender from '../ShouldRender'
import { Button } from 'reactstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import './style.css'

const Table: React.FC<TableType.Props> = ({
  columns,
  data,
  loading,
  onRowClick
}) => {
  const createColumns = () => {
    return columns.map((column, index) => {
      if (column.actions?.length) {
        return (
          <TableHeaderColumn
            key={index}
            dataFormat={(_, row) => createActions(column.actions, row)}
          />
        )
      }
      return (
        <TableHeaderColumn
          key={index}
          dataField={column.dataField}
          isKey={column.isKey}
          dataSort={column.sortable}
          dataFormat={column.formatter}
        >
          {column.title}
        </TableHeaderColumn>
      )
    })
  }

  const createActions: TableType.CreateActions = (actions, row) => {
    return (
      <ShouldRender if={actions!.length > 0}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
          className='text-right'
        >
          {actions!.map((item: TableType.Action, index: number) => {
            return item.buttonLabel &&
              row.status.toLowerCase() === 'não publicado' ? (
              <Button
                outline
                className='action-button green-button'
                onClick={() => item.action(row)}
              >
                {item.buttonLabel}
              </Button>
            ) : (
              <React.Fragment key={index}>
                <i
                  className={`action-button ${item.classname}`}
                  onClick={() => item.action(row)}
                />
              </React.Fragment>
            )
          })}
        </div>
      </ShouldRender>
    )
  }

  return (
    <React.Fragment>
      <BootstrapTable
        data={data}
        hover
        version='4'
        bordered={false}
        options={{
          noDataText: 'Nenhum dado encontrado',
          onRowClick: (row) => {
            onRowClick && onRowClick(row)
          }
        }}
        trStyle={{ cursor: onRowClick ? 'pointer' : 'auto' }}
      >
        {createColumns()}
      </BootstrapTable>
      <ShouldRender if={!!loading}>
        <Spinner />
      </ShouldRender>
    </React.Fragment>
  )
}

export default Table
