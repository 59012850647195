import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const mutation = gql`
  mutation RateAsCompany($comment: String, $freightId: ID!, $rate: Int!) {
    rated: rateAsCompany(
      comment: $comment
      freightId: $freightId
      rate: $rate
    ) {
      id
    }
  }
`

export default () => {
  const [rateAsCompany, result] = useMutation(mutation, {
    onError: () => null,
    refetchQueries: ['Rating']
  })

  return [rateAsCompany, result]
}
