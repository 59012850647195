import React from 'react'
import { Col, Row, Spinner as ReactSpinner } from 'reactstrap'

const Spinner: React.FC = () => {
  return (
    <Row>
      <Col className='text-center mb-4'>
        <ReactSpinner color='warning' />
      </Col>
    </Row>
  )
}

export default Spinner
