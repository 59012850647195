import gql from 'graphql-tag'

export default gql`
  query LazyPaginatedProfiles($after: String) {
    paginatedProfiles(first: 30, after: $after) {
      nodes {
        id
        name
        adminRole
        negotiatorRole
        company {
          name
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
`
