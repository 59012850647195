import { Dashboard, Users, User, Freights, FreightDetails } from 'screens'

import Drivers from '../../app/screens/Drivers'
import Driver from '../../app/screens/Driver'
import Vehicles from '../../app/screens/Vehicles'
import Vehicle from '../../app/screens/Vehicle'
import RiskAnalysis from '../../app/screens/RiskAnalysis'

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/usuarios',
    name: 'Usuários',
    component: Users
  },
  {
    path: '/usuario',
    name: 'Usuário',
    component: User
  },
  {
    path: '/fretes',
    name: 'Fretes',
    component: Freights,
    exact: true
  },
  {
    path: '/fretes/:id/:currentArea',
    name: 'Detalhes do frete',
    component: FreightDetails
  },

  {
    path: '/motoristas',
    name: 'Motoristas',
    component: Drivers,
    exact: true
  },
  {
    path: '/motoristas/:id',
    name: 'Detalhes do motorista',
    component: Driver
  },
  {
    path: '/veiculos',
    name: 'Veículos',
    component: Vehicles,
    exact: true
  },
  {
    path: '/veiculos/:id',
    name: 'Detalhes do veículo',
    component: Vehicle
  },
  {
    path: '/analises-de-risco',
    name: 'Análises de risco',
    component: RiskAnalysis
  }
]

export default routes
