import {
  Users,
  User,
  Freight,
  Freights,
  FreightDetails,
  Company,
  Config
} from 'screens'

import Payment from '../../app/screens/Payment'

const routes = [
  {
    path: '/empresa',
    name: 'Empresa',
    component: Company
  },
  {
    path: '/usuarios',
    name: 'Usuários',
    component: Users
  },
  {
    path: '/usuario',
    name: 'Usuário',
    component: User
  },
  {
    path: '/frete',
    name: 'Novo frete',
    component: Freight,
    exact: true
  },
  {
    path: '/fretes',
    name: 'Fretes',
    component: Freights,
    exact: true
  },
  {
    path: '/fretes/:id/:currentArea',
    name: 'Detalhes do frete',
    component: FreightDetails
  },
  {
    path: '/configuracoes',
    name: 'Configurações',
    component: Config
  },
  {
    path: '/creditos',
    name: 'Creditos',
    component: Payment
  }
]

export default routes
