import { notify } from '../services'
import { useState, useEffect } from 'react'
import useAsyncEffect from 'use-async-effect'
import { useUpdateProfile, useUpdateRoles } from 'mutations'

const useUpdateProfileAndRoles: UpdateProfileAndRolesType.Hook = () => {
  const [loading, setLoading] = useState(false)
  const [fields, setFields] = useState<UpdateProfileAndRolesType.Props>({
    id: '',
    name: '',
    email: '',
    mobilePhone: '',
    document: '',
    roles: []
  })
  const [
    updateRoles,
    { data: roles, error: rolesError }
  ]: any = useUpdateRoles()
  const [
    updateProfile,
    { data: profile, error: profileError }
  ]: any = useUpdateProfile()

  const updateProfileData = (props: UpdateProfileAndRolesType.Props) => {
    setFields(props)
    setLoading(true)

    const variables = { ...props }
    delete variables['roles']

    return updateProfile({ variables })
  }

  useAsyncEffect(() => {
    if (profile?.updatedProfile?.id) {
      return updateRoles({
        variables: {
          id: fields.id,
          adminRole: fields.roles.indexOf('admin') > -1,
          negotiatorRole: fields.roles.indexOf('negotiator') > -1
        }
      })
    }
  }, [profile])

  useAsyncEffect(() => {
    if (roles?.updatedRoles?.id) {
      setLoading(false)

      return notify({
        type: 'success',
        description: 'Usuário atualizado'
      })
    }
  }, [roles])

  useEffect(() => {
    setLoading(false)
  }, [rolesError, profileError])

  return [updateProfileData, loading]
}

export default useUpdateProfileAndRoles
