import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const mutation = gql`
  mutation UpdateRole(
    $id: ID!
    $adminRole: Boolean
    $driverRole: Boolean
    $negotiatorRole: Boolean
  ) {
    updatedRoles: restrictedUpdateProfile(
      id: $id
      adminRole: $adminRole
      driverRole: $driverRole
      negotiatorRole: $negotiatorRole
    ) {
      id
    }
  }
`

export default () => {
  const [updateRoles, result] = useMutation(mutation, {
    onError: () => null,
    refetchQueries: ['Profiles']
  })

  return [updateRoles, result]
}
