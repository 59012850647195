import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const mutation = gql`
  mutation AddCredit(
    $cardNumber: String!
    $expirationMonth: Int!
    $expirationYear: Int!
    $holderName: String!
    $planId: ID!
    $securityCode: Int!
  ) {
    added: addCredit(
      cardNumber: $cardNumber
      expirationMonth: $expirationMonth
      expirationYear: $expirationYear
      holderName: $holderName
      planId: $planId
      securityCode: $securityCode
    ) {
      id
    }
  }
`

export default () => {
  const [addCredit, result] = useMutation(mutation, {
    refetchQueries: ['Plans', 'Company'],
    onError: () => null
  })

  return [addCredit, result]
}
