import React from 'react'
import ReactSelect from 'react-select'
import columnSize from 'utils/columnSize'
import ShouldRender from '../ShouldRender'
import ReactAsyncSelect from 'react-select/async'

import { FormGroup, Label, FormFeedback, Col } from 'reactstrap'

const Select: React.FC<SelectType.Props> = ({
  name,
  value,
  options,
  label,
  size = 12,
  disabled,
  error,
  loading,
  setFieldValue,
  setFieldTouched,
  loadOptions,
  defaultOptions
}) => {
  return (
    <Col {...columnSize(size)}>
      <FormGroup className='custom-form-group'>
        <Label>{label}</Label>
        <ShouldRender if={!loadOptions}>
          <ReactSelect
            name={name}
            value={value}
            options={options}
            isLoading={loading}
            isDisabled={disabled}
            placeholder='Selecione'
            onBlur={() => setFieldTouched(name, true, true)}
            noOptionsMessage={() => 'Nenhuma opção disponível'}
            onChange={(x: Record<string, any>) => setFieldValue(name, x)}
          />
        </ShouldRender>

        <ShouldRender if={loadOptions}>
          <ReactAsyncSelect
            name={name}
            value={value}
            loading={loading}
            isDisabled={disabled}
            placeholder='Selecione'
            loadOptions={loadOptions}
            defaultOptions={defaultOptions}
            onBlur={() => setFieldTouched(name, true, true)}
            noOptionsMessage={() => 'Nenhuma opção disponível'}
            onChange={(x: Record<string, any>) => setFieldValue(name, x)}
          />
        </ShouldRender>

        <FormFeedback className={`form-message ${error ? 'show-error' : ''}`}>
          {error}
        </FormFeedback>
      </FormGroup>
    </Col>
  )
}

export default Select
