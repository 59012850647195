import idx from 'idx'

const getError = (
  getFieldMeta: (fieldName: string) => any,
  fieldName: string
) => {
  const { touched, error } = getFieldMeta(fieldName)

  return touched && error
    ? typeof error === 'object'
      ? idx(error, (_) => _.value) || null
      : error
    : null
}

export default getError
