import './App.scss'
import { ToastContainer } from 'react-toastify'
import React, { useState, useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.min.css'
import { ApolloProvider } from '@apollo/react-hooks'
import { Route, Switch } from 'react-router-dom'
import { ShouldRender } from 'components'
import { cachePersistor, ApolloClientInstance, dropApolloCache } from 'services'
import { MainApp, Login, PasswordRecovery, SignUp, Terms } from 'screens'

const App: React.FC = () => {
  const [persisted, setPersisted] = useState(false)

  useEffect(() => {
    const persistCache = async () => {
      try {
        await cachePersistor.restore()
        await cachePersistor.persist()
        setPersisted(true)
      } catch (e) {
        console.log('cannot persist cache')
      }
    }

    persistCache()
  }, [])

  return (
    <ShouldRender if={persisted}>
      <ApolloProvider client={ApolloClientInstance}>
        <div className='app'>
          <ToastContainer
            position='bottom-right'
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable
            pauseOnHover
          />
          <Switch>
            <Route exact path='/login' name='Login' component={Login} />
            <Route
              exact
              path='/recuperar-senha'
              name='Recuperar Senha'
              component={PasswordRecovery}
            />
            <Route exact path='/cadastro' name='Cadastro' component={SignUp} />
            <Route
              exact
              path='/politica-de-privacidade'
              name='Política de Privacidade'
              component={Terms}
            />
            {/* <Route exact path='/404' name='Page 404' component={Page404} />
            <Route exact path='/500' name='Page 500' component={Page500} /> */}
            <Route path='/' name='Home' component={MainApp} />
          </Switch>
        </div>
      </ApolloProvider>
    </ShouldRender>
  )
}

export default App
