import React from 'react'
import { authCode } from 'utils/mask'
import { TextInput, Spinner, ShouldRender } from 'components'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const ConfirmCode: React.FC<SignUpType.ConfirmCode> = ({
  visible,
  toggle,
  confirm,
  code,
  setCode,
  message,
  loading
}) => {
  return (
    <Modal isOpen={visible} className='lineless-modal'>
      <ModalHeader>{loading ? message : 'Confirme seu telefone'}</ModalHeader>

      <ShouldRender if={loading}>
        <Spinner />
      </ShouldRender>

      <ShouldRender if={!loading}>
        <React.Fragment>
          <ModalBody>
            Informe o código de confirmação que enviamos para o seu telefone
            <br />
            <small>O código pode levar alguns minutos para chegar.</small>
            <TextInput
              name='code'
              value={code}
              onChange={(event) => setCode(authCode(event.target.value))}
              style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 20 }}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={confirm}
              disabled={code.length !== 6}
              className='btn-primary-yellow'
            >
              Confirmar
            </Button>
          </ModalFooter>
        </React.Fragment>
      </ShouldRender>
    </Modal>
  )
}

export default ConfirmCode
