import React, { useState } from 'react'
import { starFill, starLine } from 'assets/images'

const Rating: React.FC<RatingType.Props> = ({
  rating,
  setRating,
  readOnly
}) => {
  const numberOfStars = 5
  const [editing, setEditing] = useState<boolean>(false)
  const [editRating, setEditRating] = useState<number | null>(null)

  const _setEditing = (isEditing: boolean) => {
    if (!readOnly) {
      setEditing(isEditing)
      setEditRating(null)
    }
  }

  const _setRating = (index: number) => {
    if (!readOnly && setRating) {
      setRating(index)
      _setEditing(false)
    }
  }

  const starImage = (i: number) => {
    if (!editing && rating && i <= rating) return starFill
    if (editing && editRating != null && i <= editRating) return starFill

    return starLine
  }
  const renderStars = () => {
    let stars = []
    for (let i = 1; i < numberOfStars + 1; i++) {
      stars.push(
        <img
          key={i}
          style={{ marginRight: i < numberOfStars ? '12px' : 0 }}
          src={starImage(i)}
          onMouseEnter={() => setEditRating(i)}
          onClick={() => {
            _setRating(i)
          }}
        />
      )
    }
    return stars
  }
  return (
    <div
      className='my-4 mx-auto'
      style={{
        opacity: editing ? 0.5 : 1,
        cursor: readOnly ? 'auto' : 'pointer'
      }}
      onMouseEnter={() => _setEditing(true)}
      onMouseLeave={() => _setEditing(false)}
    >
      {renderStars()}
    </div>
  )
}

export default Rating
