import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const mutation = gql`
  mutation CreateCompany(
    $document: String!
    $phone: String
    $name: String!
    $address: String
    $number: String
    $fantasyName: String
    $stateRegistration: String
    $complement: String
    $district: String
    $kind: CompanyKind = tac
    $city: ID
    $zipCode: String
  ) {
    createdCompany: createCompany(
      document: $document
      phone: $phone
      name: $name
      fantasyName: $fantasyName
      address: $address
      number: $number
      stateRegistration: $stateRegistration
      complement: $complement
      district: $district
      kind: $kind
      cityId: $city
      zipCode: $zipCode
    ) {
      id
    }
  }
`

export default () => {
  const [createCompany, result] = useMutation(mutation, { onError: () => null })

  return [createCompany, result]
}
