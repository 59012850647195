import * as firebase from 'firebase'

let config = {
  apiKey: 'AIzaSyCGn2MitO5PqLMt9Dfuq0PuN-U8E4rnutA',
  authDomain: 'frete-mais.firebaseapp.com',
  databaseURL: 'https://frete-mais.firebaseio.com',
  projectId: 'frete-mais',
  storageBucket: 'frete-mais.appspot.com',
  messagingSenderId: '763864597653',
  appId: '1:763864597653:web:ca30566d03f90d76a1479c'
}
firebase.initializeApp(config)

export default firebase
