import * as y from 'yup'

const validateSelect = y.object().shape({
  value: y
    .string()
    .required('Obrigatório')
    .min(1, 'Obrigatório'),
  label: y
    .string()
    .required('Obrigatório')
    .min(1, 'Obrigatório')
})

export default validateSelect
