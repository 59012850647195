import * as y from 'yup'
import { useFormik } from 'formik'
import { select } from 'utils/validation'

const useVehicle: FormTypes.Hook = ({ onSubmit, initialValues }) => {
  const formik = useFormik({
    onSubmit,
    initialValues,
    enableReinitialize: true,
    validationSchema: y.object().shape({
      anttDueDate: y
        .string()
        .nullable()
        .required('Obrigatório'),
      chassis: y
        .string()
        .nullable()
        .required('Obrigatório'),
      anttRntrc: y
        .string()
        .nullable()
        .max(8, 'Máx. 8 caracteres')
        .required('Obrigatório'),
      axis: y.string().required('Obrigatório'),
      capacityKg: y.string().required('Obrigatório'),
      capacityM3: y.string().required('Obrigatório'),
      document: y
        .string()
        .nullable()
        .required('Obrigatório'),
      licensePlate: y.string().required('Obrigatório'),
      nationality: select,
      renavam: y
        .string()
        .nullable()
        .required('Obrigatório'),
      tare: y.string().required('Obrigatório'),
      vehicleBrand: select,
      vehicleCategory: select,
      vehicleColor: select,
      vehicleModel: select,
      vehicleTracker: select,
      year: y
        .string()
        .nullable()
        .min(4, 'Mín. 4 caracteres')
        .max(4, 'Máx. 4 caracteres')
        .required('Obrigatório'),
      city: y
        .string()
        .nullable()
        .required('Obrigatório'),
      ownerCity: select,
      ownerDocument: y
        .string()
        .nullable()
        .required('Obrigatório'),
      ownerEmail: y
        .string()
        .nullable()
        .email('E-mail inválido')
        .notRequired(),
      ownerId: y
        .string()
        .nullable()
        .required('Obrigatório'),
      ownerName: y
        .string()
        .nullable()
        .required('Obrigatório'),
      ownerNationality: select,
      ownerPhone: y
        .string()
        .nullable()
        .required('Obrigatório'),
      lesseeCity: y
        .string()
        .nullable()
        .when('hasLessee', (hasLessee: any, schema: any) => {
          return !!hasLessee?.value ? select : schema.notRequired()
        }),
      lesseeDocument: y
        .string()
        .nullable()
        .when('hasLessee', (hasLessee: any, schema: any) => {
          return !!hasLessee?.value
            ? schema.required('Obrigatório')
            : schema.notRequired()
        }),
      lesseeEmail: y
        .string()
        .nullable()
        .email('E-mail inválido')
        .when('hasLessee', (hasLessee: any, schema: any) => {
          return !!hasLessee?.value
            ? schema.notRequired()
            : schema.notRequired()
        }),
      lesseeName: y
        .string()
        .nullable()
        .when('hasLessee', (hasLessee: any, schema: any) => {
          return !!hasLessee?.value
            ? schema.required('Obrigatório')
            : schema.notRequired()
        }),
      lesseeNationality: y
        .string()
        .nullable()
        .when('hasLessee', (hasLessee: any, schema: any) => {
          return !!hasLessee?.value ? select : schema.notRequired()
        }),
      lesseePhone: y
        .string()
        .nullable()
        .when('hasLessee', (hasLessee: any, schema: any) => {
          return !!hasLessee?.value
            ? schema.required('Obrigatório')
            : schema.notRequired()
        })
    })
  })

  return formik
}

export default useVehicle
