import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from 'reactstrap'
import { ShouldRender } from 'components'
import { logo } from 'assets/images'
import firebase from 'config/firebase'
import { email as emailValidation } from 'utils/validation'
import { notify } from 'services'

const PasswordRecovery: React.FC = () => {
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [emailSent, setEmailSent] = useState(false)

  const recover = () => {
    if (validateEmail()) {
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          setEmailSent(true)
        })
        .catch((error) => {
          if (error.code === 'auth/user-not-found')
            return notify({
              type: 'danger',
              description: 'Usuário não encontrado'
            })

          notify({
            type: 'danger',
            description: 'Não foi possível enviar o e-mail de recuperação'
          })
        })
    }
  }

  const validateEmail = () => {
    if (!email) {
      notify({
        type: 'danger',
        description: 'Por favor insira seu e-mail.'
      })
      return false
    }
    if (!emailValidation(email)) {
      notify({
        type: 'danger',
        description: 'Por favor insira um e-mail válido.'
      })
      return false
    }
    return true
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' && !emailSent) {
      recover()
    }
  }

  return (
    <div>
      <div className='absolute-back-btn'>
        <Button
          onClick={() => {
            history.goBack()
          }}
          className='custom-btn white-text-btn'
        >
          <i className='fa fa-arrow-left' aria-hidden='true'></i> Voltar
        </Button>
      </div>
      <div className='app flex-row align-items-center yellow-bg'>
        <Container>
          <Row className='justify-content-center'>
            <Col md='12' lg='5'>
              <Card className='p-4 login-card'>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className='text-center mb-4'>
                        <img
                          src={logo}
                          style={{ width: '140px', height: 'auto' }}
                          className='mx-auto'
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <h2>{!emailSent ? 'Recuperar Senha' : 'Pronto!'}</h2>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>
                          {!emailSent
                            ? 'Informe o e-mail cadastrado para receber um link de recuperação de senha:'
                            : 'Enviamos um link de recuperação de senha para seu e-mail'}
                        </p>
                      </Col>
                    </Row>
                    <ShouldRender if={!emailSent}>
                      <InputGroup className='mb-3'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText>
                            <i className='cui-user'></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type='text'
                          placeholder='E-mail'
                          autoComplete='email'
                          value={email}
                          onChange={(evt) => {
                            setEmail(evt.target.value)
                          }}
                          onKeyDown={handleKeyDown}
                        />
                      </InputGroup>
                    </ShouldRender>
                    <Row className='flex-row-reverse'>
                      <Col xs='6' className='text-right'>
                        <ShouldRender if={!emailSent}>
                          <Button
                            color='success'
                            className='px-4'
                            onClick={recover}
                          >
                            Enviar
                          </Button>
                        </ShouldRender>
                        <ShouldRender if={emailSent}>
                          <Button
                            className='px-4 custom-btn green-btn'
                            onClick={() => history.push('/login')}
                          >
                            Ir para login
                          </Button>
                        </ShouldRender>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default PasswordRecovery
