import gql from 'graphql-tag'

export default gql`
  query LazyFreightOffers($freightId: ID) {
    offers(freightId: $freightId) {
      id
      type: offerType
      value: offerValue
      status
      comments
      offer {
        id
        offerValue
      }
      freight {
        status
        requireAnalysis
      }
      vehicle: vehicleAssembly {
        driver {
          currentPosition
        }
        truck {
          category: vehicleCategory {
            description
          }
        }
        implement: implement1 {
          category: vehicleCategory {
            description
          }
          bodywork: vehicleBodywork {
            description
          }
        }
      }
    }
  }
`
