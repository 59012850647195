import Form from './Form'
import { useProfileForm } from 'forms'
import React, { useEffect } from 'react'
import { cpf, remove, cellphone } from 'utils/mask'
import { useLocation } from 'react-router-dom'
import { ShouldRender, Spinner } from 'components'
import { useLazyQuery } from '@apollo/react-hooks'
import { Card, CardHeader, CardBody } from 'reactstrap'
import { useLazyProfiles, useCurrentCompany } from 'queries'
import { useCreateProfile, useUpdateProfileAndRoles } from 'hooks'

const User: React.FC = () => {
  const location = useLocation()
  const id = location.pathname.split('/usuario/')[1]
  const { data: current } = useCurrentCompany()
  const [
    getProfile,
    { data: query, loading: fetching }
  ] = useLazyQuery(useLazyProfiles, { fetchPolicy: 'no-cache' })

  const [createProfile, creatingProfile] = useCreateProfile()
  const [updateProfile, updatingProfile] = useUpdateProfileAndRoles()

  useEffect(() => {
    id && getProfile({ variables: { id } })
  }, [id, getProfile])

  const getRemoteRoles = () => {
    if (query?.profiles?.length > 0) {
      const roles = []
      query?.profiles[0].adminRole && roles.push('admin')
      query?.profiles[0].negotiatorRole && roles.push('negotiator')

      return roles
    }

    return []
  }

  const initialValues: FormTypes.Profile = {
    name: '',
    document: '',
    email: '',
    mobilePhone: '',
    roles: [...getRemoteRoles()],
    ...(query?.profiles?.length > 0
      ? {
          ...query?.profiles[0],
          document: cpf(remove(query?.profiles[0]?.document)),
          mobilePhone: cellphone(remove(query?.profiles[0]?.mobilePhone))
        }
      : {})
  }

  const formik = useProfileForm({
    onSubmit: (values) => {
      id
        ? updateProfile({ id, ...values })
        : createProfile({ companyId: current?.profile?.company?.id, ...values })
    },
    initialValues
  })

  return (
    <Card>
      <CardHeader>{id ? 'Editar' : 'Novo'} Usuário</CardHeader>
      <CardBody>
        <ShouldRender if={id ? fetching : false}>
          <Spinner />
        </ShouldRender>

        <ShouldRender if={id ? !fetching : true}>
          <Form
            id={id}
            formik={formik}
            loading={creatingProfile || updatingProfile}
          />
        </ShouldRender>
      </CardBody>
    </Card>
  )
}

export default User
