import * as y from 'yup'
import { useFormik } from 'formik'
import { select, cpf } from 'utils/validation'

const useDriver: FormTypes.Hook = ({ onSubmit, initialValues }) => {
  const formik = useFormik({
    onSubmit,
    initialValues,
    enableReinitialize: true,
    validationSchema: y.object().shape({
      name: y.string().required('Obrigatório'),
      address: y.string().required('Obrigatório'),
      district: y.string().required('Obrigatório'),
      document: y
        .string()
        .test('valid-cpf', 'CPF Inválido', function(value) {
          return value ? cpf(value) : true
        })
        .required('Obrigatório'),
      driverLicense: y.string().required('Obrigatório'),
      driverLicenseDueDate: y.string().required('Obrigatório'),
      driverLicenseRenach: y.string().required('Obrigatório'),
      driverLicenseFirstDate: y.string().required('Obrigatório'),
      driverLicenseType: select,
      driverLicenseIssuingState: select,
      identityCard: y.string().required('Obrigatório'),
      email: y
        .string()
        .email('E-mail inválido')
        .required('Obrigatório'),
      employeeTime: y.string().required('Obrigatório'),
      employeeTimeKind: select,
      mobilePhone: y.string().required('Obrigatório'),
      nationality: select,
      number: y.string().required('Obrigatório'),
      zipCode: y.string().required('Obrigatório'),
      city: select,
      birthDate: y.string().required('Obrigatório'),
      fatherName: y.string().required('Obrigatório'),
      motherName: y.string().required('Obrigatório'),
      personalContactKind: select,
      personalContactName: y.string().required('Obrigatório'),
      personalContactPhone: y.string().required('Obrigatório'),
      professionalContactCompany: y.string().required('Obrigatório'),
      professionalContactKind: select,
      professionalContactName: y.string().required('Obrigatório'),
      professionalContactPhone: y.string().required('Obrigatório')
    })
  })

  return formik
}

export default useDriver
