import React from 'react'
import { TextInput } from 'components'
import { getFormikError } from 'services'
import { Row, Col, Button } from 'reactstrap'

const Form: React.FC<UserType.Props> = ({ formik, loading }) => {
  const { getFieldMeta, getFieldProps, handleSubmit } = formik

  return (
    <React.Fragment>
      <Row>
        <TextInput
          size={3}
          label='Login'
          {...getFieldProps('login', 'text')}
          error={getFormikError(getFieldMeta, 'login')}
        />

        <TextInput
          size={3}
          label='Senha*'
          type='password'
          {...getFieldProps('password', 'text')}
          error={getFormikError(getFieldMeta, 'password')}
        />

        <TextInput
          size={3}
          label='Transportadora'
          {...getFieldProps('transporter', 'text')}
          error={getFormikError(getFieldMeta, 'transporter')}
        />

        <TextInput
          size={3}
          label='Filial'
          {...getFieldProps('transportingUnit', 'text')}
          error={getFormikError(getFieldMeta, 'transportingUnit')}
        />
      </Row>
      <Row>
        <Col xs='12'>
          <p className='text-muted'>
            *A sua senha atual não será exibida aqui. Deixe o campo vazio caso
            queira manter a senha existente.
          </p>
        </Col>
      </Row>
      <Row className='flex-row-reverse'>
        <Col sm={3} xl={2}>
          <Button
            block
            color='success'
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? 'Salvando' : 'Salvar'}
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Form
