import React, { useState, useEffect } from 'react'
import { useRiskAnalysis } from 'queries'
import { Spinner, ShouldRender, WarningModal, FeedbackModal } from 'components'
import { useHistory } from 'react-router-dom'
import './style.css'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  CardFooter
} from 'reactstrap'
import { capitalize } from 'utils/changeCase'
import { useResendAnalysis, useFeedbackAnalysis } from 'mutations'
import { notify } from 'services'

const RiskAnalysis: React.FC = () => {
  const history = useHistory()
  const [modalData, setModalData] = useState() as any
  const [feedbackModal, setFeedbackModal] = useState() as any

  const [items, setItems] = useState<Record<string, any>[]>([])
  const [
    resend,
    { data: analysis, loading: sending, error }
  ]: any = useResendAnalysis()

  const [
    feedback,
    { data: analysisFeedback, loading: sendingFeedback, errorFeedback }
  ]: any = useFeedbackAnalysis()

  const { data, loading, refetch } = useRiskAnalysis({
    variables: {
      status: 'failure'
    }
  })

  useEffect(() => {
    const parsed = data?.list?.map((item: Record<string, any>) => ({
      ...item,
      data: typeof item.data === 'string' ? JSON.parse(item.data) : item.data
    }))

    setItems(parsed)
  }, [data])

  useEffect(() => {
    setModalData(undefined)

    if (analysis?.resendRiskAnalysis?.id) {
      notify({
        type: 'success',
        description: 'Reenviado para análise de risco'
      })
    }

    refetch({ status: 'failure' })
  }, [analysis, error])

  useEffect(() => {
    setModalData(undefined)
    setFeedbackModal(undefined)

    if (analysisFeedback?.feedbackRiskAnalysis?.id) {
      notify({
        type: 'success',
        description: 'Comentário sobre análise enviado'
      })
    }

    refetch({ status: 'failure' })
  }, [analysisFeedback, errorFeedback])


  const renderFullError = (data: Record<string, any>) => {
    if (data?.Liberacao) {
      return (
        <React.Fragment>
          <ShouldRender if={!!data?.Liberacao?.situacao_cadastro_descricao}>
            <p>
              <b>Situação cadastral: </b>
              {data?.Liberacao?.situacao_cadastro_descricao}
            </p>
          </ShouldRender>
          <ShouldRender if={!!data?.observacao}>
            <p>
              <b>Observações: </b>
              {data?.observacao}
            </p>
          </ShouldRender>
        </React.Fragment>
      )
    }

    return null
  }

  return (
    <React.Fragment>
      <ShouldRender if={loading}>
        <Spinner />
      </ShouldRender>
      <ShouldRender if={!loading}>
        {
          items?.map((item: any) => {
            return (
              <Card className='mb-3' key={item.id}>
                <CardHeader>{capitalize(item?.provider || '')}</CardHeader>
                <CardBody>
                  <ShouldRender if={!!item?.data?.observacao}>
                    {renderFullError(item?.data) as any}
                  </ShouldRender>

                  <ShouldRender if={!item?.data?.observacao}>
                    <React.Fragment>
                      <ShouldRender if={!!item?.data?.ERROR?.MSG}>
                        <p>
                          <b>Mensagem:</b> {item?.data?.ERROR?.MSG}
                        </p>
                      </ShouldRender>

                      <ShouldRender if={!!item?.description}>
                        <p>
                          <b>Descrição:</b> {item?.description}
                        </p>
                      </ShouldRender>
                    </React.Fragment>
                  </ShouldRender>
                </CardBody>
                <CardFooter>
                  <ShouldRender if={!!item?.profile?.id}>
                    <Button
                      onClick={() => {
                        history.push(`/motoristas/${item?.profile?.id}`)
                      }}
                      color='info'
                      outline
                      className='mr-2'
                    >
                      Visualizar Motorista
                    </Button>
                  </ShouldRender>

                  <ShouldRender if={!!item?.vehicle?.id}>
                    <Button
                      onClick={() => {
                        history.push(`/veiculos/${item?.vehicle?.id}`)
                      }}
                      color='info'
                      outline
                      className='mr-2'
                    >
                      Visualizar Veículo
                    </Button>
                  </ShouldRender>

                  <ShouldRender if={!!item?.offer?.freight?.id}>
                    <Button
                      onClick={() => {
                        history.push(
                          `/fretes/${item?.offer?.freight?.id}/frete`
                        )
                      }}
                      color='info'
                      outline
                      className='mr-2'
                    >
                      Visualizar Frete
                    </Button>
                  </ShouldRender>

                  <Button
                    className='float-right btn-primary-yellow'
                    onClick={() =>
                      setModalData({
                        title: 'Reenviar para análise de risco?',
                        message: 'Esta ação pode demorar alguns minutos.',
                        onCancel: () => setModalData(undefined),
                        onConfirm: () => {
                          setModalData({
                            ...modalData,
                            loading: sending,
                            title: 'Reenviando, aguarde'
                          })

                          resend({ variables: { id: item?.id } })
                        }
                      })
                    }
                  >
                    Reenviar para análise
                  </Button>

                  <Button
                    className='float-right btn-primary-gray'
                    onClick={() =>
                      setFeedbackModal({
                        title: 'Finalizar Processo de Análise de risco?',
                        message: 'Esta ação não poderá ser revertida.',
                        onCancel: () => setFeedbackModal(undefined),
                        onConfirm: (comment: string) => {
                          console.log(comment);
                          setModalData({
                            ...modalData,
                            loading: sending,
                            title: 'Atualizando, aguarde'
                          })

                          feedback({ variables: { id: item?.id , comment: comment} })
                        }
                      })
                    }
                  >
                    Resposta final
                  </Button>

                </CardFooter>

                <WarningModal modalData={modalData} />
                <FeedbackModal modalData={feedbackModal} />
              </Card>
            )
          }) as any
        }
      </ShouldRender>
    </React.Fragment>
  )
}

export default RiskAnalysis
