import * as y from 'yup'
import { useFormik } from 'formik'

const usePayment: FormTypes.Hook = ({ onSubmit, initialValues }) => {
  const formik = useFormik({
    onSubmit,
    initialValues,
    enableReinitialize: true,
    validationSchema: y.object().shape({
      planId: y.string().required('Obrigatório'),
      holderName: y.string().required('Obrigatório'),
      expirationMonth: y.string().required('Obrigatório'),
      expirationYear: y.string().required('Obrigatório'),
      cardNumber: y.string().required('Obrigatório'),
      securityCode: y.string().required('Obrigatório')
    })
  })

  return formik
}

export default usePayment
