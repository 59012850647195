import { useState } from 'react'
import { remove } from 'utils/mask'
import useAsyncEffect from 'use-async-effect'

const useViaCep = (zipcode: string) => {
  const controller = new AbortController()
  const [result, setResult] = useState({
    address: '',
    district: ''
  })
  const [loading, setLoading] = useState(false)

  useAsyncEffect(() => {
    const search = async () => {
      setLoading(true)

      try {
        const url = `https://viacep.com.br/ws/${remove(zipcode)}/json/`
        const request = await fetch(url, { signal: controller.signal })
        const data = await request.json()

        setTimeout(() => setLoading(false), 1000)

        if (!data.erro) {
          setResult({
            address: data.logradouro,
            district: data.bairro
          })
        }

        if (data.erro) {
          setLoading(false)
        }
      } catch (e) {
        setLoading(false)
      }
    }

    if (zipcode && remove(zipcode).length === 8) {
      return search()
    }

    return () => {
      const timeout = setTimeout(() => controller.abort(), 5000)
      clearTimeout(timeout)
    }
  }, [zipcode])

  return { loading, result }
}

export default useViaCep
