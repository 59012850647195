import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const mutation = gql`
  mutation FeedbackAnalysis($id: ID!, $comment: String!) {
    feedbackRiskAnalysis(id: $id, comment: $comment) {
      id
    }
  }
`

export default () => {
  const [feedbackRiskAnalysis, result] = useMutation(mutation, {
    refetchQueries: ['FeedbackAnalysis'],
    onError: () => null
  })

  return [feedbackRiskAnalysis, result]
}
