import idx from 'idx'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const mutation = gql`
  mutation FullLogin(
    $company: CompanyAttributes
    $profile: ProfileAttributes
    $token: String!
    $uid: String!
  ) {
    login: fullAuthLogin(
      company: $company
      profile: $profile
      token: $token
      uid: $uid
    ) {
      id
      token
    }
  }
`

export default () => {
  const [fullAuthLogin, result] = useMutation(mutation, {
    onError: () => null
  })

  return [fullAuthLogin, result]
}
