import Form from './Form'
import { logo } from 'assets/images'
import gql from 'graphql-tag'
import ConfirmCode from './ConfirmCode'
import { useApolloClient } from '@apollo/react-hooks'
import { useSignUpForm } from 'forms'
import firebase from 'config/firebase'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Col, Container, Row } from 'reactstrap'
import { useFullAuthLogin } from 'mutations'
import { notify } from 'services'
import { remove } from 'utils/mask'

const SignUp: React.FC<SignUpType.Props> = () => {
  const history = useHistory()
  const client = useApolloClient()
  const [loading, setLoading] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [code, setCode] = useState('')
  const [message, setMessage] = useState('')
  const [verificationId, setVerificationId] = useState('')
  const [formValues, setFormValues] = useState({
    name: '',
    document: '',
    email: '',
    mobilePhone: '',
    password: '',
    passwordConfirmation: '',
    contractorKind: { label: '', value: '' },
    companyDocument: '',
    companyName: '',
    fantasyName: '',
    companyPhone: ''
  })

  const [fullAuthLogin, { data, error }]: any = useFullAuthLogin()

  const initialValues: FormTypes.UserSignUp = {
    name: '',
    document: '',
    email: '',
    mobilePhone: '',
    password: '',
    passwordConfirmation: '',
    contractorKind: { label: 'Transportadora', value: 'transporter' },
    companyDocument: '',
    companyName: '',
    fantasyName: '',
    companyPhone: ''
  }

  const checkForAuthenticatedUser = async () => {
    const cachedJWT = await client.query({
      query: gql`
        {
          token @client
        }
      `
    })

    if (!!cachedJWT?.data) {
      history.replace('/dashboard')
      history.go(0)
    }
  }

  useEffect(() => {
    checkForAuthenticatedUser()
  }, [])

  useEffect(() => {
    if (error) {
      firebase
        .auth()
        .currentUser?.delete()
        .then(() => {
          setLoading(false)
          setModalVisible(false)
        })
        .catch(() => {
          setLoading(false)
          setModalVisible(false)
        })
    }

    if (data?.login?.token) {
      notify({
        type: 'success',
        description: 'Conta criada, faça login para continuar'
      })
      setTimeout(() => {
        history.replace('/login')
      }, 1100)
    }
  }, [data, error])

  useEffect(() => {
    if (!!formValues.mobilePhone) {
      return sendSMS()
    }
  }, [formValues])

  const formik = useSignUpForm({
    onSubmit: (values) => {
      setModalVisible(true)
      setFormValues({ ...formValues, ...values })
    },
    initialValues
  })

  const sendSMS = () => {
    const recaptcha = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible'
      }
    )
    const phoneProvider = new firebase.auth.PhoneAuthProvider()

    phoneProvider
      .verifyPhoneNumber(`+55${remove(formValues.mobilePhone)}`, recaptcha)
      .then((firebaseVerificationId) => {
        setVerificationId(firebaseVerificationId)
      })
      .catch((e) => {
        notify({
          type: 'danger',
          description:
            'Não foi possível confirmar seu número de telefone. Tente novamente.'
        })
      })
  }

  const createAndLinkAccounts = () => {
    setLoading(true)
    setMessage('Telefone confirmado. Criando sua conta')

    const phoneCredential = firebase.auth.PhoneAuthProvider.credential(
      verificationId,
      code
    )

    firebase
      .auth()
      .createUserWithEmailAndPassword(formValues.email, formValues.password)
      .then(async (createdAccount: any) => {
        const uid = await createdAccount.user?.uid
        const idToken = await createdAccount.user?.getIdToken()

        firebase
          .auth()
          // @ts-ignore
          .currentUser?.linkWithCredential(phoneCredential)
          .then(() => {
            return fullAuthLogin({
              variables: {
                uid,
                token: idToken,
                company: {
                  contractorKind:
                    formValues?.contractorKind?.value || 'transporter',
                  document: formValues?.companyDocument,
                  phone: formValues?.companyPhone,
                  name: formValues?.companyName,
                  fantasyName: formValues.fantasyName,
                  kind: 'etc'
                },
                profile: {
                  name: formValues?.name,
                  email: formValues?.email,
                  document: formValues?.document,
                  mobilePhone: formValues?.mobilePhone
                }
              }
            })
          })
          .catch((e: any) => {
            firebase
              .auth()
              .currentUser?.delete()
              .then(() => {
                setLoading(false)
                setModalVisible(false)
                notify({
                  type: 'danger',
                  description:
                    'Não foi possível sincronizar os dados da sua conta. Tente novamente.'
                })
              })
              .catch((e) => {
                firebase
                  .auth()
                  .currentUser?.delete()
                  .then(() => {
                    setLoading(false)
                    setModalVisible(false)
                    notify({
                      type: 'danger',
                      description: 'Erro interno.'
                    })
                  })
              })
          })
      })
      .catch((e) => {
        const options = [
          { code: 'auth/internal-error', message: 'Erro interno' },
          { code: 'auth/invalid-email', message: 'E-mail inválido' },
          {
            code: 'auth/invalid-password',
            message: 'Forneça uma senha de pelo menos 6 caracteres'
          },
          {
            code: 'auth/email-already-in-use',
            message: 'O e-mail informado já está em uso'
          }
        ]

        const index = options.findIndex((x: Record<string, string>) => {
          return x.code === e?.code
        })

        notify({
          type: 'danger',
          description: options[index > -1 ? index : 0].message
        })

        setLoading(false)
        setModalVisible(false)
      })
  }

  return (
    <div className='yellow-bg'>
      <div className='absolute-back-btn'>
        <Button
          onClick={() => {
            history.push('/login')
          }}
          className='custom-btn white-text-btn'
        >
          <i className='fa fa-arrow-left' aria-hidden='true'></i> Voltar
        </Button>
      </div>
      <div className='app'>
        <Container className='signup-header-margin'>
          <Row>
            <Col sm={3} className='text-center'>
              <img className='img-fluid' src={logo} />
            </Col>
            <Col>
              <Row className='mb-3'>
                <Col>
                  <h1>Cadastro</h1>
                </Col>
              </Row>
              <Form formik={formik} loading={false} />
            </Col>
          </Row>
        </Container>
      </div>
      <ConfirmCode
        visible={modalVisible}
        toggle={() => setModalVisible(!modalVisible)}
        confirm={createAndLinkAccounts}
        code={code}
        setCode={setCode}
        loading={loading}
        message={message}
      />
      <div id='recaptcha-container' />
    </div>
  )
}

export default SignUp
