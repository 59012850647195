import * as y from 'yup'
import { useFormik } from 'formik'
import { select } from 'utils/validation'

const useWaypoint: FormTypes.Hook = ({ onSubmit, initialValues }) => {
  const formik = useFormik({
    onSubmit,
    initialValues,
    enableReinitialize: true,
    validationSchema: y.object().shape({
      city: select,
      address: y.string().required('Obrigatório'),
      district: y.string().required('Obrigatório'),
      kind: select,
      number: y.string().required('Obrigatório'),
      zipCode: y.string().required('Obrigatório'),
      expectedAt: y
        .date()
        .test('not past', 'Data inválida', function(value) {
          return value ? value > new Date() : true
        })
        .required('Obrigatório')
    })
  })

  return formik
}

export default useWaypoint
