import gql from 'graphql-tag'

export default gql`
  query LazyPaginatedDriversList(
    $completedDriverData: Boolean
    $after: String
  ) {
    list: paginatedProfiles(
      first: 30
      after: $after
      filter: { driverRole: true, completedDriverData: $completedDriverData }
    ) {
      nodes {
        id
        name
        document
        company {
          name
        }
        completedDriverData
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
`
