import gql from 'graphql-tag'
import { notify } from 'services'
import ApolloClient from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { createHttpLink } from 'apollo-link-http'
import { CachePersistor } from 'apollo-cache-persist'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createBrowserHistory } from 'history'
import loggerLink from 'apollo-link-logger'

const cache = new InMemoryCache()
const history = createBrowserHistory()

const persistor = new CachePersistor({
  cache,
  storage: window.localStorage as any,
  trigger: 'write'
})

const dropCache = () => {
  persistor.pause()
  persistor.purge()
  cache.reset()
  persistor.resume()

  return setTimeout(() => {
    history.replace('/login')
    history.go(0)
  }, 300)
}

const client = new ApolloClient({
  cache,
  resolvers: {},
  link: ApolloLink.from([
    // loggerLink,
    onError(({ graphQLErrors, networkError }: any) => {
      if (graphQLErrors) {
        if (graphQLErrors[0].code === 401) {
          dropCache()
        }

        notify({
          type: graphQLErrors[0].type || 'danger',
          title: graphQLErrors[0].description || undefined,
          description: graphQLErrors[0].message || 'Erro interno'
        })

        return undefined
      }

      if (networkError) {
        return !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
          ? console.log(`Network Error: ${networkError}`)
          : undefined
      }
    }),
    createHttpLink({
      uri: 'https://api-fretemais.herokuapp.com/graphql',
      fetch: async (uri, options: any) => {
        const cached = await client.query({
          query: gql`
            {
              token @client
            }
          `
        })

        options.headers.Authorization = `Bearer ${cached?.data?.token}`

        return fetch(uri, options)
      }
    })
  ])
})

export { persistor, dropCache, client }
