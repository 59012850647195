import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const query = gql`
  query VehicleModels($vehicleBrandId: ID) {
    list: vehicleModels(filter: { vehicleBrandId: $vehicleBrandId }) {
      value: id
      label: description
    }
  }
`
export default () => useQuery(query, { fetchPolicy: 'no-cache' })
