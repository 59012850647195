import React, { useState, Fragment, useEffect } from 'react'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  Input,
  Row,
  FormGroup,
  Label
} from 'reactstrap'
import { Rating, ShouldRender } from 'components'
import { useRating } from 'queries'
import { useRateAsCompany } from 'mutations'
import { notify } from 'services'

const FreightEvaluation: React.FC<{ id: string }> = ({ id }) => {
  const [rating, setRating] = useState<number | undefined>()
  const [comment, setComment] = useState<string>('')

  const { data } = useRating({ variables: { freightId: id } })
  const [
    rateDriver,
    { data: driver, loading: savingRating }
  ]: any = useRateAsCompany()

  useEffect(() => {
    if (driver?.rated?.id) {
      notify({
        type: 'success',
        description: 'Avaliação enviada.'
      })
    }
  }, [driver])

  useEffect(() => {
    if (data?.list[0]?.freight?.rating?.driverRating) {
      setRating(data?.list[0]?.freight?.rating?.driverRating)
    }
  }, [data])

  return (
    <ShouldRender
      if={
        data?.list?.length > 0 &&
        !['created', 'cancelled', 'expired'].includes(
          data?.list[0]?.freight?.status
        )
      }
    >
      <Fragment>
        <Card>
          <CardHeader>
            {!!data?.list[0]?.freight?.rating?.driverRating
              ? 'Avaliação enviada'
              : 'Enviar avaliação'}
          </CardHeader>
          <CardBody>
            <ShouldRender if={!data?.list[0]?.freight?.rating?.driverRating}>
              <Row>
                <Col>Dê uma nota de 1 a 5 estrelas para o transportador:</Col>
              </Row>
            </ShouldRender>
            <Row>
              <Rating
                rating={rating}
                setRating={setRating}
                readOnly={!!data?.list[0]?.freight?.rating?.driverRating}
              />
            </Row>
            <ShouldRender if={!!data?.list[0]?.freight?.rating?.driverRating}>
              <Fragment>
                <Row>
                  <Col className='text-center'>Comentário:</Col>
                </Row>
                <Row>
                  <Col
                    md={10}
                    lg={8}
                    xl={6}
                    className='text-center mx-auto my-3'
                  >
                    {data?.list[0]?.freight?.rating?.driverComment ||
                      'Você não deixou nenhum comentário'}
                  </Col>
                </Row>
              </Fragment>
            </ShouldRender>
            <ShouldRender if={!data?.list[0]?.freight?.rating?.driverRating}>
              <Row>
                <Col lg={10} xl={8} className='mx-auto'>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for='exampleText'>Comentário:</Label>
                        <Input
                          type='textarea'
                          name='text'
                          onChange={(evt) => setComment(evt.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <ShouldRender
                    if={!data?.list[0]?.freight?.rating?.driverRating}
                  >
                    <Row className='flex-row-reverse mb-4'>
                      <Col md={6} xl={4}>
                        <Button
                          block
                          color='success'
                          disabled={savingRating}
                          onClick={() =>
                            rateDriver({
                              variables: {
                                comment,
                                rate: rating,
                                freightId: id
                              }
                            })
                          }
                        >
                          {savingRating
                            ? 'Enviando avaliação'
                            : 'Enviar avaliação'}
                        </Button>
                      </Col>
                    </Row>
                  </ShouldRender>
                </Col>
              </Row>
            </ShouldRender>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>Avaliação recebida</CardHeader>
          <CardBody>
            <ShouldRender
              if={
                data?.list?.length > 0 &&
                !!data?.list[0]?.freight?.rating?.driverRating
              }
            >
              <React.Fragment>
                <Row>
                  <Rating
                    rating={data?.list[0]?.freight?.rating?.companyRating || 0}
                    readOnly
                  />
                </Row>
                <Row>
                  <Col className='text-center'>Comentário:</Col>
                </Row>
                <Row>
                  <Col
                    md={10}
                    lg={8}
                    xl={6}
                    className='text-center mx-auto my-3'
                  >
                    {data?.list[0]?.freight?.rating?.companyComment ||
                      'O motorista não deixou nenhum comentário'}
                  </Col>
                </Row>
              </React.Fragment>
            </ShouldRender>

            <ShouldRender if={!data?.list[0]?.freight?.rating?.driverRating}>
              <Col md={10} lg={8} xl={6} className='text-center mx-auto my-3'>
                Faça a sua avaliação para poder visualizar a avaliação recebida
                do motorista.
              </Col>
            </ShouldRender>
          </CardBody>
        </Card>
      </Fragment>
    </ShouldRender>
  )
}

export default FreightEvaluation
