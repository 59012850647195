import React, { Fragment, useCallback, useState } from 'react'
import './style.css'
import { Col, Row } from 'reactstrap'
import ImageViewer from 'react-simple-image-viewer'

const ImageGallery: React.FC<ImageGalleryType.Props> = ({ images }) => {
  const [currentImage, setCurrentImage] = useState(0)
  const [isViewerOpen, setIsViewerOpen] = useState(false)

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }, [])

  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }

  const renderSlides = () => {
    return images.map((url: string, index: number) => {
      return (
        <Col
          md='3 d-flex align-items-center justify-content-center'
          key={index}
        >
          {url && (
            <div className='image-gallery-item'>
              <img
                className='square-image'
                src={url}
                onClick={() => openImageViewer(index)}
              />
            </div>
          )}
        </Col>
      )
    })
  }
  return (
    <Fragment>
      <Row>{renderSlides()}</Row>
      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          onClose={closeImageViewer}
        />
      )}
    </Fragment>
  )
}

export default ImageGallery
