import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const query = gql`
  query CompanyFreights {
    list: companyFreights {
      id
      startDate
      endDate
      status
      offersCount
      waypoints {
        expectedAt
      }
      startCity {
        name
        state {
          initials
        }
      }
      endCity {
        name
        state {
          initials
        }
      }
      operator {
        name
      }
      publisher {
        company {
          name
        }
      }
    }
  }
`

export default () => {
  return useQuery(query, { fetchPolicy: 'no-cache' })
}
