import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const mutation = gql`
  mutation UpdateVehicleDocumentStatus($id: ID!, $status: DocumentStatus!) {
    document: updateVehicleDocument(id: $id, status: $status) {
      id
      status
    }
  }
`

export default () => {
  const [updateVehicleDocument, result] = useMutation(mutation, {
    onError: () => null,
    refetchQueries: ['Vehicle', 'Vehicles', 'LazyPaginatedVehicles']
  })

  return [updateVehicleDocument, result]
}
