import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const mutation = gql`
  mutation UpdateUser(
    $id: ID!
    $name: String
    $email: String
    $document: String
  ) {
    updatedUser: updateUser(
      id: $id
      name: $name
      email: $email
      document: $document
    ) {
      id
      email
      document
      name
      phone
      provider
      termsOfService
    }
  }
`

export default () => {
  const [updateUser, result] = useMutation(mutation, { onError: () => null })

  return [updateUser, result]
}
