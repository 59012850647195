import React, { useEffect, useState, useRef } from 'react'
import GoogleMapReact from 'google-map-react'
// @ts-ignore
import { fitBounds } from 'google-map-react/utils'
import { truckPosition, truckPositionFuture } from 'assets/images'
import { ShouldRender } from 'components'
import format from 'date-fns/format'

const TruckMarker = ({ isFuture, onClick }: any) => (
  <div style={{ zIndex: 10 }} onClick={onClick}>
    <img
      src={isFuture ? truckPositionFuture : truckPosition}
      style={{ height: '26px', width: '26px' }}
    />
  </div>
)

const Map: React.FC<MapType.Props> = ({ markers }) => {
  const mapHeight = 380
  const mapRef = useRef(null)
  const [center, setCenter] = useState<MapType.coordinate>()
  const [zoom, setZoom] = useState<number>()
  const [checkinTime, setCheckinTime] = useState('')

  const calculateBounds = () => {
    let nw: MapType.coordinate = {
      lat: Math.min.apply(
        Math,
        markers.map(function(o) {
          return o.lat
        })
      ),
      lng: Math.min.apply(
        Math,
        markers.map(function(o) {
          return o.lng
        })
      )
    }
    let se: MapType.coordinate = {
      lat: Math.max.apply(
        Math,
        markers.map(function(o) {
          return o.lat
        })
      ),
      lng: Math.max.apply(
        Math,
        markers.map(function(o) {
          return o.lng
        })
      )
    }

    return { nw, se }
  }

  useEffect(() => {
    // set BR as center
    if (markers.length === 0) {
      setCenter({ lat: -11.5205, lng: -53.0108 })
      setZoom(4)
    }

    // set single marker as center
    if (markers.length === 1) {
      setCenter({ lat: markers[0].lat, lng: markers[0].lng })
      setZoom(7)
    }

    // calculate map bounds for 2 or more items
    if (markers.length > 1) {
      // check if all markers have same location
      const equalMarkers = markers.filter((x: any) => {
        return x.lat === markers[0].lat && x.lng === markers[0].lng
      }).length

      if (equalMarkers === markers.length) {
        setCenter({ lat: markers[0].lat, lng: markers[0].lng })
        setZoom(7)
      } else {
        // fit markers
        const { nw, se } = calculateBounds()
        const bounds = { nw, se }

        const size = {
          // @ts-ignore
          width: mapRef.current ? mapRef.current.offsetWidth : 640, // Map width in pixels
          height: mapHeight // Map height in pixels
        }

        const { center, zoom } = fitBounds(bounds, size)
        setCenter(center)
        setZoom(zoom)
      }
    }
  }, [markers, mapRef.current])

  const renderTrucksPositions = () => {
    return markers?.map((truckPosition: MapType.Marker) => {
      const { lat, lng, isFuture, index, createdAt }: any = truckPosition
      return (
        <TruckMarker
          lat={lat}
          lng={lng}
          isFuture={isFuture}
          key={index}
          onClick={() => setCheckinTime(createdAt)}
        />
      )
    })
  }

  return (
    <div
      ref={mapRef}
      style={{
        height: `${mapHeight}px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <ShouldRender if={!!center && !!zoom}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCGn2MitO5PqLMt9Dfuq0PuN-U8E4rnutA' }}
          defaultCenter={center}
          defaultZoom={zoom}
          onDrag={() => setCheckinTime('')}
        >
          {renderTrucksPositions()}
        </GoogleMapReact>
      </ShouldRender>

      <ShouldRender if={!!checkinTime}>
        <div
          style={{
            position: 'absolute',
            bottom: 30,
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 5,
            paddingBottom: 5,
            backgroundColor: 'rgba(0,0,0,0.6)'
          }}
        >
          <span style={{ color: '#fff' }}>
            {`Check-in efetuado em ${format(
              new Date(checkinTime || Date.now()),
              'dd/MM/y H:mm'
            )}h`}
          </span>
        </div>
      </ShouldRender>
    </div>
  )
}

export default Map
