import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const mutation = gql`
  mutation DestroyProfile($id: ID!) {
    destroyedProfile: destroyProfile(id: $id) {
      success
    }
  }
`

export default () => {
  const [destroyProfile, result] = useMutation(mutation, {
    onError: () => null,
    refetchQueries: ['Profiles', 'LazyPaginatedProfiles']
  })

  return [destroyProfile, result]
}
