import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const query = gql`
  query Plans {
    list: plans {
      creditAmount
      description
      id
      name
      price
    }
  }
`

export default () => useQuery(query, { fetchPolicy: 'no-cache' })
