import gql from 'graphql-tag'

export default gql`
  query LazyCompactVehicles($completedVehicleData: Boolean) {
    list: vehicles(filter: { completedVehicleData: $completedVehicleData }) {
      id
      completedVehicleData
      company {
        name
      }
      licensePlate
      brand: vehicleBrand {
        description
      }
      model: vehicleModel {
        description
      }
      category: vehicleCategory {
        description
      }
    }
  }
`
