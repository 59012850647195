import { v4 } from 'uuid'
import React, { useState } from 'react'
import Waypoint from './Waypoint'
import { money, number } from 'utils/mask'
import { getFormikError } from 'services'
import { Row, Col, Button } from 'reactstrap'
import { useLazyQuery } from '@apollo/react-hooks'
import { options as paymentOptions } from 'utils/payment'
import {
  useVehicleCategories,
  useVehicleBodyworks,
  useCargoTypes,
  useCargoCategories,
  useProfiles
} from 'queries'
import {
  TextInput,
  CheckboxGroup,
  ShouldRender,
  Spinner,
  Select
} from 'components'

const Form: React.FC<FreightType.Form> = ({
  formik,
  loading,
  publish,
  published,
  publishing
}) => {
  const [editing, setEditing] = useState<string[]>([])
  const [showNewWaypoint, setShowNewWaypoint] = useState(false)
  const {
    errors,
    values,
    getFieldMeta,
    getFieldProps,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    handleSubmit
  } = formik

  const {
    data: categories,
    loading: loadingCategories
  } = useVehicleCategories()
  const {
    data: cargoCategories,
    loading: loadingCargoCategories
  } = useCargoCategories()
  const { data: bodyworks, loading: loadingBodyworks } = useVehicleBodyworks()
  const { data: cargoTypes, loading: loadingCargoTypes } = useCargoTypes()
  const { data: profiles, loading: loadingProfiles } = useProfiles({
    variables: {
      negotiatorRole: true
    }
  })

  const booleanOptions = [
    { label: 'Sim', value: true },
    { label: 'Não', value: false }
  ]

  const floorOptions = [
    { label: 'Madeira', value: 'wood' },
    { label: 'Ferro', value: 'iron' },
    { label: 'Alumínio', value: 'aluminum' },
    { label: 'Não aplicável', value: 'not_applicable' }
  ]

  const removeWaypoint = (index: number) => {
    return setTimeout(() => {
      setFieldValue('waypoints', values['waypoints'].splice(index, 1))
    }, 250)
  }

  const toggleEditMode = (id: string) => {
    const output = [...editing]
    const exists = output.indexOf(id) > -1

    exists ? output.splice(output.indexOf(id), 1) : output.push(id)

    return setEditing(output)
  }

  return (
    <React.Fragment>
      <Row>
        <Col xs='12'>
          <legend>Informações gerais</legend>
        </Col>
      </Row>
      <Row>
        <Select
          size={{ xs: 12, sm: 3 }}
          label='Negociador responsável'
          name='operator'
          loading={loadingProfiles}
          value={values['operator']}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          error={getFormikError(getFieldMeta, 'operator')}
          disabled={published}
          options={profiles?.profiles?.map((profile: Record<string, any>) => ({
            value: profile.id,
            label: profile.name
          }))}
        />

        <Select
          size={{ xs: 12, sm: 6, md: 3 }}
          label='Tipo de carga'
          name='cargoCategory'
          disabled={published}
          value={values['cargoCategory']}
          loading={loadingCargoCategories}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          error={getFormikError(getFieldMeta, 'cargoCategory')}
          options={cargoCategories?.categories}
        />

        <Select
          size={{ xs: 12, sm: 6, md: 3 }}
          label='Tipo de mercadoria'
          name='cargoType'
          disabled={published}
          value={values['cargoType']}
          loading={loadingCargoTypes}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          error={getFormikError(getFieldMeta, 'cargoType')}
          options={cargoTypes?.types?.map((type: Record<string, string>) => ({
            value: type.value,
            label: type.label
          }))}
        />

        <TextInput
          size={{ xs: 12, sm: 6, md: 3 }}
          label='Valor total da carga'
          labelIcon='fa fa-question-circle'
          labelIconTooltip='Valor da carga é uma informação confidencial, utilizada apenas para análise de risco. ESSA INFORMAÇÃO NÃO É EXIBIDA PARA O MOTORISTA.'
          disabled={published}
          {...getFieldProps('cargoValue', 'text')}
          error={getFormikError(getFieldMeta, 'cargoValue')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('cargoValue', money(e.target.value))
          }}
        />

        <TextInput
          size={{ xs: 12, sm: 6, md: 6 }}
          disabled={published}
          label='Informações adicionais (opcional)'
          {...getFieldProps('freightObservations', 'text')}
          error={getFormikError(getFieldMeta, 'freightObservations')}
        />
      </Row>
      <hr className='my-3' />
      <Row>
        <Col xs='12'>
          <legend>Tamanho da carga</legend>
        </Col>
      </Row>
      <Row>
        <TextInput
          size={{ xs: 12, sm: 6, md: 3 }}
          label='Peso em Kg'
          disabled={published}
          {...getFieldProps('capacityKg', 'text')}
          error={getFormikError(getFieldMeta, 'capacityKg')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('capacityKg', number(e.target.value))
          }}
        />

        <TextInput
          size={{ xs: 12, sm: 6, md: 3 }}
          label='m³ (opcional)'
          disabled={published}
          {...getFieldProps('capacityM3', 'text')}
          error={getFormikError(getFieldMeta, 'capacityM3')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('capacityM3', number(e.target.value))
          }}
        />
      </Row>
      <hr className='my-3' />
      <Row>
        <Col xs='12'>
          <legend>Remuneração</legend>
        </Col>
      </Row>
      <Row>
        <TextInput
          size={{ xs: 12, sm: 6, md: 3 }}
          label='Valor do frete'
          disabled={published}
          {...getFieldProps('freightFirstValue', 'text')}
          error={getFormikError(getFieldMeta, 'freightFirstValue')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('freightFirstValue', money(e.target.value))
          }}
        />

        <Select
          size={{ xs: 12, sm: 6, md: 3 }}
          label='Forma de pagamento'
          name='paymentMethod'
          disabled={published}
          value={values['paymentMethod']}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          error={getFormikError(getFieldMeta, 'paymentMethod')}
          options={paymentOptions}
        />

        <TextInput
          size={{ xs: 12, sm: 6, md: 3 }}
          label='Percentual de adiantamento'
          disabled={published}
          {...getFieldProps('paymentAdvance', 'text')}
          error={getFormikError(getFieldMeta, 'paymentAdvance')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('paymentAdvance', number(e.target.value))
          }}
        />
      </Row>
      <hr className='my-3' />
      <Row>
        <Col xs='12'>
          <legend>Adicionais</legend>
        </Col>
      </Row>
      <Row>
        <Select
          size={{ xs: 12, sm: 6, md: 3 }}
          label='Análise de risco'
          name='requireAnalysis'
          disabled={published}
          value={values['requireAnalysis']}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          error={getFormikError(getFieldMeta, 'requireAnalysis')}
          options={booleanOptions}
        />

        <Select
          size={{ xs: 12, sm: 6, md: 3 }}
          label='Possui rastreador'
          name='tracked'
          disabled={published}
          value={values['tracked']}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          error={getFormikError(getFieldMeta, 'tracked')}
          options={booleanOptions}
        />

        <Select
          size={{ xs: 12, sm: 6, md: 3 }}
          label='Tipo de assoalho (opcional)'
          name='floor'
          disabled={published}
          value={values['floor']}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          error={getFormikError(getFieldMeta, 'floor')}
          options={floorOptions}
        />
      </Row>
      <hr className='my-3' />
      <Row>
        <Col xs='12'>
          <legend>Veículo</legend>
        </Col>
      </Row>

      <Row>
        <ShouldRender if={loadingCategories}>
          <Spinner />
        </ShouldRender>

        <ShouldRender if={!loadingCategories}>
          <CheckboxGroup
            name='acceptedVehicleTypeIds'
            value={values['acceptedVehicleTypeIds']}
            error={errors['acceptedVehicleTypeIds']}
            disabled={published}
            setFieldError={setFieldError}
            setFieldValue={setFieldValue}
            options={categories?.list?.reduce(
              (acc: Record<string, any>[], iteratee: Record<string, any>) => {
                if (['car', 'semitrailer', 'truck'].includes(iteratee.kind)) {
                  acc.push({
                    label: iteratee.label,
                    value: iteratee.value
                  })
                }
                return acc
              },
              []
            )}
          />
        </ShouldRender>
      </Row>
      <hr className='my-3' />
      <Row>
        <Col xs='12'>
          <legend>Carroceria</legend>
        </Col>
      </Row>

      <Row>
        <ShouldRender if={loadingBodyworks}>
          <Spinner />
        </ShouldRender>

        <ShouldRender if={!loadingBodyworks}>
          <CheckboxGroup
            name='acceptedBodyworkTypeIds'
            value={values['acceptedBodyworkTypeIds']}
            error={errors['acceptedBodyworkTypeIds']}
            setFieldError={setFieldError}
            disabled={published}
            setFieldValue={setFieldValue}
            options={bodyworks?.list}
          />
        </ShouldRender>
      </Row>

      <hr className='my-3' />
      <Row>
        <Col xs='12'>
          <legend>Carregamentos</legend>
          <ShouldRender if={errors['waypoints']}>
            <p className='form-message text-danger pr-4'>
              {errors['waypoints']}
            </p>
          </ShouldRender>
        </Col>
        <ShouldRender if={values['waypoints'].length > 0}>
          {values['waypoints'].map((waypoint: any, index: number) => {
            return (
              <Waypoint
                editing={editing.indexOf(waypoint.id) > -1}
                disabled={editing.indexOf(waypoint.id) === -1}
                remove={() => removeWaypoint(index)}
                key={waypoint.id}
                published={published}
                initialValues={waypoint}
                submit={(waypointValues) => {
                  const index = values['waypoints'].findIndex((x: any) => {
                    return x.id === waypoint.id
                  })

                  if (index > -1) {
                    const newValue = [...values['waypoints']]
                    newValue[index] = waypointValues

                    setFieldValue('waypoints', newValue)
                    toggleEditMode(waypoint.id)
                  }
                }}
                edit={() => toggleEditMode(waypoint.id)}
              />
            )
          })}
        </ShouldRender>

        <ShouldRender if={showNewWaypoint}>
          <Waypoint
            cancel={() => setShowNewWaypoint(false)}
            submit={(waypointValues) => {
              const newValue = [
                ...values['waypoints'],
                { ...waypointValues, id: v4() }
              ]
              setFieldValue('waypoints', newValue)
              setShowNewWaypoint(false)
            }}
          />
        </ShouldRender>

        <ShouldRender if={!showNewWaypoint && !published}>
          <Col sm='4' className='mx-auto my-4'>
            <Button
              block
              color='success'
              disabled={!!editing.length}
              onClick={() => setShowNewWaypoint(true)}
            >
              Adicionar ponto de coleta/entrega
            </Button>
          </Col>
        </ShouldRender>
      </Row>

      <ShouldRender if={!showNewWaypoint}>
        <Row className='flex-row-reverse mb-4'>
          <ShouldRender if={!published}>
            <Col sm={3} xl={2}>
              <Button
                block
                disabled={loading}
                color='success'
                onClick={handleSubmit}
              >
                {loading ? 'Salvando' : 'Salvar'}
              </Button>
            </Col>
          </ShouldRender>

          <ShouldRender if={!published && values.id}>
            <Col sm={3} xl={2}>
              <Button
                block
                disabled={publishing}
                color='success'
                onClick={publish}
              >
                {publishing ? 'Publicando' : 'Publicar'}
              </Button>
            </Col>
          </ShouldRender>
        </Row>
      </ShouldRender>
    </React.Fragment>
  )
}

export default Form
