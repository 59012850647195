import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const query = gql`
  query CompanyId {
    company {
      id
    }
  }
`

export default () => useQuery(query)
