import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const query = gql`
  query Freight($id: ID) {
    list: freights(id: $id) {
      id
      startDate
      cargoType {
        description
      }
      endCity {
        name
        state {
          initials
        }
      }
      requireAnalysis
      startPosition
      endPosition
      fullEndAddress
      freightFirstValue
      freightLastValue
      freightObservations
      loadingObservations
      paymentObservations
      fullStartAddress
      distance
      paymentMethod
      operatorName
      operatorPhone
      status
      waypoints {
        id
        kind
        fullAddress
        position
        waypointOrder
        number
        executedAt
        district
        deliveryProofUrl
        complement
        avariesUrls
        address
        city {
          name
          state {
            initials
          }
        }
        zipCode
      }
      startCity {
        name
        state {
          initials
        }
      }
      publisher {
        company {
          name
        }
      }
    }
  }
`

export default (params?: any) =>
  useQuery(query, { fetchPolicy: 'no-cache', ...params })
