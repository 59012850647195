import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const mutation = gql`
  mutation CreateCounterOffer($offerId: ID!, $offerValue: Int!) {
    result: createCounterOffer(offerId: $offerId, offerValue: $offerValue) {
      id
    }
  }
`

export default () => {
  const [createCounterOffer, result] = useMutation(mutation, {
    onError: () => null,
    refetchQueries: ['Offers']
  })

  return [createCounterOffer, result]
}
