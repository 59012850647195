import React, { useEffect } from 'react'
import {
  licensePlate,
  number,
  year,
  cpf,
  cnpj,
  cep,
  phone,
  rntrc
} from 'utils/mask'
import { getFormikError } from 'services'
import { Row, Col, Button } from 'reactstrap'
import { TextInput, Select, ShouldRender } from 'components'
import {
  useVehicleBodyworks,
  useVehicleCategories,
  useVehicleTrackers,
  useVehicleColors,
  useVehicleModels,
  useVehicleBrands,
  useLazyCities
} from 'queries'
import { useLazyQuery } from '@apollo/react-hooks'

const Form: React.FC<VehicleType.Props> = ({ formik, loading }) => {
  const {
    values,
    getFieldMeta,
    getFieldProps,
    setFieldValue,
    handleSubmit,
    setFieldTouched
  } = formik

  const {
    data: categories,
    loading: loadingCategories
  } = useVehicleCategories()
  const { data: brands, loading: loadingBrands } = useVehicleBrands()
  const { data: colors, loading: loadingColors } = useVehicleColors()
  const { data: models, loading: loadingModels, refetch } = useVehicleModels()
  const { data: trackers, loading: loadingTrackers } = useVehicleTrackers()
  const { data: bodyworks, loading: loadingBodyworks } = useVehicleBodyworks()
  const [
    getCities,
    { data: list, loading: loadingCities }
  ] = useLazyQuery(useLazyCities, { fetchPolicy: 'no-cache' })

  const searchCities = async (inputValue: string, callback: any) => {
    if (!inputValue || inputValue.trim().length < 3) {
      return callback([])
    }

    if (inputValue.trim().length >= 3) {
      getCities({ variables: { name: inputValue } })

      if (list) {
        const options = list?.cities?.map((city: Record<string, any>) => ({
          label: `${city.label}, ${city.state?.initials}, ${city.state?.country?.name}`,
          value: city.value,
          state: city?.state?.id,
          country: city?.state?.country?.id
        }))

        callback(options)
      }
    }
  }

  useEffect(() => {
    refetch({ vehicleBrandId: values?.vehicleBrand?.value })
  }, [values?.vehicleBrand?.value])

  return (
    <React.Fragment>
      <Row>
        <TextInput
          size={{ xs: 12, sm: 4 }}
          label='Placa'
          {...getFieldProps('licensePlate', 'text')}
          error={getFormikError(getFieldMeta, 'licensePlate')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('licensePlate', licensePlate(e.target.value))
          }}
        />

        <TextInput
          size={{ xs: 12, sm: 4 }}
          label='Documento'
          {...getFieldProps('document', 'text')}
          error={getFormikError(getFieldMeta, 'document')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('document', number(e.target.value))
          }}
        />

        <TextInput
          size={{ xs: 12, sm: 4 }}
          label='Renavam'
          {...getFieldProps('renavam', 'text')}
          error={getFormikError(getFieldMeta, 'renavam')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('renavam', number(e.target.value))
          }}
        />

        <TextInput
          size={{ xs: 12, sm: 4 }}
          label='Chassi'
          {...getFieldProps('chassis', 'text')}
          error={getFormikError(getFieldMeta, 'chassis')}
        />

        <TextInput
          size={{ xs: 12, sm: 4 }}
          label='RNTRC'
          {...getFieldProps('anttRntrc', 'text')}
          error={getFormikError(getFieldMeta, 'anttRntrc')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('anttRntrc', rntrc(e.target.value))
          }}
        />

        <TextInput
          size={{ xs: 12, sm: 4 }}
          label='Vencimento ANTT'
          type='date'
          {...getFieldProps('anttDueDate', 'text')}
          error={getFormikError(getFieldMeta, 'anttDueDate')}
        />

        <Select
          size={{ xs: 12, sm: 6, md: 4 }}
          label='Marca'
          name='vehicleBrand'
          value={values['vehicleBrand']}
          setFieldValue={(_: string, selected: any) => {
            setFieldValue('vehicleBrand', selected)
            setFieldValue('vehicleModel', undefined)
          }}
          loading={loadingBrands}
          setFieldTouched={setFieldTouched}
          error={getFormikError(getFieldMeta, 'vehicleBrand')}
          options={brands?.list}
        />

        <Select
          size={{ xs: 12, sm: 6, md: 4 }}
          label='Modelo'
          name='vehicleModel'
          value={values['vehicleModel']}
          setFieldValue={setFieldValue}
          loading={loadingModels}
          setFieldTouched={setFieldTouched}
          error={getFormikError(getFieldMeta, 'vehicleModel')}
          options={models?.list}
        />

        <Select
          size={{ xs: 12, sm: 6, md: 4 }}
          label='Categoria'
          name='vehicleCategory'
          disabled
          value={values['vehicleCategory']}
          setFieldValue={setFieldValue}
          loading={loadingCategories}
          setFieldTouched={setFieldTouched}
          error={getFormikError(getFieldMeta, 'vehicleCategory')}
          options={categories?.list}
        />

        <Select
          size={{ xs: 12, sm: 6, md: 4 }}
          label='Carroceria'
          name='vehicleBodywork'
          disabled
          value={values['vehicleBodywork']}
          setFieldValue={setFieldValue}
          loading={loadingBodyworks}
          setFieldTouched={setFieldTouched}
          error={getFormikError(getFieldMeta, 'vehicleBodywork')}
          options={bodyworks?.list}
        />

        <Select
          size={{ xs: 12, sm: 6, md: 4 }}
          label='Assoalho'
          name='vehicleFloor'
          disabled
          value={values['vehicleFloor']}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          error={getFormikError(getFieldMeta, 'vehicleFloor')}
          options={[
            { value: 'not_applicable', label: 'Não aplicável' },
            { value: 'iron', label: 'Ferro' },
            { value: 'wood', label: 'Madeira' },
            { value: 'aluminum', label: 'Alumínio' }
          ]}
        />

        <Select
          size={{ xs: 12, sm: 6, md: 4 }}
          label='Cor'
          name='vehicleColor'
          value={values['vehicleColor']}
          setFieldValue={setFieldValue}
          loading={loadingColors}
          setFieldTouched={setFieldTouched}
          error={getFormikError(getFieldMeta, 'vehicleColor')}
          options={colors?.list}
        />

        <Select
          size={{ xs: 12, sm: 6, md: 4 }}
          label='Rastreador'
          name='vehicleTracker'
          disabled
          value={values['vehicleTracker']}
          setFieldValue={setFieldValue}
          loading={loadingTrackers}
          setFieldTouched={setFieldTouched}
          error={getFormikError(getFieldMeta, 'vehicleTracker')}
          options={trackers?.list}
        />

        <Select
          size={{ xs: 12, sm: 6, md: 4 }}
          label='País de fabricação'
          name='nationality'
          value={values['nationality']}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          error={getFormikError(getFieldMeta, 'nationality')}
          options={[
            { value: 'national', label: 'Brasil' },
            { value: 'foreign', label: 'Outro' }
          ]}
        />

        <TextInput
          size={{ xs: 12, sm: 4 }}
          label='Ano'
          {...getFieldProps('year', 'text')}
          error={getFormikError(getFieldMeta, 'year')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('year', year(e.target.value))
          }}
        />

        <TextInput
          size={{ xs: 12, sm: 4 }}
          label='Eixos'
          {...getFieldProps('axis', 'text')}
          error={getFormikError(getFieldMeta, 'axis')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('axis', number(e.target.value))
          }}
        />

        <TextInput
          size={{ xs: 12, sm: 4 }}
          label='Capacidade Kg'
          disabled
          {...getFieldProps('capacityKg', 'text')}
          error={getFormikError(getFieldMeta, 'capacityKg')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('capacityKg', number(e.target.value))
          }}
        />

        <TextInput
          size={{ xs: 12, sm: 4 }}
          label='Capacidade ㎥'
          disabled
          {...getFieldProps('capacityM3', 'text')}
          error={getFormikError(getFieldMeta, 'capacityM3')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('capacityM3', number(e.target.value))
          }}
        />

        <Select
          size={{ xs: 12, sm: 6, md: 4 }}
          label='Cidade'
          name='city'
          loading={loadingCities}
          value={values['city']}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          error={getFormikError(getFieldMeta, 'city')}
          loadOptions={searchCities}
        />
      </Row>
      <hr className='my-3' />
      <Row>
        <Col xs='12'>
          <legend>Proprietário</legend>
        </Col>
      </Row>

      <Row>
        <TextInput
          size={{ xs: 12, sm: 4 }}
          label='Nome'
          {...getFieldProps('ownerName', 'text')}
          error={getFormikError(getFieldMeta, 'ownerName')}
        />

        <Select
          size={{ xs: 12, sm: 4 }}
          label='Tipo de documento'
          name='ownerDocumentType'
          value={values['ownerDocumentType']}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          error={getFormikError(getFieldMeta, 'ownerDocumentType')}
          options={[
            { label: 'CPF', value: 'cpf' },
            { label: 'CNPJ', value: 'cnpj' }
          ]}
        />

        <TextInput
          size={{ xs: 12, sm: 4 }}
          label={values['ownerDocumentType']?.label || 'CPF'}
          {...getFieldProps('ownerDocument', 'text')}
          error={getFormikError(getFieldMeta, 'ownerDocument')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue(
              'ownerDocument',
              values['ownerDocumentType']?.value === 'cnpj'
                ? cnpj(e.target.value)
                : cpf(e.target.value)
            )
          }}
        />

        <Select
          size={{ xs: 12, sm: 4 }}
          label='Nacionalidade'
          name='ownerNationality'
          value={values['ownerNationality']}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          error={getFormikError(getFieldMeta, 'ownerNationality')}
          options={[
            { value: 'national', label: 'Brasileiro(a)' },
            { value: 'foreign', label: 'Estrangeiro(a)' }
          ]}
        />

        <Select
          size={{ xs: 12, sm: 4 }}
          label='Cidade'
          name='ownerCity'
          loading={loadingCities}
          value={values['ownerCity']}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          error={getFormikError(getFieldMeta, 'ownerCity')}
          loadOptions={searchCities}
        />

        <TextInput
          label='Telefone'
          size={{ xs: 12, sm: 4 }}
          {...getFieldProps('ownerPhone', 'text')}
          error={getFormikError(getFieldMeta, 'ownerPhone')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('ownerPhone', phone(e.target.value))
          }}
        />

        <TextInput
          label='E-mail'
          size={{ xs: 12, sm: 4 }}
          {...getFieldProps('ownerEmail', 'text')}
          error={getFormikError(getFieldMeta, 'ownerEmail')}
        />
      </Row>

      <hr className='my-3' />
      <Row>
        <Col xs='12'>
          <legend>Arrendatário</legend>
        </Col>
      </Row>

      <Row>
        <Select
          size={{ xs: 12, sm: 4 }}
          label='Veículo alienado?'
          name='hasLessee'
          value={values['hasLessee']}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          error={getFormikError(getFieldMeta, 'hasLessee')}
          options={[
            { value: true, label: 'Sim' },
            { value: false, label: 'Não' }
          ]}
        />

        <ShouldRender if={!!values['hasLessee'].value}>
          <React.Fragment>
            <TextInput
              size={{ xs: 12, sm: 4 }}
              label='Nome'
              {...getFieldProps('lesseeName', 'text')}
              error={getFormikError(getFieldMeta, 'lesseeName')}
            />

            <Select
              size={{ xs: 12, sm: 4 }}
              label='Tipo de documento'
              name='lesseeDocumentType'
              value={values['lesseeDocumentType']}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              error={getFormikError(getFieldMeta, 'lesseeDocumentType')}
              options={[
                { label: 'CPF', value: 'cpf' },
                { label: 'CNPJ', value: 'cnpj' }
              ]}
            />

            <TextInput
              size={{ xs: 12, sm: 4 }}
              label={values['lesseeDocumentType']?.label || 'CPF'}
              {...getFieldProps('lesseeDocument', 'text')}
              error={getFormikError(getFieldMeta, 'lesseeDocument')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue(
                  'lesseeDocument',
                  values['lesseeDocumentType']?.value === 'cnpj'
                    ? cnpj(e.target.value)
                    : cpf(e.target.value)
                )
              }}
            />

            <Select
              size={{ xs: 12, sm: 4 }}
              label='Nacionalidade'
              name='lesseeNationality'
              value={values['lesseeNationality']}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              error={getFormikError(getFieldMeta, 'lesseeNationality')}
              options={[
                { value: 'national', label: 'Brasileiro(a)' },
                { value: 'foreign', label: 'Estrangeiro(a)' }
              ]}
            />

            <Select
              size={{ xs: 12, sm: 4 }}
              label='Cidade'
              name='lesseeCity'
              loading={loadingCities}
              value={values['lesseeCity']}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              error={getFormikError(getFieldMeta, 'lesseeCity')}
              loadOptions={searchCities}
            />

            <TextInput
              label='Telefone'
              size={{ xs: 12, sm: 4 }}
              {...getFieldProps('lesseePhone', 'text')}
              error={getFormikError(getFieldMeta, 'lesseePhone')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('lesseePhone', phone(e.target.value))
              }}
            />

            <TextInput
              label='E-mail'
              size={{ xs: 12, sm: 4 }}
              {...getFieldProps('lesseeEmail', 'text')}
              error={getFormikError(getFieldMeta, 'lesseeEmail')}
            />
          </React.Fragment>
        </ShouldRender>
      </Row>

      <Row className='flex-row-reverse mb-4'>
        <Col sm={3} xl={2}>
          <Button
            block
            color='success'
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? 'Salvando' : 'Salvar'}
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Form
