import gql from 'graphql-tag'

export default gql`
  query CompanyFreight($id: ID) {
    list: companyFreights(id: $id) {
      status
      id
    }
  }
`
