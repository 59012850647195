import React, { useState } from 'react'
import Spinner from '../Spinner'
import ShouldRender from '../ShouldRender'
import {
  Button, 
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  Col,
  Input,
  Row,
  FormGroup,
  Label
} from 'reactstrap'
import { Select } from 'components'

import './style.css'

const FeedbackModal: React.FC<FeedbackModalType.Props> = ({ modalData }) => {
  const [comment, setComment] = useState<string>('')
  const [status, setStatus] = useState<{
    status: any
  }>({
    status: {
      value: null
    }
  })


  const options = [
    { label: 'Cancelado', value: 'cancelled' },
    { label: 'Finalizado', value: 'finished' },
    { label: 'Expirado', value: 'expired' }
  ];

  return (
    <Modal
      isOpen={!!modalData}
      toggle={modalData?.onCancel}
      className='lineless-modal'
    >
      <ModalHeader toggle={modalData?.onCancel}>{modalData?.title}</ModalHeader>
      <ShouldRender if={!!modalData?.message}>
        <React.Fragment>
          <ModalBody>
            {/* <Row>
              <Select
                label='Status'
                name='status'
                value={status['status']}
                setFieldValue={setStatus}
                setFieldTouched={setStatus}
                options={options}
              />
            </Row> */}
            <Row>
              <Col xs="12">
                <FormGroup >
                  <Label for='exampleText'>Comentário:</Label>
                  <Input
                    type='textarea'
                    name='text'
                    onChange={(evt) => setComment(evt.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row> 
          </ModalBody>

          <ShouldRender if={!!modalData?.loading}>
            <Spinner />
          </ShouldRender>
        </React.Fragment>
      </ShouldRender>
      <ShouldRender if={!modalData?.loading}>
        <ModalFooter>
          <Button onClick={modalData?.onCancel} className='btn-secondary-light'>
            Cancelar
          </Button>
          <Button onClick={ () => modalData?.onConfirm(comment) } className='btn-primary-yellow'>
            Confirmar
          </Button>
        </ModalFooter>
      </ShouldRender>
    </Modal>
  )
}

export default FeedbackModal
