import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const query = gql`
  query CurrentUser {
    currentUser {
      document
      email
      id
      name
      phone
      provider
      uid
      role
    }
  }
`

export default () => useQuery(query, { fetchPolicy: 'network-only' })
