const camelize: ChangeCase.Method = (str) => {
  return str
    .replace(/(-|_|\.|\s)+(.)?/g, (match, separator, chr) =>
      chr ? chr.toUpperCase() : ''
    )
    .replace(/(^|\/)([A-Z])/g, (match: string) => match.toLowerCase())
}

const decamelize = (str: string) =>
  str.replace(/([a-z\d])([A-Z])/g, '$1_$2').toLowerCase()

const capitalize = (str: any) => {
  return str.toLowerCase().replace(/(?:^|\s)\S/g, (l: string) => {
    return l.toUpperCase()
  })
}

export { camelize, decamelize, capitalize }
