import React, { useEffect } from 'react'
import { useLazyFreightOffers, useCurrentUser } from 'queries'
import { useLazyQuery } from '@apollo/react-hooks'
import { Table, Spinner, ShouldRender, Map } from 'components'
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { ActionsFormatter, StatusFormatter } from './formatters'
import { money } from 'utils/mask'

const FreightNegotiation: React.FC<{ id?: string }> = ({ id }) => {
  const [getOffers, { data, loading }] = useLazyQuery(useLazyFreightOffers, {
    variables: { freightId: id },
    fetchPolicy: 'no-cache'
  })

  const { data: user } = useCurrentUser()

  useEffect(() => {
    getOffers()
  }, [])

  const normalizeData = () => {
    if (data?.offers) {
      const output = data.offers
        .filter((x: any) => {
          if (x.type === 'new_offer') {
            const index = data.offers.findIndex((y: any) => {
              return y.type === 'counter_offer' && y?.offer?.id === x.id
            })

            return index === -1 ? x : null
          }

          return x
        })
        .map((offer: Record<string, any>) => ({
          id: offer.id,
          status: offer.status,
          value: offer.value,
          comments: offer.comments ? offer.comments : '' ,
          requireAnalysis: offer?.freight?.requireAnalysis,
          offer:
            offer?.type === 'new_offer'
              ? offer?.value
                ? money(offer?.value?.toString())
                : ''
              : offer?.offer?.offerValue
              ? money(offer?.offer?.offerValue?.toString())
              : '',
          counter:
            offer?.type === 'counter_offer'
              ? offer?.value
                ? money(offer?.value?.toString())
                : ''
              : '',
          vehicle: `${offer?.vehicle?.truck?.category?.description}/${
            offer?.vehicle?.implement?.category?.description
              ? `${offer?.vehicle?.implement?.category?.description} - ${offer?.vehicle?.implement?.bodywork?.description}`
              : 'Não aplicável'
          }`
        }))

      return output
    }

    return []
  }

  const generateMarkers = () => {
    const markers = data?.offers
      ?.map((offer: any) => {
        return offer?.vehicle?.driver?.currentPosition?.length > 0
          ? {
              lat: offer?.vehicle?.driver?.currentPosition[0],
              lng: offer?.vehicle?.driver?.currentPosition[1],
              isFuture: offer?.responsible?.futurePosition || false
            }
          : undefined
      })
      .filter((x: Record<string, any>) => !!x?.lat && !!x?.lng)
    return markers || []
  }

  const columns: TableType.ColumnsType[] = [
    {
      title: 'Veículo/Reboque',
      dataField: 'vehicle',
      isKey: true,
      sortable: true
    },
    { title: 'Proposta', dataField: 'offer', sortable: true },
    { title: 'Contra proposta', dataField: 'counter', sortable: true },
    {
      title: 'Status',
      dataField: 'status',
      sortable: true,
      formatter: (_, row) => <StatusFormatter row={row} />
    },
    {
      title: 'Comentário',
      dataField: 'comments',
      sortable: false,
    },
    {
      title: 'Ações',
      formatter: (_, row) => <ActionsFormatter row={row} />
    }
  ]

  if (
    user?.currentUser?.role === 'back_office' ||
    [
      'finished',
      'cancelled',
      'rejected_by_contractor',
      'rejected_by_provider'
    ].includes(data?.offers[0]?.freight?.status)
  ) {
    columns.pop()
  }

  return (
    <Card>
      <CardHeader>Negociação</CardHeader>
      <CardBody>
        <ShouldRender if={!loading}>
          <Map markers={generateMarkers()} />
        </ShouldRender>

        <Row className='flex-row-reverse my-4'>
          <Col sm={3} xl={2}>
            <Button
              block
              color='success'
              onClick={() => {
                getOffers()
              }}
              disabled={loading}
            >
              Recarregar
            </Button>
          </Col>
        </Row>

        <ShouldRender if={loading}>
          <Spinner />
        </ShouldRender>

        <ShouldRender if={!loading}>
          <Table columns={columns} data={normalizeData()} />
        </ShouldRender>
      </CardBody>
    </Card>
  )
}

export default FreightNegotiation
