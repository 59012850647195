import gql from 'graphql-tag'

export default gql`
  query LazyCompanyFreight($id: ID) {
    list: companyFreights(id: $id) {
      acceptedBodyworkTypeIds
      acceptedVehicleTypeIds
      acceptedVehicleFloor
      capacityKg
      capacityL
      capacityM3
      capacityPallets
      requireAnalysis
      status
      cargoCategory {
        value: id
        label: description
      }
      cargoType {
        value: id
        label: description
      }
      cargoValue
      endCity {
        value: id
        label: name
      }
      endDate
      freightFirstValue
      freightObservations
      id
      operator {
        value: id
        label: name
      }
      paymentAdvance
      paymentMethod
      startCity {
        value: id
        label: name
      }
      startDate
      tracked
      waypoints {
        expectedAt
        address
        city {
          value: id
          label: name
        }
        complement
        district
        id
        kind
        number
        waypointOrder
        zipCode
      }
    }
  }
`
