import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const mutation = gql`
  mutation CancelFreight($id: ID!) {
    cancelledFreight: cancelFreight(id: $id) {
      id
    }
  }
`

export default () => {
  const [cancelFreight, result] = useMutation(mutation, {
    onError: () => null,
    refetchQueries: ['CompanyFreights']
  })

  return [cancelFreight, result]
}
