import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const query = gql`
  query Vehicles($id: ID) {
    vehicles(id: $id) {
      anttDueDate
      anttRntrc
      axis
      capacityKg
      capacityM3
      chassis
      document
      id
      nationality
      licensePlate
      parts
      renavam
      tare
      vehicleFloor
      year
      city {
        value: id
        label: name
        state {
          initials
          country {
            name
          }
        }
      }
      vehicleBodywork {
        label: description
        value: id
      }
      vehicleBrand {
        label: description
        value: id
      }
      vehicleCategory {
        label: description
        value: id
        kind
      }
      vehicleColor {
        label: description
        value: id
      }
      vehicleDocument {
        id
        status
        url
      }
      vehicleModel {
        label: description
        value: id
      }
      vehicleTracker {
        label: description
        value: id
      }
      vehicleOwners {
        address
        city {
          id
          name
          state {
            initials
          }
        }
        complement
        district
        email
        document
        id
        kind
        name
        nationality
        number
        phone
        zipCode
      }
    }
  }
`

export default (params?: any) =>
  useQuery(query, {
    fetchPolicy: 'no-cache',
    ...params
  })
