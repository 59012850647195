import gql from 'graphql-tag'

export default gql`
  query LazyPaginatedVehicles($completedVehicleData: Boolean, $after: String) {
    list: paginatedVehicles(
      first: 30
      after: $after
      filter: { completedVehicleData: $completedVehicleData }
    ) {
      nodes {
        id
        completedVehicleData
        company {
          name
        }
        licensePlate
        brand: vehicleBrand {
          description
        }
        model: vehicleModel {
          description
        }
        category: vehicleCategory {
          description
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
`
