import React, { useState } from 'react'
import columnSize from 'utils/columnSize'
import ShouldRender from '../ShouldRender'

import {
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Col,
  FormText,
  Tooltip
} from 'reactstrap'

const TextInput: React.FC<TextInputType.Props> = ({
  name,
  label,
  labelIcon,
  labelIconTooltip,
  size = 12,
  hint,
  disabled,
  placeholder,
  value,
  error,
  touched,
  onChange,
  type = 'text',
  style
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => {
    setTooltipOpen(!tooltipOpen)
  }

  return (
    <Col {...columnSize(size)}>
      <FormGroup className='custom-form-group'>
        <Label>
          {label}
          <ShouldRender if={!!labelIcon}>
            <React.Fragment>
              <span id={name}>
                {' '}
                <i className={labelIcon} aria-hidden='true'></i>
              </span>
              <ShouldRender if={!!labelIconTooltip}>
                <Tooltip
                  placement='top'
                  isOpen={tooltipOpen}
                  target={name}
                  toggle={toggle}
                >
                  {labelIconTooltip}
                </Tooltip>
              </ShouldRender>
            </React.Fragment>
          </ShouldRender>
        </Label>
        <Input
          name={name}
          type={type}
          value={value}
          touched={touched}
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholder}
          invalid={touched && error}
          style={style}
        />

        <ShouldRender if={!!hint}>
          <FormText color='muted'>{hint}</FormText>
        </ShouldRender>
        <FormFeedback className={`form-message ${error ? 'show-error' : ''}`}>
          {error}
        </FormFeedback>
      </FormGroup>
    </Col>
  )
}

export default TextInput
