import Form from './Form'
import { useVehicleForm } from 'forms'
import React, { useEffect } from 'react'
import format from 'date-fns/format'
import { useParams } from 'react-router-dom'
import { ShouldRender, Spinner, ImageGallery } from 'components'
import { Card, CardHeader, CardBody, Row, Col, Button } from 'reactstrap'
import { useVehicles } from 'queries'
import { useUpdateFullVehicle, useUpdateVehicleDocumentStatus } from 'mutations'
import { notify } from 'services'
import { capitalize } from 'utils/changeCase'
import { remove, cpf, cnpj, phone, cep } from 'utils/mask'

const Vehicle: React.FC = () => {
  const { id } = useParams()
  const { data, loading } = useVehicles({ variables: { id } })
  const [
    update,
    { data: vehicle, loading: updating }
  ]: any = useUpdateFullVehicle()

  const [
    updateDocumentStatus,
    { data: updated, loading: updatingDocument, error: updateError }
  ]: any = useUpdateVehicleDocumentStatus()

  useEffect(() => {
    if (vehicle?.updated?.id) {
      notify({
        type: 'success',
        description: 'Veículo atualizado'
      })
    }
  }, [vehicle])

  useEffect(() => {
    if (updated?.document?.id) {
      notify({
        type: 'success',
        description: 'Status do documento atualizado'
      })
    }
  }, [updated, updateError])

  const handleFloor = () => {
    const options = [
      { value: 'not_applicable', label: 'Não aplicável' },
      { value: 'iron', label: 'Ferro' },
      { value: 'wood', label: 'Madeira' },
      { value: 'aluminum', label: 'Alumínio' }
    ]

    const index = options.findIndex((x: Record<string, string>) => {
      return x.value === data?.vehicles[0]?.vehicleFloor
    })

    return index > -1 ? options[index] : { value: '', label: '' }
  }

  const handleNationality = () => {
    const options = [
      { value: 'national', label: 'Brasil' },
      { value: 'foreign', label: 'Outro' }
    ]

    const index = options.findIndex((x: Record<string, string>) => {
      return x.value === data?.vehicles[0]?.nationality
    })

    return index > -1 ? options[index] : { value: '', label: '' }
  }

  const handleImageStatus = (status: string) => {
    const options = [
      { input: 'pending', output: 'Processando' },
      { input: 'auto_rejected', output: 'Rejeitado pelo sistema' },
      { input: 'auto_approved', output: 'Aprovado pelo sistema' },
      { input: 'manual_approved', output: 'Aprovado manualmente' },
      { input: 'manual_rejected', output: 'Rejeitado manualmente' },
      { input: 'missing_data', output: 'Aguardando interação manual' }
    ]

    const index = options.findIndex((x: Record<string, string>) => {
      return x.input === status
    })

    return options[index > -1 ? index : 0].output
  }

  const ownerIndex = data?.vehicles[0]?.vehicleOwners.findIndex(
    (x: Record<string, any>) => x.kind === 'owner'
  )

  const lesseeIndex = data?.vehicles[0]?.vehicleOwners.findIndex(
    (x: Record<string, any>) => x.kind === 'lessee'
  )

  const generateOwners = () => {
    const output = {
      ownerDocument: '',
      ownerDocumentType: { label: '', value: '' },
      ownerNationality: { label: '', value: '' },
      ownerPhone: '',
      ownerZipCode: '',
      ownerCity: {
        label: '',
        value: '',
        id: undefined,
        name: undefined,
        state: undefined
      },
      lesseeDocument: '',
      lesseeDocumentType: { label: '', value: '' },
      lesseeNationality: { label: '', value: '' },
      lesseePhone: '',
      lesseeZipCode: '',
      lesseeCity: {
        label: '',
        value: '',
        id: undefined,
        name: undefined,
        state: undefined
      }
    }

    if (ownerIndex > -1) {
      const keys = Object.keys(data?.vehicles[0]?.vehicleOwners[ownerIndex])

      keys.map((key: string) => {
        // @ts-ignore
        return (output[`owner${capitalize(key)}`] =
          data?.vehicles[0]?.vehicleOwners[ownerIndex][key] || '')
      })

      const isCPF = remove(output.ownerDocument).length === 11

      output.ownerDocument = isCPF
        ? cpf(output.ownerDocument)
        : cnpj(output.ownerDocument)

      output.ownerDocumentType = isCPF
        ? { label: 'CPF', value: 'cpf' }
        : { label: 'CNPJ', value: 'cnpj' }

      output.ownerNationality = !!output.ownerNationality
        ? output.ownerNationality === ('national' as any)
          ? { label: 'Brasileiro(a)', value: 'national' }
          : { label: 'Estrangeiro(a)', value: 'foreign' }
        : { label: '', value: '' }

      output.ownerPhone = phone(output.ownerPhone)
      output.ownerZipCode = cep(
        data?.vehicles[0]?.vehicleOwners[ownerIndex]['zipCode']
      )

      output.ownerCity = {
        // @ts-ignore
        label: `${output.ownerCity?.name}, ${output.ownerCity?.state?.initials}`,
        value: output.ownerCity?.id as any,
        id: undefined,
        state: undefined,
        name: undefined
      }
    }

    if (lesseeIndex > -1) {
      const keys = Object.keys(data?.vehicles[0]?.vehicleOwners[lesseeIndex])

      keys.map((key: string) => {
        // @ts-ignore
        return (output[`lessee${capitalize(key)}`] =
          data?.vehicles[0]?.vehicleOwners[lesseeIndex][key] || '')
      })

      const isCPF = remove(output.lesseeDocument).length === 11

      output.lesseeDocument = isCPF
        ? cpf(output.lesseeDocument)
        : cnpj(output.lesseeDocument)

      output.lesseeDocumentType = isCPF
        ? { label: 'CPF', value: 'cpf' }
        : { label: 'CNPJ', value: 'cnpj' }

      output.lesseeNationality = !!output.lesseeNationality
        ? output.lesseeNationality === ('national' as any)
          ? { label: 'Brasileiro(a)', value: 'national' }
          : { label: 'Estrangeiro(a)', value: 'foreign' }
        : { label: '', value: '' }

      output.lesseePhone = phone(output.lesseePhone)
      output.lesseeZipCode = cep(
        data?.vehicles[0]?.vehicleOwners[lesseeIndex]['zipCode']
      )

      output.lesseeCity = {
        // @ts-ignore
        label: `${output.lesseeCity?.name}, ${output.lesseeCity?.state?.initials}`,
        value: output.lesseeCity?.id as any,
        id: undefined,
        state: undefined,
        name: undefined
      }
    }

    return output
  }

  const formik = useVehicleForm({
    onSubmit: (values) => {
      const variables = {
        vehicle: {
          id,
          anttDueDate: format(new Date(values.anttDueDate.replace(/-/g, '\/')), 'y-MM-dd'),
          anttRntrc: values?.anttRntrc,
          axis: Number(values?.axis),
          capacityKg: Number(values?.capacityKg),
          capacityM3: Number(values?.capacityM3),
          chassis: values?.chassis,
          cityId: values?.city?.value || null,
          document: remove(values?.document || ''),
          licensePlate: values?.licensePlate,
          nationality: values?.nationality?.value || '',
          renavam: values?.renavam,
          tare: Number(values?.tare),
          vehicleFloor: values?.vehicleFloor?.value || '',
          vehicleCategoryId: values?.vehicleCategory?.value || '',
          vehicleBrandId: values?.vehicleBrand?.value || '',
          vehicleModelId: values?.vehicleModel?.value || '',
          vehicleColorId: values?.vehicleColor?.value || '',
          vehicleTrackerId: values?.vehicleTracker?.value || '',
          vehicleBodyworkId: values?.vehicleBodywork?.value || null,
          year: Number(values?.year)
        },
        owner: {
          address: values?.ownerAddress,
          cityId: values?.ownerCity?.value,
          complement: values?.ownerComplement,
          district: values?.ownerDistrict,
          document: remove(values?.ownerDocument || ''),
          email: values?.ownerEmail,
          id: values?.ownerId,
          kind: values?.ownerKind,
          name: values?.ownerName,
          nationality: values?.ownerNationality?.value,
          number: values?.ownerNumber,
          phone: remove(values?.ownerPhone || ''),
          zipCode: remove(values?.ownerZipCode || '')
        },
        lessee: {
          address: values?.hasLessee?.value ? values?.lesseeAddress : '',
          cityId: values?.lesseeCity?.value || 'foo',
          complement: values?.hasLessee?.value ? values?.lesseeComplement : '',
          district: values?.hasLessee?.value ? values?.lesseeDistrict : '',
          document: values?.hasLessee?.value
            ? remove(values?.lesseeDocument || '')
            : null,
          email: values?.hasLessee?.value ? values?.lesseeEmail : '',
          id: values?.lesseeId || '',
          kind: values?.lesseeKind || 'lessee',
          name: values?.hasLessee?.value ? values?.lesseeName : '',
          nationality: values?.lesseeNationality?.value || 'national',
          number: values?.hasLessee?.value ? values?.lesseeNumber : '',
          phone: values?.hasLessee?.value
            ? remove(values?.lesseePhone || '')
            : '',
          zipCode: values?.hasLessee?.value
            ? remove(values?.lesseeZipCode || '')
            : ''
        }
      }

      !values?.hasLessee?.value && !values?.lesseeId && delete variables.lessee

      update({ variables })
    },
    initialValues: {
      ...data?.vehicles[0],
      vehicleFloor: handleFloor(),
      nationality: handleNationality(),
      vehicleBodywork: data?.vehicles[0]?.vehicleBodywork || {
        label: '',
        value: ''
      },
      city: {
        value: data?.vehicles[0]?.city?.value,
        label: `${data?.vehicles[0]?.city?.label}, ${data?.vehicles[0]?.city?.state?.initials}, ${data?.vehicles[0]?.city?.state?.country?.name}`
      },
      ...generateOwners(),
      hasLessee: {
        value: lesseeIndex > -1,
        label: lesseeIndex > -1 ? 'Sim' : 'Não'
      }
    }
  })

  return (
    <Card>
      <CardHeader>Veículo</CardHeader>
      <CardBody>
        <ShouldRender if={loading}>
          <Spinner />
        </ShouldRender>

        <ShouldRender if={!loading}>
          <React.Fragment>
            <legend>Documentos enviados</legend>
            <ShouldRender if={data?.vehicles[0]?.vehicleDocument?.url}>
              <React.Fragment>
                <ImageGallery
                  images={[data?.vehicles[0]?.vehicleDocument?.url]}
                />
                <Row className='mb-4'>
                  <Col md='3' className='text-center'>
                    <small className='font-italic'>CRLV</small>
                    <p className='font-weight-bold'>
                      {handleImageStatus(
                        data?.vehicles[0]?.vehicleDocument?.status
                      )}
                    </p>

                    <Button
                      block
                      color='success'
                      onClick={() =>
                        updateDocumentStatus({
                          variables: {
                            id: data?.vehicles[0]?.vehicleDocument?.id,
                            status: 'manual_approved'
                          }
                        })
                      }
                      disabled={
                        updatingDocument ||
                        ['auto_approved', 'manual_approved'].includes(
                          data?.vehicles[0]?.vehicleDocument?.status
                        )
                      }
                    >
                      {updatingDocument ? 'Atualizando' : 'Aprovar'}
                    </Button>

                    <Button
                      block
                      color='danger'
                      onClick={() =>
                        updateDocumentStatus({
                          variables: {
                            id: data?.vehicles[0]?.vehicleDocument?.id,
                            status: 'manual_rejected'
                          }
                        })
                      }
                      disabled={
                        updatingDocument ||
                        ['auto_rejected', 'manual_rejected'].includes(
                          data?.vehicles[0]?.vehicleDocument?.status
                        )
                      }
                    >
                      {updatingDocument ? 'Atualizando' : 'Rejeitar'}
                    </Button>
                  </Col>
                </Row>
              </React.Fragment>
            </ShouldRender>

            <ShouldRender if={!data?.vehicles[0]?.vehicleDocument?.url}>
              <p>CRLV não enviado</p>
            </ShouldRender>
            <Form formik={formik} loading={updating} />
          </React.Fragment>
        </ShouldRender>
      </CardBody>
    </Card>
  )
}

export default Vehicle
