import React, { useState } from 'react'
import { phone, cpf, cnpj, cellphone } from 'utils/mask'
import { getFormikError } from 'services'
import { Row, Col, Button, FormGroup, Label, Input } from 'reactstrap'
import { TextInput, Select, ShouldRender } from 'components'

const Form: React.FC<UserType.Props> = ({ formik, loading }) => {
  const {
    values,
    getFieldMeta,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
    handleSubmit
  } = formik

  const [checked, setChecked] = useState(false)

  return (
    <React.Fragment>
      <Row>
        <Col xs='12'>
          <legend>Dados do usuário</legend>
        </Col>
      </Row>

      <Row>
        <Select
          size={{ xs: 12, sm: 6 }}
          label='Tipo de contratante'
          name='contractorKind'
          value={values['contractorKind']}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          error={getFormikError(getFieldMeta, 'contractorKind')}
          options={[
            { label: 'Empresa', value: 'default' },
            { label: 'Transportadora', value: 'transporter' }
          ]}
        />
      </Row>

      <Row>
        <TextInput
          disabled={loading}
          size={{ xs: 12, sm: 6 }}
          label='Nome completo'
          {...getFieldProps('name', 'text')}
          error={getFormikError(getFieldMeta, 'name')}
        />

        <TextInput
          size={{ xs: 12, sm: 6 }}
          label='CPF'
          disabled={loading}
          {...getFieldProps('document', 'text')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('document', cpf(e.target.value))
          }}
          error={getFormikError(getFieldMeta, 'document')}
        />

        <TextInput
          disabled={loading}
          size={{ xs: 12, sm: 6 }}
          label='E-mail'
          {...getFieldProps('email', 'text')}
          error={getFormikError(getFieldMeta, 'email')}
        />

        <TextInput
          disabled={loading}
          size={{ xs: 12, sm: 6 }}
          label='Celular'
          {...getFieldProps('mobilePhone', 'text')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('mobilePhone', cellphone(e.target.value))
          }}
          error={getFormikError(getFieldMeta, 'mobilePhone')}
        />

        <TextInput
          disabled={loading}
          size={{ xs: 12, sm: 6 }}
          label='Senha'
          {...getFieldProps('password', 'text')}
          error={getFormikError(getFieldMeta, 'password')}
          type='password'
        />
        <TextInput
          disabled={loading}
          size={{ xs: 12, sm: 6 }}
          label='Repita sua senha'
          {...getFieldProps('passwordConfirmation', 'text')}
          error={getFormikError(getFieldMeta, 'passwordConfirmation')}
          type='password'
        />
      </Row>

      <Row>
        <Col xs='12'>
          {/* FIXME: Add conditional title */}
          <legend>Dados da transportadora</legend>
        </Col>
      </Row>
      <Row>
        <TextInput
          disabled={loading}
          size={{ xs: 12, sm: 6 }}
          label='CNPJ'
          {...getFieldProps('companyDocument', 'text')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('companyDocument', cnpj(e.target.value))
          }}
          error={getFormikError(getFieldMeta, 'companyDocument')}
        />

        <TextInput
          size={6}
          disabled={loading}
          label='Telefone'
          {...getFieldProps('companyPhone', 'text')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('companyPhone', phone(e.target.value))
          }}
          error={getFormikError(getFieldMeta, 'companyPhone')}
        />

        <TextInput
          size={6}
          disabled={loading}
          label='Razão social'
          {...getFieldProps('companyName', 'text')}
          error={getFormikError(getFieldMeta, 'companyName')}
        />

        <TextInput
          size={6}
          disabled={loading}
          label='Nome fantasia'
          {...getFieldProps('fantasyName', 'text')}
          error={getFormikError(getFieldMeta, 'fantasyName')}
        />
      </Row>
      <Row className='mb-4'>
        <Col sm={9} xl={10}>
          <FormGroup check>
            <FormGroup>
              <Label check>
                <Input
                  type='checkbox'
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                Li e concordo com a{' '}
                <a href='/politica-de-privacidade'>Política de Privacidade</a>
              </Label>
            </FormGroup>
          </FormGroup>
        </Col>
        <Col sm={3} xl={2}>
          <Button
            block
            color='success'
            onClick={handleSubmit}
            disabled={loading || !checked}
          >
            {loading ? 'Criando conta, aguarde...' : 'Avançar'}
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Form
