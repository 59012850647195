import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const query = gql`
  query Countries($name: String, $id: ID) {
    countries(filter: { name: $name }, id: $id) {
      id
      name
    }
  }
`

export default () =>
  useQuery(query, {
    fetchPolicy: 'no-cache'
  })
