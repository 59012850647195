import React from 'react'
import './style.css'

type StatusFormatterType = {
  row: any
}

const StatusFormatter: React.FC<StatusFormatterType> = ({ row }) => {
  const { status } = row

  const options = [
    { status: 'created', color: 'yellow', label: 'Aguardando motorista' },
    { status: 'risk_free', color: 'green', label: 'Sem riscos' },
    {
      status: 'waiting_analysis',
      color: 'green',
      label: 'Aceita pelo motorista'
    },
    { status: 'countered', color: 'yellow', label: 'Contra proposta' },
    { status: 'expired', color: 'red', label: 'Expirada' },
    {
      status: 'rejected_by_contractor',
      color: 'red',
      label: 'Recusada pelo contratante'
    },
    {
      status: 'rejected_by_provider',
      color: 'red',
      label: 'Recusada pelo motorista'
    },
    { status: 'analysing', color: 'yellow', label: 'Em análise de risco' },
    { status: 'accepted', color: 'green', label: 'Aceita' },
    {
      status: 'risk_warn',
      color: 'red',
      label: 'Recusada pela análise de risco'
    },
  ]

  const index = options.findIndex((x: Record<string, any>) => {
    return x.status === status
  })

  const selected = options[index > -1 ? index : 0]

  return <span className={selected.color}>{selected.label}</span>
}

export default StatusFormatter
