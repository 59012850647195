import React, { useState, useEffect } from 'react'
import { useLazyPaginatedCompanyFreights, useCurrentUser } from 'queries'
import { useHistory } from 'react-router-dom'
import {
  useDestroyFreight,
  usePublishFreight,
  useCancelFreight
} from 'mutations'
import { Table, Spinner, ShouldRender, WarningModal } from 'components'
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { notify } from 'services'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import handleDate from 'utils/handleDate'

const Freights: React.FC<FreightsType.Props> = () => {
  const history = useHistory()
  const { data: currentUser, loading: loadingCurrentUser } = useCurrentUser()
  const [modalData, setModalData] = useState(undefined) as any
  const [
    destroyFreight,
    { data: destroyed, loading: destroying, error }
  ]: any = useDestroyFreight()

  const [
    publishFreight,
    { data: published, loading: publishing }
  ]: any = usePublishFreight()

  const [
    cancelFreight,
    { data: cancelled, loading: cancelling, error: cancelError }
  ]: any = useCancelFreight()

  const { data, loading, refetch } = useQuery(useLazyPaginatedCompanyFreights, {
    fetchPolicy: 'no-cache',
    variables: {
      after: undefined
    }
  })

  const [freights, setFreights] = useState<any[]>([])
  const [nextPageCursor, setNextPageCursor] = useState()

  useEffect(() => {
    if (!!publishing) {
      setModalData({ ...modalData, loading: true })
    }

    if (published?.publishedFreight?.id) {
      notify({
        type: 'success',
        description: 'Frete publicado'
      })
      setFreights([])
      refetch({ after: undefined })
    }

    setModalData(undefined)
  }, [published, publishing])

  useEffect(() => {
    if (data?.list?.nodes) {
      setFreights((prevState: any) => [
        ...prevState,
        ...normalizeData(data?.list?.nodes)
      ])
    }

    if (data?.list?.pageInfo) {
      setNextPageCursor(data?.list?.pageInfo?.endCursor || undefined)
    }
  }, [data])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [nextPageCursor])

  useEffect(() => {
    if (
      destroyed?.destroyedFreight?.success ||
      cancelled?.cancelledFreight?.id
    ) {
      notify({
        type: 'success',
        description: 'Frete removido'
      })
    }

    setModalData(undefined)
  }, [destroyed, error, cancelled, cancelError])

  function handleScroll() {
    if (
      !loading &&
      !!nextPageCursor &&
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight
    )
      return refetch({ after: nextPageCursor })
  }

  const handleFreightStatus = (status: string) => {
    const options = [
      { label: 'Não publicado', value: 'created' },
      { label: 'Publicado', value: 'published' },
      { label: 'Negociado', value: 'negotiated' },
      { label: 'Em execução', value: 'ongoing' },
      { label: 'Cancelado', value: 'cancelled' },
      { label: 'Finalizado', value: 'finished' },
      { label: 'Expirado', value: 'expired' }
    ]

    const index = options.findIndex((x: Record<string, any>) => {
      return x.value === status
    })

    return index > -1 ? options[index].label : '...'
  }

  const normalizeData = (data: any) => {
    return data?.map((entry: Record<string, any>) => ({
      id: entry.id,
      name: entry.operator.name,
      from: `${entry.startCity.name}, ${entry.startCity.state.initials}`,
      to: `${entry.endCity.name}, ${entry.endCity.state.initials}`,
      status: handleFreightStatus(entry.status),
      company: entry?.publisher?.company?.name || '',
      count: entry?.offersCount || 0,
      start: entry?.waypoints?.length
        ? handleDate(entry?.waypoints[0]?.expectedAt)
        : '...',
      end: entry?.waypoints?.length
        ? handleDate(entry?.waypoints[entry?.waypoints?.length - 1]?.expectedAt)
        : '...'
    }))
  }

  const handleColumns = () => {
    const columns: TableType.ColumnsType[] = [
      { title: 'Gestor', dataField: 'name', isKey: true, sortable: true },
      { title: 'Origem', dataField: 'from', sortable: true },
      { title: 'Destino', dataField: 'to', sortable: true },
      { title: 'Carregamento', dataField: 'start', sortable: true },
      { title: 'Conclusão', dataField: 'end', sortable: true },
      { title: 'Propostas', dataField: 'count', sortable: true },
      { title: 'Status', dataField: 'status', sortable: true }
    ]

    if (currentUser?.currentUser?.role === 'back_office') {
      columns.splice(1, 0, {
        title: 'Empresa',
        dataField: 'company',
        sortable: true
      })
    }

    if (currentUser?.currentUser?.role !== 'back_office') {
      columns.push({
        actions: [
          {
            buttonLabel: 'Publicar',
            classname: '',
            action: (row) => {
              setModalData({
                title: 'Publicar frete?',
                onCancel: () => setModalData(undefined),
                onConfirm: () => {
                  setModalData({
                    ...modalData,
                    loading: publishing,
                    title: 'Publicando frete'
                  })

                  publishFreight({ variables: { id: row.id } })
                }
              })
            }
          },
          {
            classname: 'icon-note',
            action: (row) => history.push(`/fretes/${row.id}/frete`)
          },
          {
            classname: 'icon-trash ml-2',
            action: (row) => {
              const isPublished = row.status.toLowerCase() === 'publicado'

              setModalData({
                title: 'Remover frete?',
                message: 'Esta ação não pode ser revertida.',
                onCancel: () => setModalData(undefined),
                onConfirm: () => {
                  setModalData({
                    ...modalData,
                    loading: destroying,
                    title: 'Removendo frete'
                  })

                  isPublished
                    ? cancelFreight({ variables: { id: row.id } })
                    : destroyFreight({ variables: { id: row.id } })
                }
              })
            }
          }
        ]
      })
    }

    return columns
  }

  const handleRowClick = (row: TableType.FreightType) => {
    if (currentUser?.currentUser?.role === 'back_office') {
      history.push(`/fretes/${row.id}/frete`)
    }
  }

  return (
    <Card>
      <CardHeader>Fretes</CardHeader>
      <CardBody>
        <ShouldRender
          if={
            currentUser?.currentUser?.role !== 'back_office' &&
            !loadingCurrentUser
          }
        >
          <Row className='flex-row-reverse mb-4'>
            <Col sm={3} xl={2}>
              <Button
                block
                color='success'
                onClick={() => history.push('/frete')}
              >
                Adicionar
              </Button>
            </Col>
          </Row>
        </ShouldRender>

        <ShouldRender if={loadingCurrentUser}>
          <Spinner />
        </ShouldRender>

        <ShouldRender if={!loadingCurrentUser}>
          <Table
            columns={handleColumns()}
            data={freights}
            loading={loading}
            onRowClick={
              currentUser?.currentUser?.role === 'back_office'
                ? handleRowClick
                : undefined
            }
          />
        </ShouldRender>
      </CardBody>

      <WarningModal modalData={modalData} />
    </Card>
  )
}

export default Freights
