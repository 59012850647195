import React, { useState, useEffect } from 'react'
import { Button } from 'reactstrap'
import { WarningModal, ShouldRender } from 'components'
import { useAnalyseOffer, useRejectOffer, useAcceptOffer } from 'mutations'
import './style.css'
import { notify } from 'services'

type ActionsFormatterType = {
  row: any
}

const ActionsFormatter: React.FC<ActionsFormatterType> = ({ row }) => {
  const [modalData, setModalData] = useState() as any
  const [
    analyse,
    { data: analysedOffer, loading: analysing, error: analyseError }
  ]: any = useAnalyseOffer()
  const [
    accept,
    { data: acceptedOffer, loading: accepting, error: acceptError }
  ]: any = useAcceptOffer()
  const [
    reject,
    { data: rejectedOffer, loading: rejecting, error: rejectError }
  ]: any = useRejectOffer()

  const { status } = row

  useEffect(() => {
    if (
      analysedOffer?.analysedOffer?.id ||
      analyseError ||
      acceptedOffer?.accepted?.id ||
      acceptError ||
      rejectedOffer?.rejected?.id ||
      rejectError
    ) {
      return setModalData(undefined)
    }

    if (row?.requireAnalysis && !!analysedOffer?.analysedOffer?.id) {
      notify({
        type: 'success',
        description: 'Análise de risco solicitada'
      })
    }

    if (acceptedOffer?.accepted?.id) {
      notify({
        type: 'success',
        description: 'Proposta aceita'
      })
    }

    if (rejectedOffer?.rejected?.id) {
      notify({
        type: 'success',
        description: 'Proposta rejeitada'
      })
    }
  }, [
    analysedOffer,
    analyseError,
    acceptedOffer,
    acceptError,
    rejectedOffer,
    rejectError
  ])

  useEffect(() => {
    if (!row?.requireAnalysis && !!analysedOffer?.analysedOffer?.id) {
      accept({ variables: { offerId: row.id } })
    }
  }, [analysedOffer])

  const hasActions = [
    'created',
    'risk_free',
    'waiting_analysis',
    'countered',
    'analysing',
    'skipped_analysis'
  ].includes(status)

  return (
    <React.Fragment>
      <ShouldRender if={hasActions}>
        <React.Fragment>
          <ShouldRender if={row?.requireAnalysis}>
            <Button
              outline
              className='action-button blue-button'
              onClick={() =>
                setModalData({
                  title: 'Analisar proposta?',
                  message: 'Esta ação pode demorar alguns minutos.',
                  onCancel: () => setModalData(undefined),
                  onConfirm: () => {
                    setModalData({
                      ...modalData,
                      loading: analysing,
                      title: 'Solicitando análise'
                    })

                    analyse({ variables: { offerId: row.id } })
                  }
                })
              }
            >
              Analisar
            </Button>
          </ShouldRender>
          <Button
            outline
            className='action-button green-button ml-1'
            onClick={() =>
              setModalData({
                title: 'Aceitar proposta?',
                message: 'Esta ação não pode ser revertida.',
                onCancel: () => setModalData(undefined),
                onConfirm: () => {
                  setModalData({
                    ...modalData,
                    loading: accepting,
                    title: 'Aceitando proposta'
                  })
                  !row?.requireAnalysis && row.status !== 'skipped_analysis'
                    ? analyse({ variables: { offerId: row.id } })
                    : accept({ variables: { offerId: row.id } })
                }
              })
            }
          >
            Aceitar
          </Button>

          <Button
            outline
            className='action-button red-button ml-1'
            onClick={() =>
              setModalData({
                title: 'Recusar proposta?',
                message: 'Esta ação não pode ser revertida.',
                onCancel: () => setModalData(undefined),
                onConfirm: () => {
                  setModalData({
                    ...modalData,
                    loading: rejecting,
                    title: 'Rejeitando proposta'
                  })

                  reject({ variables: { offerId: row.id } })
                }
              })
            }
          >
            Recusar
          </Button>

          <WarningModal modalData={modalData} />
        </React.Fragment>
      </ShouldRender>

      <ShouldRender if={!hasActions}>Nenhuma ação disponível</ShouldRender>
    </React.Fragment>
  )
}

export default ActionsFormatter
