import { isValid, format } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'

const createJSCoreDate = (dateString: string) => {
  // https://github.com/facebook/react-native/issues/15819#issuecomment-369976505
  // dateString *HAS* to be in this format "YYYY-MM-DD H:mm:SS"
  let dateParam = dateString.split(/[\s-:]/)
  dateParam[1] = (parseInt(dateParam[1], 10) - 1).toString()
  //@ts-ignore
  return new Date(...dateParam)
}

const handleDate = (date: string) => {
  const output = createJSCoreDate(date.split(' UTC')[0])

  return isValid(output)
    ? // ? `${format(zonedTimeToUtc(output, 'UTC'), 'dd/MM/yyyy H:mm')}`
      `${format(output, 'dd/MM/yyyy H:mm')}`
    : '...'
}

export default handleDate
