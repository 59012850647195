import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const query = gql`
  query FreightPaymentMethods {
    methods: __type(name: "FreightPaymentMethod") {
      name
      values: enumValues {
        name
      }
    }
  }
`

export default () => useQuery(query, { fetchPolicy: 'no-cache' })
