import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const mutation = gql`
  mutation UpdateProfile(
    $id: ID!
    $email: String!
    $mobilePhone: String
    $name: String!
    $companyId: ID
  ) {
    updatedProfile: updateProfile(
      id: $id
      email: $email
      mobilePhone: $mobilePhone
      name: $name
      companyId: $companyId
    ) {
      id
    }
  }
`

export default () => {
  const [updateProfile, result] = useMutation(mutation, {
    onError: () => null,
    refetchQueries: ['Profiles']
  })

  return [updateProfile, result]
}
