import idx from 'idx'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const mutation = gql`
  mutation FirebaseLogin($token: String!, $uid: String!) {
    firebaseLogin(token: $token, uid: $uid) {
      id
      token
    }
  }
`

export default () => {
  const [firebaseLogin, result] = useMutation(mutation, {
    onError: () => null,
    update(cache, { data }) {
      return idx(data, (_) => _.firebaseLogin.token)
        ? cache.writeData({ data: { token: data.firebaseLogin.token } })
        : null
    }
  })

  return [firebaseLogin, result]
}
