import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const mutation = gql`
  mutation UpdateVehicle(
    $id: ID!
    $document: String
    $licensePlate: String
    $chassis: String
    $renavam: String
    $year: Int
    $anttRntrc: String
    $anttDueDate: ISODate
    $capacityM3: Int
    $capacityKg: Int
    $tare: Int
    $axis: Int
    $nationality: NationalityKind
    $vehicleFloor: VehicleFloorKind
    $vehicleCategory: ID
    $vehicleBrand: ID
    $vehicleModel: ID
    $vehicleColor: ID
    $vehicleTracker: ID
    $vehicleBodywork: ID
    $city: ID
  ) {
    updated: updateVehicle(
      id: $id
      document: $document
      licensePlate: $licensePlate
      chassis: $chassis
      renavam: $renavam
      year: $year
      anttRntrc: $anttRntrc
      anttDueDate: $anttDueDate
      capacityM3: $capacityM3
      capacityKg: $capacityKg
      tare: $tare
      axis: $axis
      nationality: $nationality
      vehicleFloor: $vehicleFloor
      vehicleCategoryId: $vehicleCategory
      vehicleBrandId: $vehicleBrand
      vehicleModelId: $vehicleModel
      vehicleColorId: $vehicleColor
      vehicleTrackerId: $vehicleTracker
      vehicleBodyworkId: $vehicleBodywork
      cityId: $city
    ) {
      id
    }
  }
`

export default () => {
  const [updateVehicle, result] = useMutation(mutation, {
    onError: () => null,
    refetchQueries: ['CompactVehicles']
  })

  return [updateVehicle, result]
}
