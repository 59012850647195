import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const query = gql`
  query Dashboard {
    backOfficeDashboard
  }
`

export default () => {
  return useQuery(query, { fetchPolicy: 'no-cache' })
}
