import React, { Fragment } from 'react'
import { creditCard, cvv } from 'utils/mask'
import { getFormikError } from 'services'
import { Row, Col, Button } from 'reactstrap'
import { usePlans } from 'queries'
import { Plans, Select, ShouldRender, TextInput } from 'components'

const Form: React.FC<PaymentType.Props> = ({ formik, loading }) => {
  const { data: plans } = usePlans()

  const {
    values,
    getFieldMeta,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
    handleSubmit
  } = formik

  const monthsOptions = () => {
    return Array.from({ length: 12 }, (_, index: number) => ({
      label: (index + 1).toString(),
      value: (index + 1).toString()
    }))
  }
  const yearsOptions = () => {
    const currentYear = new Date().getFullYear()

    return Array.from({ length: 10 }, (_, index: number) => ({
      label: (currentYear + index).toString(),
      value: (currentYear + index).toString()
    }))
  }

  return (
    <React.Fragment>
      <Plans
        options={plans?.list || []}
        selectedId={values['planId']}
        onChange={(id: string) => {
          setFieldValue('planId', id)
        }}
      />

      <ShouldRender if={values['planId']}>
        <Fragment>
          <Row>
            <TextInput
              size={{ sm: 6 }}
              label='Nome no cartão'
              disabled={loading}
              {...getFieldProps('holderName', 'text')}
              error={getFormikError(getFieldMeta, 'holderName')}
            />

            <TextInput
              size={{ sm: 6 }}
              label='Número do cartão'
              disabled={loading}
              {...getFieldProps('cardNumber', 'text')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('cardNumber', creditCard(e.target.value))
              }}
              error={getFormikError(getFieldMeta, 'cardNumber')}
            />

            <Select
              size={{ xs: 6, sm: 4 }}
              label='Mês de validade'
              name='expirationMonth'
              disabled={loading}
              options={monthsOptions()}
              value={values['expirationMonth']}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              error={getFormikError(getFieldMeta, 'city')}
            />

            <Select
              size={{ xs: 6, sm: 4 }}
              label='Ano de validade'
              name='expirationYear'
              disabled={loading}
              options={yearsOptions()}
              value={values['expirationYear']}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              error={getFormikError(getFieldMeta, 'city')}
            />

            <TextInput
              size={{ xs: 6, sm: 4 }}
              label='CVV'
              disabled={loading}
              {...getFieldProps('securityCode', 'text')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('securityCode', cvv(e.target.value))
              }}
              error={getFormikError(getFieldMeta, 'securityCode')}
            />
          </Row>
          <Row className='flex-row-reverse mb-4'>
            <Col sm={3} xl={2}>
              <Button
                block
                color='success'
                disabled={loading}
                onClick={handleSubmit}
              >
                {loading ? 'Finalizando compra' : 'Pagar'}
              </Button>
            </Col>
          </Row>
        </Fragment>
      </ShouldRender>
    </React.Fragment>
  )
}

export default Form
