import React, { useEffect, useState } from 'react'
import { useDashboard } from 'queries'
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Progress,
  Row
} from 'reactstrap'
import camelcaseKeys from 'camelcase-keys'

const Dashboard: React.FC = () => {
  const { data }: any = useDashboard()
  const [totalFreights, setTotalFreights] = useState(0)
  const [totalProfiles, setTotalProfiles] = useState(0)
  const [totalVehicles, setTotalVehicles] = useState(0)
  const [freights, setFreights] = useState<DashboardType.Freight[]>([])
  const [profiles, setProfiles] = useState<DashboardType.Profile[]>([])
  const [vehicles, setVehicles] = useState<DashboardType.Vehicles[]>([])

  useEffect(() => {
    let totalFreights = 0,
      totalProfiles = 0,
      totalVehicles = 0

    if (data && data.backOfficeDashboard) {
      const camelizedData: DashboardType.Dashboard | any = camelcaseKeys(
        data.backOfficeDashboard,
        {
          deep: true
        }
      )

      camelizedData.freightsByStatus &&
        camelizedData.freightsByStatus.map((freight: DashboardType.Freight) => {
          totalFreights += freight.count
        })

      camelizedData.profilesByType &&
        camelizedData.profilesByType.map((profile: DashboardType.Profile) => {
          totalProfiles += profile.count
        })

      camelizedData.vehiclesByType &&
        camelizedData.vehiclesByType.map((vehicle: DashboardType.Vehicles) => {
          totalVehicles += vehicle.count
        })

      setFreights(camelizedData.freightsByStatus)
      setProfiles(camelizedData.profilesByType)
      setVehicles(camelizedData.vehiclesByType)
      setTotalFreights(totalFreights)
      setTotalProfiles(totalProfiles)
      setTotalVehicles(totalVehicles)
    }
  }, [data])

  // CALLOUT -------------------------------------------------------------------

  const calloutItems: DashboardType.Callout[] = [
    { colorStyle: 'danger', name: 'Veículos', value: totalVehicles },
    { colorStyle: 'warning', name: 'Usuários', value: totalProfiles },
    { colorStyle: 'success', name: 'Fretes', value: totalFreights }
  ]

  const topCallout = (calloutData: DashboardType.Callout) => {
    return (
      <Col md='3'>
        <div className={`callout callout-${calloutData.colorStyle}`}>
          <small className='text-muted'>{calloutData.name}</small>
          <br />
          <strong className='h4'>{calloutData.value}</strong>
        </div>
      </Col>
    )
  }

  const renderCalloutItems = () => {
    return calloutItems.map((calloutItem) => topCallout(calloutItem))
  }

  // PROGRESS ------------------------------------------------------------------

  const progress = (progressData: DashboardType.Progress) => {
    return (
      <div className='progress-group'>
        <div className='progress-group-header'>
          <span className='title'>{progressData.name}</span>
          <span className='ml-auto font-weight-bold'>
            {progressData.value}{' '}
            <span className='text-muted small'>
              ({progressData.percentage === 'NaN' ? 0 : progressData.percentage}
              %)
            </span>
          </span>
        </div>
        <div className='progress-group-bars'>
          <Progress
            className='progress-xs'
            color={progressData.colorStyle}
            value={progressData.percentage}
          />
        </div>
      </div>
    )
  }

  const percentage = (value: number, total: number) => {
    return ((value * 100) / total).toFixed(1)
  }

  const renderVehicles = () => {
    return (
      vehicles &&
      vehicles.map((vehicle) =>
        progress({
          colorStyle: 'danger',
          name: vehicle.type,
          value: vehicle.count,
          percentage: percentage(vehicle.count, totalVehicles)
        })
      )
    )
  }

  const handleProfileName = (name: string) => {
    const options = [
      { label: 'Administrador', value: 'admin' },
      { label: 'Negociador', value: 'negotiator' },
      { label: 'Motorista', value: 'driver' }
    ]

    const index = options.findIndex((x: Record<string, any>) => {
      return x.value === name
    })

    return index > -1 ? options[index].label : '...'
  }

  const renderProfiles = () => {
    return (
      profiles &&
      profiles.map((profile) =>
        progress({
          colorStyle: 'warning',
          name: handleProfileName(profile.role),
          value: profile.count,
          percentage: percentage(profile.count, totalProfiles)
        })
      )
    )
  }

  const handleFreightStatus = (status: string) => {
    const options = [
      { label: 'Não publicado', value: 'created' },
      { label: 'Publicado', value: 'published' },
      { label: 'Negociado', value: 'negotiated' },
      { label: 'Em execução', value: 'ongoing' },
      { label: 'Cancelado', value: 'cancelled' },
      { label: 'Finalizado', value: 'finished' },
      { label: 'Expirado', value: 'expired' }
    ]

    const index = options.findIndex((x: Record<string, any>) => {
      return x.value === status
    })

    return index > -1 ? options[index].label : '...'
  }

  const renderFreights = () => {
    return (
      freights &&
      freights.map((freight) =>
        progress({
          colorStyle: 'success',
          name: handleFreightStatus(freight.status),
          value: freight.count,
          percentage: percentage(freight.count, totalFreights)
        })
      )
    )
  }

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>Dashboard</CardHeader>
          <CardBody>
            <Row>{renderCalloutItems()}</Row>
            <hr className='mt-0' />
            <Row>
              <Col xs='12' md='6' xl='6'>
                <h5>Veículos:</h5>
                {renderVehicles()}
              </Col>
              <Col xs='12' md='6' xl='6'>
                <h5>Usuários:</h5>
                {renderProfiles()}
                <h5 className='mt-4'>Fretes:</h5>
                {renderFreights()}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default Dashboard
