const cep: Mask.Method = (input) => {
  return typeof input === 'string'
    ? input
        .replace(/\D/g, '')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{3})\d+?$/, '$1')
    : input
}

const cnpj: Mask.Method = (input) => {
  return typeof input === 'string'
    ? input
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
    : input
}

const cpf: Mask.Method = (input) => {
  return typeof input === 'string'
    ? input
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
    : input
}

const number: Mask.Method = (input) => {
  return typeof input === 'string' ? input.replace(/\D/g, '') : input
}

const phone: Mask.Method = (input) => {
  return typeof input === 'string'
    ? input
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{4})(\d)/, '$1 $2')
        .replace(/(\d{4}) (\d)(\d{4})/, '$1$2 $3')
        .replace(/( \d{4})\d+?$/, '$1')
    : input
}

const cellphone: Mask.Method = (input) => {
  return typeof input === 'string'
    ? input
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/( \d{9})\d+?$/, '$1')
    : input
}

const money: Mask.Method = (input) => {
  let output = (Number(remove(input)) / 100)
    .toFixed(2)
    .replace('.', ',')
    .replace(/\d{3}(?=(\d{3})*,)/g, (s: string) => `.${s}`)
  output.substring(0, 1) === '.' && (output = output.substring(1))

  return 'R$ ' + output
}

const remove: Mask.Method = (input) => {
  return typeof input === 'string' ? input.replace(/\D/g, '') : input
}

const creditCard: Mask.Method = (input) => {
  return typeof input === 'string'
    ? input
        .replace(/\D/g, '')
        .replace(/(\d{4})(\d)/, '$1 $2')
        .replace(/(\d{4})(\d)/, '$1 $2')
        .replace(/(\d{4})(\d)/, '$1 $2')
        .replace(/( \d{4})\d+?$/, '$1')
    : input
}

const cvv: Mask.Method = (input) => {
  return typeof input === 'string'
    ? input.replace(/\D/g, '').replace(/(\d{3})(\d)/, '$1')
    : input
}

const rntrc: Mask.Method = (input) => {
  return typeof input === 'string'
    ? input.replace(/\D/g, '').replace(/(\d{8})(\d)/, '$1')
    : input
}

const authCode: Mask.Method = (input) => {
  return typeof input === 'string'
    ? input.replace(/\D/g, '').replace(/(\d{6})(\d)/, '$1')
    : input
}

const licensePlate: Mask.Method = (input) => {
  return typeof input === 'string'
    ? input.replace(/\s/g, '').replace(/([\w\d]{7})[\w\d]+?$/, '$1')
    : input
}

const year: Mask.Method = (input) => {
  return typeof input === 'string'
    ? input.replace(/\D/g, '').replace(/(\d{4})(\d)/, '$1')
    : input
}

export {
  authCode,
  cep,
  cnpj,
  cpf,
  number,
  phone,
  money,
  remove,
  creditCard,
  cvv,
  licensePlate,
  year,
  cellphone,
  rntrc
}
