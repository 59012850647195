import gql from 'graphql-tag'

export default gql`
  query Cities($name: String, $id: ID) {
    cities(filter: { name: $name }, id: $id) {
      value: id
      label: name
      state {
        country {
          name
          id
        }
        id
        initials
      }
    }
  }
`
