import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const query = gql`
  query getLocalToken {
    token @client
  }
`

export default () => useQuery(query)
