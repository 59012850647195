import * as y from 'yup'
import { useFormik } from 'formik'
import { cpf } from 'utils/validation'

const useProfile: FormTypes.Hook = ({ onSubmit, initialValues }) => {
  const formik = useFormik({
    onSubmit,
    initialValues,
    enableReinitialize: true,
    validationSchema: y.object().shape({
      name: y.string().required('Obrigatório'),
      document: y
        .string()
        .test('valid-cpf', 'CPF Inválido', function(value) {
          return value ? cpf(value) : true
        })
        .required('Obrigatório'),
      email: y
        .string()
        .email('E-mail inválido')
        .required('Obrigatório'),
      mobilePhone: y
        .string()
        .min(14, 'Celular inválido')
        .required('Obrigatório'),
      roles: y.array().required('Selecione pelo menos um papel')
    })
  })

  return formik
}

export default useProfile
