import { useDriverForm } from 'forms'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ShouldRender, Spinner, ImageGallery } from 'components'
import { Card, CardHeader, CardBody, Col, Row, Button } from 'reactstrap'
import { useDriverProfile, useProfileDocuments } from 'queries'
import { useUpdateDriverProfile, useUpdateDocumentStatus } from 'mutations'
import DriverForm from '../FreightDetails/FreightScreens/FreightTrip/Forms/DriverForm'
import format from 'date-fns/format'
import { cep, cpf, phone, remove } from 'utils/mask'
import { notify } from 'services'

const Driver: React.FC = () => {
  const { id } = useParams()
  const { data: driver, loading } = useDriverProfile({ variables: { id } })
  const { data: images } = useProfileDocuments({ variables: { profileId: id } })
  const [
    update,
    { data: profile, loading: updating }
  ]: any = useUpdateDriverProfile()

  const [
    updateDocumentStatus,
    { data: updated, loading: updatingDocument, error: updateError }
  ]: any = useUpdateDocumentStatus()

  useEffect(() => {
    if (profile?.updated?.id) {
      notify({
        type: 'success',
        description: 'Motorista atualizado'
      })
    }
  }, [profile])

  useEffect(() => {
    if (updated?.document?.id) {
      notify({
        type: 'success',
        description: 'Status do documento atualizado'
      })
    }
  }, [updated, updateError])

  const handleEmployeeTimeKind = () => {
    const options = [
      { label: 'Ano(s)', value: 'year' },
      { label: 'Mês(es)', value: 'month' },
      { label: 'Viagem(ns)', value: 'shipment' }
    ]

    const kind = driver?.profile[0]?.employeeTimeKind?.trim() || ''

    const index = options.findIndex((x: Record<string, any>) => {
      return x.value === kind
    })

    return index > -1 ? options[index] : { label: '', value: '' }
  }

  const handleNationality = () => {
    const nationality = driver?.profile[0]?.nationality || ''

    return nationality
      ? nationality === 'national'
        ? { label: 'Brasileiro(a)', value: 'national' }
        : { label: 'Estrangeiro(a)', value: 'foreign' }
      : ''
  }

  const handlePersonalContactKind = () => {
    const kind = driver?.profile[0]?.personalContactKind || ''

    const options = [
      { label: 'Esposo(a)', value: 'partner' },
      { label: 'Tio(a)', value: 'uncle' },
      { label: 'Filho(a)', value: 'son' },
      { label: 'Sobrinho(a)', value: 'nephew' },
      { label: 'Vizinho(a)', value: 'neighbor' },
      { label: 'Cunhado(a)', value: 'brother_in_law' },
      { label: 'Amigo(a)', value: 'friend' },
      { label: 'Sogro(a)', value: 'parent_in_law' },
      { label: 'Irmã(o)', value: 'brother' },
      { label: 'Avô(ó)', value: 'grandparent' },
      { label: 'Genro', value: 'son_in_law' },
      { label: 'Nora', value: 'daughter_in_law' },
      { label: 'Pai', value: 'father' },
      { label: 'Mãe', value: 'mother' },
      { label: 'Outro', value: 'others' }
    ]

    const index = options.findIndex((x: Record<string, any>) => {
      return x.value === kind
    })

    return index > -1 ? options[index] : { label: '', value: '' }
  }

  const handleProfessionalContactKind = () => {
    const kind = driver?.profile[0]?.professionalContactKind || ''

    const options = [
      { label: 'Administração', value: 'administration' },
      { label: 'Portaria', value: 'lobby' },
      { label: 'Funcionário(a)', value: 'operator' },
      { label: 'Proprietário(a)', value: 'owner' },
      { label: 'Outro', value: 'others' }
    ]

    const index = options.findIndex((x: Record<string, any>) => {
      return x.value === kind
    })

    return index > -1 ? options[index] : { label: '', value: '' }
  }

  const handleImageStatus = (status: string) => {
    const options = [
      { input: 'pending', output: 'Processando' },
      { input: 'auto_rejected', output: 'Rejeitado pelo sistema' },
      { input: 'auto_approved', output: 'Aprovado pelo sistema' },
      { input: 'manual_approved', output: 'Aprovado manualmente' },
      { input: 'manual_rejected', output: 'Rejeitado manualmente' },
      { input: 'missing_data', output: 'Aguardando interação manual' }
    ]

    const index = options.findIndex((x: Record<string, string>) => {
      return x.input === status
    })

    return options[index > -1 ? index : 0].output
  }

  const formik = useDriverForm({
    onSubmit: (values) => {
      console.log(values)
      update({
        variables: {
          id,
          ...values,
          birthDate: format(new Date(values?.birthDate.replace(/-/g, '\/')), 'yyyy-MM-dd'),
          city: values?.city?.value,
          document: remove(values?.document),
          driverLicenseDueDate: format(
            new Date(values?.driverLicenseDueDate.replace(/-/g, '\/')),
            'yyyy-MM-dd'
          ),
          driverLicenseFirstDate: format(
            new Date(values?.driverLicenseFirstDate.replace(/-/g, '\/')),
            'yyyy-MM-dd'
          ),
          driverLicenseType: values?.driverLicenseType?.value,
          employeeTime: Number(values?.employeeTime),
          employeeTimeKind: values?.employeeTimeKind?.value,
          nationality: values?.nationality?.value,
          personalContactKind: values?.personalContactKind?.value,
          professionalContactKind: values?.professionalContactKind?.value,
          driverLicenseIssuingState: values?.driverLicenseIssuingState?.value
        }
      })
    },
    initialValues: {
      name: driver?.profile[0]?.name || '',
      identityCard: driver?.profile[0]?.identityCard || '',
      issuingState: driver?.profile[0]?.issuingState || '',
      address: driver?.profile[0]?.address || '',
      complement: driver?.profile[0]?.complement || '',
      district: driver?.profile[0]?.district || '',
      document: cpf(remove(driver?.profile[0]?.document || '')),
      driverLicense: driver?.profile[0]?.driverLicense || '',
      driverLicenseDueDate: driver?.profile[0]?.driverLicenseDueDate
        ? format(
            new Date(driver?.profile[0]?.driverLicenseDueDate.replace(/-/g, '\/')),
            'yyyy-MM-dd'
          )
        : '',
      driverLicenseFirstDate: driver?.profile[0]?.driverLicenseFirstDate
        ? format(
            new Date(driver?.profile[0]?.driverLicenseFirstDate.replace(/-/g, '\/')),
            'yyyy-MM-dd'
          )
        : '',
      driverLicenseType: {
        label: driver?.profile[0]?.driverLicenseType?.description || '',
        value: driver?.profile[0]?.driverLicenseType?.id || ''
      },
      email: driver?.profile[0]?.email || '',
      employeeTime: driver?.profile[0]?.employeeTime || '',
      employeeTimeKind: handleEmployeeTimeKind(),
      mobilePhone: phone(remove(driver?.profile[0]?.mobilePhone || '')),
      nationality: handleNationality(),
      number: driver?.profile[0]?.number || '',
      rating: driver?.profile[0]?.rating || '',
      zipCode: cep(remove(driver?.profile[0]?.zipCode || '')),
      city: {
        value: driver?.profile[0]?.city?.id || '',
        label: `${driver?.profile[0]?.city?.name || ''} ${driver?.profile[0]
          ?.city?.state?.initials || ''}`
      },
      birthDate: driver?.profile[0]?.birthDate
        ? format(new Date(driver?.profile[0]?.birthDate.replace(/-/g, '\/')), 'yyyy-MM-dd')
        : '',
      driverLicenseIssuingState: {
        label: driver?.profile[0]?.driverLicenseIssuingState || '',
        value: driver?.profile[0]?.driverLicenseIssuingState || ''
      },
      driverLicenseRenach: driver?.profile[0]?.driverLicenseRenach || '',
      fatherName: driver?.profile[0]?.fatherName || '',
      motherName: driver?.profile[0]?.motherName || '',
      personalContactKind: handlePersonalContactKind(),
      personalContactName: driver?.profile[0]?.personalContactName || '',
      personalContactPhone: phone(
        remove(driver?.profile[0]?.personalContactPhone || '')
      ),
      professionalContactCompany:
        driver?.profile[0]?.professionalContactCompany || '',
      professionalContactKind: handleProfessionalContactKind(),
      professionalContactName:
        driver?.profile[0]?.professionalContactName || '',
      professionalContactPhone: phone(
        remove(driver?.profile[0]?.professionalContactPhone || '')
      )
    }
  })

  return (
    <Card>
      <CardHeader>Editar Motorista</CardHeader>
      <CardBody>
        <ShouldRender if={loading}>
          <Spinner />
        </ShouldRender>

        <ShouldRender if={!loading}>
          <React.Fragment>
            <legend>Documentos enviados</legend>
            <ShouldRender if={images?.list?.length > 0}>
              <React.Fragment>
                <ImageGallery
                  images={
                    images?.list?.map(
                      (image: Record<string, any>) => image.url
                    ) || []
                  }
                />
                <Row className='mb-4'>
                  {images?.list?.map((image: Record<string, any>) => {
                    return (
                      <Col md='3' className='text-center' key={image?.id}>
                        <small className='font-italic'>
                          {image?.kind === 'rg_back'
                            ? 'RG (Verso)'
                            : image?.kind === 'rg'
                            ? 'RG (Frente)'
                            : image?.kind?.toUpperCase() || ''}
                        </small>
                        <p className='font-weight-bold'>
                          {handleImageStatus(image?.status)}
                        </p>

                        <Button
                          block
                          color='success'
                          onClick={() =>
                            updateDocumentStatus({
                              variables: {
                                id: image?.id,
                                status: 'manual_approved'
                              }
                            })
                          }
                          disabled={
                            updatingDocument ||
                            ['auto_approved', 'manual_approved'].includes(
                              image?.status
                            )
                          }
                        >
                          {updatingDocument ? 'Atualizando' : 'Aprovar'}
                        </Button>

                        <Button
                          block
                          color='danger'
                          onClick={() =>
                            updateDocumentStatus({
                              variables: {
                                id: image?.id,
                                status: 'manual_rejected'
                              }
                            })
                          }
                          disabled={
                            updatingDocument ||
                            ['auto_rejected', 'manual_rejected'].includes(
                              image?.status
                            )
                          }
                        >
                          {updatingDocument ? 'Atualizando' : 'Rejeitar'}
                        </Button>
                      </Col>
                    )
                  })}
                </Row>
              </React.Fragment>
            </ShouldRender>

            <ShouldRender if={!images?.list?.length}>
              <p>Nenhum documento enviado</p>
            </ShouldRender>

            <DriverForm formik={formik} editable loading={updating} />
          </React.Fragment>
        </ShouldRender>
      </CardBody>
    </Card>
  )
}

export default Driver
