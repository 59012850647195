import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const mutation = gql`
  mutation UpdateTecnoriskConfig(
    $id: ID!
    $tecnorisk: TecnoriskConfigAttributes
  ) {
    config: updateCompany(id: $id, tecnorisk: $tecnorisk) {
      id
    }
  }
`

export default () => {
  const [updateCompany, result] = useMutation(mutation, { onError: () => null })

  return [updateCompany, result]
}
