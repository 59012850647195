import { cep } from 'utils/mask'
import { useViaCep } from 'hooks'
import { useWaypointForm } from 'forms'
import React, { useEffect } from 'react'
import { getFormikError } from 'services'
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter
} from 'reactstrap'
import { useLazyCities } from 'queries'
import { useLazyQuery } from '@apollo/react-hooks'
import { TextInput, ShouldRender, Select } from 'components'

const operationTypes = [
  { label: 'Coleta', value: 'cargo_collect' },
  { label: 'Entrega', value: 'cargo_delivery' }
]

const Waypoint: React.FC<FreightType.WaypointProps> = ({
  edit,
  editing,
  cancel,
  remove,
  disabled,
  submit: onSubmit,
  initialValues: parentInitialValues,
  published
}) => {
  const initialValues: FormTypes.FreightWaypoint = {
    address: '',
    city: { label: '', value: '' },
    complement: '',
    district: '',
    kind: { label: '', value: '' },
    number: '',
    zipCode: '',
    expectedAt: '',
    ...parentInitialValues
  }

  const formik = useWaypointForm({ onSubmit, initialValues })

  const {
    values,
    getFieldProps,
    getFieldMeta,
    setFieldValue,
    setFieldTouched,
    handleSubmit
  } = formik

  const [
    getCities,
    { data: list, loading: loadingCities }
  ] = useLazyQuery(useLazyCities, { fetchPolicy: 'no-cache' })

  const { loading: loadingAddress, result: viaCepAddress } = useViaCep(
    values.zipCode
  )

  useEffect(() => {
    if (viaCepAddress) {
      !values['address'] && setFieldValue('address', viaCepAddress?.address)
      !values['district'] && setFieldValue('district', viaCepAddress?.district)
    }
  }, [viaCepAddress])

  const searchCities = async (inputValue: string, callback: any) => {
    if (!inputValue || inputValue.trim().length < 3) {
      return callback([])
    }

    if (inputValue.trim().length >= 3) {
      getCities({ variables: { name: inputValue } })

      if (list) {
        const options = list?.cities?.map((city: Record<string, any>) => ({
          label: `${city.label}, ${city.state?.initials}`,
          value: city.value
        }))

        callback(options)
      }
    }
  }

  return (
    <Col xs='12'>
      <Card>
        <CardHeader>Ponto de coleta/entrega</CardHeader>
        <CardBody>
          <Row>
            <Select
              size={{ xs: 12, sm: 6, md: 3 }}
              label='Tipo de operação'
              name='kind'
              disabled={disabled}
              value={values['kind']}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              error={getFormikError(getFieldMeta, 'kind')}
              options={operationTypes}
            />
            <TextInput
              size={{ xs: 12, sm: 6, md: 4 }}
              type='datetime-local'
              disabled={disabled}
              label='Data e hora'
              {...getFieldProps('expectedAt', 'text')}
              error={getFormikError(getFieldMeta, 'expectedAt')}
            />
          </Row>
          <Row>
            <TextInput
              label='CEP'
              disabled={disabled || loadingAddress}
              size={{ xs: 12, sm: 6, md: 3 }}
              {...getFieldProps('zipCode', 'text')}
              error={getFormikError(getFieldMeta, 'zipCode')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('zipCode', cep(e.target.value))
              }}
            />

            <TextInput
              label='Endereço'
              disabled={disabled || loadingAddress}
              size={{ xs: 12, sm: 6 }}
              {...getFieldProps('address', 'text')}
              error={getFormikError(getFieldMeta, 'address')}
            />

            <TextInput
              label='Número'
              disabled={disabled}
              size={{ xs: 12, sm: 6, md: 3 }}
              {...getFieldProps('number', 'text')}
              error={getFormikError(getFieldMeta, 'number')}
            />

            <TextInput
              label='Complemento'
              disabled={disabled}
              size={{ xs: 12, sm: 6, md: 4 }}
              {...getFieldProps('complement', 'text')}
              error={getFormikError(getFieldMeta, 'complement')}
            />

            <TextInput
              label='Bairro'
              disabled={disabled || loadingAddress}
              size={{ xs: 12, sm: 6, md: 4 }}
              {...getFieldProps('district', 'text')}
              error={getFormikError(getFieldMeta, 'district')}
            />

            <Select
              size={{ xs: 12, sm: 6, md: 4 }}
              label='Cidade'
              name='city'
              loading={loadingCities}
              disabled={disabled}
              value={values['city']}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              error={getFormikError(getFieldMeta, 'city')}
              loadOptions={searchCities}
            />
          </Row>
        </CardBody>
        <CardFooter>
          <Row className='flex-row-reverse'>
            <ShouldRender if={!values.id}>
              <Col sm={3} xl={2}>
                <Button block color='danger' onClick={cancel}>
                  Cancelar
                </Button>
              </Col>
            </ShouldRender>
            <ShouldRender if={!published}>
              <Col sm={3} xl={2}>
                <Button
                  block
                  color='success'
                  onClick={
                    values.id ? (editing ? handleSubmit : edit) : handleSubmit
                  }
                >
                  {values.id ? (editing ? 'Salvar' : 'Editar') : 'Adicionar'}
                </Button>
              </Col>
            </ShouldRender>
            <ShouldRender if={values.id && !published}>
              <Col sm={3} xl={2}>
                <Button block color='danger' onClick={disabled ? remove : edit}>
                  {disabled ? 'Excluir' : 'Cancelar'}
                </Button>
              </Col>
            </ShouldRender>
          </Row>
        </CardFooter>
      </Card>
    </Col>
  )
}

export default Waypoint
