import Form from './Form'
import { usePaymentForm } from 'forms'
import { useCompany } from 'queries'
import { useAddCredit } from 'mutations'
import { remove } from 'utils/mask'
import React, { Fragment, useEffect } from 'react'
import { ShouldRender, Spinner } from 'components'
import { Card, CardHeader, CardBody, Col, Row } from 'reactstrap'
import './style.css'
import { notify } from 'services'

const Payment: React.FC = () => {
  const { data: company, loading } = useCompany()
  const [
    addCredit,
    { data: credit, loading: addingCredit }
  ]: any = useAddCredit()

  const initialValues: FormTypes.Payment = {
    planId: '',
    holderName: '',
    expirationMonth: '',
    expirationYear: '',
    cardNumber: '',
    securityCode: ''
  }

  useEffect(() => {
    if (credit?.added?.id) {
      notify({
        type: 'success',
        description: 'Créditos adicionados.'
      })
    }
  }, [credit])

  const formik = usePaymentForm({
    onSubmit: (values) => {
      addCredit({
        variables: {
          ...values,
          cardNumber: remove(values.cardNumber),
          expirationMonth: Number(values.expirationMonth.value),
          expirationYear: Number(values.expirationYear.value),
          securityCode: Number(values.securityCode)
        }
      })
    },
    initialValues
  })

  return (
    <Row>
      <Col sm={4}>
        <Card className='text-white yellow-bg'>
          <CardBody>
            <i
              className='fa fa-usd float-right'
              aria-hidden='true'
              style={{ fontSize: '38px' }}
            ></i>
            <div className='credits-value'>
              {company?.company?.balance || '0'}
            </div>
            <div>Meus créditos</div>
          </CardBody>
        </Card>
      </Col>
      <Col sm={8}>
        <Card>
          <CardHeader>Comprar créditos</CardHeader>
          <CardBody>
            <ShouldRender if={loading}>
              <Spinner />
            </ShouldRender>

            <ShouldRender if={!loading}>
              <Fragment>
                <Row>
                  <Col>
                    <h3>Selecione um plano</h3>
                  </Col>
                </Row>

                <Form formik={formik} loading={addingCredit} />
              </Fragment>
            </ShouldRender>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default Payment
