import React from 'react'
import ShouldRender from '../ShouldRender'

const Notification: React.FC<NotificationType.Props> = ({
  title,
  description
}) => (
  <div>
    <ShouldRender if={!!title}>
      <h4>{title}</h4>
    </ShouldRender>
    <p>{description}</p>
  </div>
)

export default Notification
