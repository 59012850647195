import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const mutation = gql`
  mutation CreateOffer($freightId: ID!, $vehicleAssemblyId: ID!) {
    createdOffer: createOffer(
      freightId: $freightId
      vehicleAssemblyId: $vehicleAssemblyId
    ) {
      id
    }
  }
`

export default () => {
  const [createOffer, result] = useMutation(mutation, {
    onError: () => null,
    refetchQueries: ['Offers']
  })

  return [createOffer, result]
}
