import React, { Fragment, useState } from 'react'
import { useFormik } from 'formik'
import format from 'date-fns/format'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Row
} from 'reactstrap'
import { ImageGallery, ShouldRender, Spinner, Map } from 'components'
import { CompanyForm, DriverForm, VehicleForm } from './Forms'
import { useFreightOffer } from 'queries'
import './style.css'
import { cpf, phone, remove } from 'utils/mask'

const FreightTrip: React.FC<{ id: string }> = ({ id }) => {
  const [accordion, setAccordion] = useState([true, true, true])
  const { data, loading } = useFreightOffer({
    variables: { freightId: id }
  })

  const toggleAccordion = (tab: number) => {
    let newAccordionState = [...accordion]
    newAccordionState[tab] = !newAccordionState[tab]
    setAccordion(newAccordionState)
  }

  const accordionIcon = (tab: number) => {
    let icon
    icon = accordion[tab] ? 'up' : 'down'
    return <i className={`header-accordion-icon fa fa-chevron-${icon}`}></i>
  }

  const handleEmployeeTimeKind = () => {
    const options = [
      { label: 'Ano(s)', value: 'year' },
      { label: 'Mês(es)', value: 'month' },
      { label: 'Viagem(ns)', value: 'shipment' }
    ]

    const kind =
      data?.offers[0]?.vehicleAssembly?.driver?.employeeTimeKind || ''

    const index = options.findIndex((x: Record<string, any>) => {
      return x.value === kind
    })

    return index > -1 ? options[index] : { label: '', value: '' }
  }

  const handleNationality = () => {
    const nationality =
      data?.offers[0]?.vehicleAssembly?.driver?.nationality || ''

    return nationality
      ? nationality === 'national'
        ? { label: 'Brasileiro(a)', value: 'national' }
        : { label: 'Estrangeiro(a)', value: 'foreign' }
      : { label: '', value: '' }
  }

  const handlePersonalContactKind = () => {
    const kind =
      data?.offers[0]?.vehicleAssembly?.driver?.personalContactKind || ''

    const options = [
      { label: 'Esposo(a)', value: 'partner' },
      { label: 'Tio(a)', value: 'uncle' },
      { label: 'Filho(a)', value: 'son' },
      { label: 'Sobrinho(a)', value: 'nephew' },
      { label: 'Vizinho(a)', value: 'neighbor' },
      { label: 'Cunhado(a)', value: 'brother_in_law' },
      { label: 'Amigo(a)', value: 'friend' },
      { label: 'Sogro(a)', value: 'parent_in_law' },
      { label: 'Irmã(o)', value: 'brother' },
      { label: 'Avô(ó)', value: 'grandparent' },
      { label: 'Genro', value: 'son_in_law' },
      { label: 'Nora', value: 'daughter_in_law' },
      { label: 'Pai', value: 'father' },
      { label: 'Mãe', value: 'mother' },
      { label: 'Outro', value: 'others' }
    ]

    const index = options.findIndex((x: Record<string, any>) => {
      return x.value === kind
    })

    return index > -1 ? options[index] : { label: '', value: '' }
  }

  const handleProfessionalContactKind = () => {
    const kind =
      data?.offers[0]?.vehicleAssembly?.driver?.professionalContactKind || ''

    const options = [
      { label: 'Administração', value: 'administration' },
      { label: 'Portaria', value: 'lobby' },
      { label: 'Funcionário(a)', value: 'operator' },
      { label: 'Proprietário(a)', value: 'owner' },
      { label: 'Outro', value: 'others' }
    ]

    const index = options.findIndex((x: Record<string, any>) => {
      return x.value === kind
    })

    return index > -1 ? options[index] : { label: '', value: '' }
  }

  const companyFormFormik = useFormik({
    enableReinitialize: true,
    onSubmit: () => {},
    initialValues: {
      document: data?.offers[0]?.vehicleAssembly?.company?.document || '',
      phone: data?.offers[0]?.vehicleAssembly?.company?.phone || '',
      name: data?.offers[0]?.vehicleAssembly?.company?.name || '',
      fantasyName: data?.offers[0]?.vehicleAssembly?.company?.fantasyName || '',
      zipCode: data?.offers[0]?.vehicleAssembly?.company?.zipCode || '',
      address: data?.offers[0]?.vehicleAssembly?.company?.address || '',
      number: data?.offers[0]?.vehicleAssembly?.company?.number || '',
      complement: data?.offers[0]?.vehicleAssembly?.company?.complement || '',
      district: data?.offers[0]?.vehicleAssembly?.company?.district || '',
      city: `${data?.offers[0]?.vehicleAssembly?.company?.city?.name ||
        ''} ${data?.offers[0]?.vehicleAssembly?.company?.city?.state
        ?.initials || ''}`
    }
  })

  const driverFormFormik = useFormik({
    enableReinitialize: true,
    onSubmit: () => {},
    initialValues: {
      name: data?.offers[0]?.vehicleAssembly?.driver?.name || '',
      address: data?.offers[0]?.vehicleAssembly?.driver?.address || '',
      complement: data?.offers[0]?.vehicleAssembly?.driver?.complement || '',
      district: data?.offers[0]?.vehicleAssembly?.driver?.district || '',
      document: cpf(data?.offers[0]?.vehicleAssembly?.driver?.document || ''),
      driverLicense:
        data?.offers[0]?.vehicleAssembly?.driver?.driverLicense || '',
      driverLicenseDueDate: data?.offers[0]?.vehicleAssembly?.driver
        ?.driverLicenseDueDate
        ? format(
            new Date(
              data?.offers[0]?.vehicleAssembly?.driver?.driverLicenseDueDate.replace(/-/g, '\/')
            ),
            'y-MM-dd'
          )
        : '',
      driverLicenseFirstDate: data?.offers[0]?.vehicleAssembly?.driver
        ?.driverLicenseFirstDate
        ? format(
            new Date(
              data?.offers[0]?.vehicleAssembly?.driver?.driverLicenseFirstDate.replace(/-/g, '\/')
            ),
            'y-MM-dd'
          )
        : '',
      identityCard:
        data?.offers[0]?.vehicleAssembly?.driver?.identityCard || '',
      issuingState:
        data?.offers[0]?.vehicleAssembly?.driver?.issuingState || '',
      driverLicenseType:
        data?.offers[0]?.vehicleAssembly?.driver?.driverLicenseType || '',
      email: data?.offers[0]?.vehicleAssembly?.driver?.email || '',
      employeeTime:
        data?.offers[0]?.vehicleAssembly?.driver?.employeeTime || '',
      employeeTimeKind: handleEmployeeTimeKind(),
      mobilePhone: phone(
        remove(data?.offers[0]?.vehicleAssembly?.driver?.mobilePhone || '')
      ),
      nationality: handleNationality(),
      number: data?.offers[0]?.vehicleAssembly?.driver?.number || '',
      rating: data?.offers[0]?.vehicleAssembly?.driver?.rating || '',
      zipCode: data?.offers[0]?.vehicleAssembly?.driver?.zipCode || '',
      city: {
        value: data?.offers[0]?.vehicleAssembly?.driver?.city?.id || '',
        label: `${data?.offers[0]?.vehicleAssembly?.driver?.city?.name ||
          ''} ${data?.offers[0]?.vehicleAssembly?.driver?.city?.state
          ?.initials || ''}`
      },
      birthDate: data?.offers[0]?.vehicleAssembly?.driver?.birthDate
        ? format(
            new Date(data?.offers[0]?.vehicleAssembly?.driver?.birthDate.replace(/-/g, '\/')),
            'y-MM-dd'
          )
        : '',
      driverLicenseIssuingState: {
        label:
          data?.offers[0]?.vehicleAssembly?.driver?.driverLicenseIssuingState ||
          '',
        value:
          data?.offers[0]?.vehicleAssembly?.driver?.driverLicenseIssuingState ||
          ''
      },
      driverLicenseRenach:
        data?.offers[0]?.vehicleAssembly?.driver?.driverLicenseRenach || '',
      fatherName: data?.offers[0]?.vehicleAssembly?.driver?.fatherName || '',
      motherName: data?.offers[0]?.vehicleAssembly?.driver?.motherName || '',
      personalContactKind: handlePersonalContactKind(),
      personalContactName:
        data?.offers[0]?.vehicleAssembly?.driver?.personalContactName || '',
      personalContactPhone: phone(
        remove(
          data?.offers[0]?.vehicleAssembly?.driver?.personalContactPhone || ''
        )
      ),
      professionalContactCompany:
        data?.offers[0]?.vehicleAssembly?.driver?.professionalContactCompany ||
        '',
      professionalContactKind: handleProfessionalContactKind(),
      professionalContactName:
        data?.offers[0]?.vehicleAssembly?.driver?.professionalContactName || '',
      professionalContactPhone: phone(
        remove(
          data?.offers[0]?.vehicleAssembly?.driver?.professionalContactPhone ||
            ''
        )
      )
    }
  })

  const buildVehicleInfo = () => {
    const assembly = data?.offers[0]?.vehicleAssembly || undefined
    const dataKeys = assembly ? Object.keys(assembly) : []

    const output: Record<string, any>[] = []
    const documents: string[] = []

    const handleFloor = (floor: string) => {
      const options = [
        { value: 'not_applicable', label: 'Não aplicável' },
        { value: 'wood', label: 'Madeira' },
        { value: 'aluminum', label: 'Alumínio' },
        { value: 'iron', label: 'Ferro' }
      ]

      const index = options.findIndex((x: Record<string, any>) => {
        return x.value === floor
      })

      return index > -1 ? options[index].label : ''
    }

    dataKeys.forEach((key: string) => {
      if (key === 'truck' || /'implement'/g.test(key)) {
        documents.push(assembly[key]?.vehicleDocument?.url)

        output.push({
          vehicle: key,
          fields: {
            chassis: assembly[key]?.chassis || '',
            axis: assembly[key]?.axis || '',
            capacityKg: assembly[key]?.capacityKg || '',
            capacityM3: assembly[key]?.capacityM3 || '',
            document: assembly[key]?.document || '',
            licensePlate: assembly[key]?.licensePlate || '',
            nationality: assembly[key]?.nationality
              ? assembly[key].nationality === 'national'
                ? 'Brasileiro'
                : 'Estrangeiro'
              : '',
            renavam: assembly[key]?.renavam || '',
            tare: assembly[key]?.tare || '',
            vehicleFloor: handleFloor(assembly[key]?.vehicleFloor || ''),
            year: assembly[key]?.year || '',
            anttDueDate: assembly[key]?.anttDueDate || '',
            anttRntrc: assembly[key]?.anttRntrc || '',
            bodywork: assembly[key]?.bodywork?.label || '',
            brand: assembly[key]?.brand?.label || '',
            category: assembly[key]?.category?.label || '',
            color: assembly[key]?.color?.label || '',
            model: assembly[key]?.model?.label || '',
            tracker: assembly[key]?.tracker?.label || ''
          }
        })
      }
    })

    return (
      <Fragment>
        {output.map((item: Record<string, any>, index) => (
          <VehicleForm key={index} fields={item.fields} />
        ))}

        <ShouldRender if={documents.length > 0}>
          <ImageGallery images={documents} />
        </ShouldRender>
      </Fragment>
    )
  }

  const handleImages = (outputType: string) => {
    const avaries: string[] = []
    const deliveryProofs: string[] = []

    data?.offers[0]?.freight?.waypoints?.map(
      (waypoint: Record<string, any>) => {
        if (waypoint?.avariesUrls?.length) {
          waypoint?.avariesUrls?.map((avary: string) => {
            return avary !== 'new' ? avaries.push(avary) : null
          })
        }

        if (waypoint?.deliveryProofUrl) {
          deliveryProofs.push(waypoint.deliveryProofUrl)
        }
      }
    )

    return outputType === 'avaries' ? avaries : deliveryProofs
  }

  const generateMarkers = () => {
    return (
      data?.offers[0]?.histories
        ?.filter((history: Record<string, any>) => {
          return history.position
        })
        .map((history: Record<string, any>, index: number) => ({
          index,
          lat: history?.position[0],
          lng: history?.position[1],
          isFuture: true,
          createdAt: history?.createdAt
        })) || []
    )
  }

  const generateHistory = () => {
    return data?.offers[0]?.histories
      ?.filter(
        (history: Record<string, any>) =>
          ![
            'freight_rated_by_contractor',
            'freight_rated_by_provider'
          ].includes(history.action)
      )
      ?.map((history: Record<string, any>) => {
        return {
          message: history?.message || '',
          createdAt: format(new Date(history?.createdAt.replace(/-/g, '\/')), 'dd/MM/y H:mm')
        }
      })
  }

  return (
    <Fragment>
      <ShouldRender if={loading}>
        <Card>
          <CardHeader>Viagem</CardHeader>
          <CardBody>
            <Spinner />
          </CardBody>
        </Card>
      </ShouldRender>

      <ShouldRender if={!loading}>
        <Fragment>
          <Card>
            <CardHeader>Viagem</CardHeader>
            <CardBody>
              <ShouldRender if={data?.offers[0]?.status === 'accepted'}>
                <Card>
                  <CardHeader
                    className='header-accordion'
                    onClick={() => toggleAccordion(0)}
                  >
                    Check-ins
                    {accordionIcon(0)}
                  </CardHeader>
                  <Collapse isOpen={accordion[0]}>
                    <CardBody>
                      <Map markers={generateMarkers()} />
                    </CardBody>
                  </Collapse>
                </Card>
              </ShouldRender>

              <Card>
                <CardHeader
                  className='header-accordion'
                  onClick={() => toggleAccordion(1)}
                >
                  Histórico
                  {accordionIcon(1)}
                </CardHeader>
                <Collapse isOpen={accordion[1]}>
                  <CardBody>
                    <ul className='list-group list-group-flush'>
                      {generateHistory()?.map(
                        (item: Record<string, any>, index: number) => {
                          return (
                            <div
                              className='list-group-item'
                              key={index}
                              style={{ cursor: 'default' }}
                            >
                              <p className='mb-1'>{item.message}</p>
                              <small className='text-muted'>
                                {item.createdAt}
                              </small>
                            </div>
                          )
                        }
                      )}
                    </ul>
                  </CardBody>
                </Collapse>
              </Card>

              <Card>
                <CardHeader
                  className='header-accordion'
                  onClick={() => toggleAccordion(2)}
                >
                  Dados da transportadora
                  {accordionIcon(2)}
                </CardHeader>
                <Collapse isOpen={accordion[2]}>
                  <CardBody>
                    <CompanyForm formik={companyFormFormik} />
                  </CardBody>
                </Collapse>
              </Card>

              <Card>
                <CardHeader
                  className='header-accordion'
                  onClick={() => toggleAccordion(3)}
                >
                  Dados do motorista + CNH
                  {accordionIcon(3)}
                </CardHeader>
                <Collapse isOpen={accordion[3]}>
                  <CardBody>
                    <DriverForm formik={driverFormFormik} />
                    <ShouldRender
                      if={
                        data?.offers[0]?.freight?.responsible?.approvedDocuments
                          ?.length
                      }
                    >
                      <ImageGallery
                        images={data?.offers[0]?.freight?.responsible?.approvedDocuments.map(
                          (image: any) => image?.url
                        )}
                      />
                    </ShouldRender>
                  </CardBody>
                </Collapse>
              </Card>

              <Card>
                <CardHeader
                  className='header-accordion'
                  onClick={() => toggleAccordion(4)}
                >
                  Dados do veículo + CRLV
                  {accordionIcon(4)}
                </CardHeader>
                <Collapse isOpen={accordion[4]}>
                  <CardBody>{buildVehicleInfo()}</CardBody>
                </Collapse>
              </Card>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>Avarias</CardHeader>
            <CardBody>
              <ShouldRender if={handleImages('avaries').length > 0}>
                <ImageGallery images={handleImages('avaries')} />
              </ShouldRender>
              <ShouldRender if={handleImages('avaries').length == 0}>
                <Row>
                  <Col className='text-center light-text'>
                    Nenhuma imagem a ser exibida
                  </Col>
                </Row>
              </ShouldRender>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>Comprovantes de Entrega</CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <p>
                    De acordo com a legislação brasileira vigente, há dois
                    documentos que servem como comprovantes de entrega: a 2ª via
                    do conhecmento eletrônico de transportes e o canhoto da Nota
                    Fiscal. Ambos com a data do recebimento e assinatura do
                    recebedor da carga.
                  </p>
                </Col>
              </Row>

              <Row>
                <Col className='text-center'>
                  <ImageGallery images={handleImages('deliveryProofs')} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Fragment>
      </ShouldRender>
    </Fragment>
  )
}

export default FreightTrip
