import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const query = gql`
  query CurrentCompany {
    profile {
      company {
        id
        name
      }
    }
  }
`

export default () => useQuery(query, { fetchPolicy: 'network-only' })
