import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const query = gql`
  query VehicleBrands {
    list: vehicleBrands {
      value: id
      label: description
    }
  }
`
export default () => useQuery(query, { fetchPolicy: 'no-cache' })
