import gql from 'graphql-tag'

export default gql`
  query LazyDriversList($completedDriverData: Boolean) {
    list: profiles(
      filter: { driverRole: true, completedDriverData: $completedDriverData }
    ) {
      id
      name
      document
      company {
        name
      }
      completedDriverData
    }
  }
`
