import React from 'react'

const ShouldRender: React.FC<ShouldRenderType.Props> = ({
  if: condition,
  children
}) => {
  return <React.Fragment>{condition ? children : null}</React.Fragment>
}

export default ShouldRender
