import { notify } from '../services'
import { useState, useEffect } from 'react'
import useAsyncEffect from 'use-async-effect'
import { useHistory } from 'react-router-dom'
import {
  useUpdateRoles,
  useCreateProfile as useCreateRemoteProfile
} from 'mutations'

const useCreateProfile: CreateProfileType.Hook = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [fields, setFields] = useState<CreateProfileType.Props>({
    companyId: '',
    name: '',
    email: '',
    mobilePhone: '',
    document: '',
    roles: []
  })

  const [
    updateRoles,
    { data: roles, error: rolesError }
  ]: any = useUpdateRoles()
  const [
    createRemoteProfile,
    { data: profile, error: profileError }
  ]: any = useCreateRemoteProfile()

  const createProfile = (props: CreateProfileType.Props) => {
    setFields(props)
    setLoading(true)

    const variables = { ...props }
    delete variables['roles']

    return createRemoteProfile({ variables })
  }

  useAsyncEffect(() => {
    if (profile?.createdProfile?.id) {
      return updateRoles({
        variables: {
          id: profile?.createdProfile?.id,
          adminRole: fields.roles.indexOf('admin') > -1,
          negotiatorRole: fields.roles.indexOf('negotiator') > -1
        }
      })
    }
  }, [profile])

  useAsyncEffect(() => {
    if (roles?.updatedRoles?.id) {
      setLoading(false)

      notify({
        type: 'success',
        description: 'Usuário adicionado'
      })

      return history.push('/usuarios')
    }
  }, [roles])

  useEffect(() => {
    setLoading(false)
  }, [rolesError, profileError])

  return [createProfile, loading]
}

export default useCreateProfile
