import React from 'react'
import { Col, Row } from 'reactstrap'
import { money } from 'utils/mask'
import './style.css'

const Plans: React.FC<PlansType.Props> = ({
  options,
  selectedId,
  onChange
}) => {
  const showPlans = () => {
    return options.map((plan: PlansType.PlanType, index) => {
      return (
        <Col sm={6} md={4} xl={3} className='text-center' key={index}>
          <div
            className={`plan-container ${
              selectedId == plan.id ? 'active' : ''
            }`}
            onClick={() => onChange(plan.id)}
          >
            <h6 className='my-0'>{plan.name}</h6>
            <h3 className='plan-title my-0'>{plan.creditAmount}</h3>
            <p className='my-0'>{money(plan.price.toString())}</p>
          </div>
        </Col>
      )
    })
  }
  return <Row className='mb-4'>{showPlans()}</Row>
}

export default Plans
