import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const mutation = gql`
  mutation PublishFreight($id: ID!) {
    publishedFreight: publishFreight(id: $id) {
      id
    }
  }
`

export default () => {
  const [publishFreight, result] = useMutation(mutation, {
    onError: () => null,
    refetchQueries: [
      'CompanyFreight',
      'CompanyFreights',
      'Company',
      'LazyPaginatedCompanyFreights'
    ]
  })

  return [publishFreight, result]
}
