import Form from './Form'
import { useCompany } from 'queries'
import { useCompanyForm } from 'forms'
import { notify } from 'services'
import React, { useEffect } from 'react'
import { useUpdateCompany } from 'mutations'
import { ShouldRender, Spinner } from 'components'
import { Card, CardHeader, CardBody } from 'reactstrap'
import { remove } from 'utils/mask'

const Company: React.FC = () => {
  const { data, loading } = useCompany()
  const [
    updateCompany,
    { data: company, loading: updating }
  ]: any = useUpdateCompany()

  useEffect(() => {
    if (company?.updatedCompany?.id) {
      notify({
        description: 'Empresa atualizada',
        type: 'success'
      })
    }
  }, [company])

  const normalizeFetchedData = () => {
    const output = { ...data?.company }
    Object.keys(output).forEach((key: string) => {
      return !output[key] && (output[key] = '')
    })

    return output
  }

  const initialValues = {
    document: '',
    phone: '',
    name: '',
    fantasyName: '',
    zipCode: '',
    address: '',
    number: '',
    complement: '',
    district: '',
    city: '',
    ...normalizeFetchedData()
  }

  const formik = useCompanyForm({
    onSubmit: (values) => {
      const variables = { ...values }
      delete variables['city']
      delete variables['document']

      variables['city'] = values.city.value

      updateCompany({ variables })
    },
    initialValues,
    documentType:
      remove(data?.company?.document || '')?.length === 11 ? 'cpf' : 'cnpj'
  })

  return (
    <Card>
      <CardHeader>Empresa</CardHeader>
      <CardBody>
        <ShouldRender if={loading}>
          <Spinner />
        </ShouldRender>
        <ShouldRender if={!loading}>
          <Form formik={formik} loading={updating} />
        </ShouldRender>
      </CardBody>
    </Card>
  )
}

export default Company
