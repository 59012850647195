import Form from './Form'
import React, { useEffect } from 'react'
import format from 'date-fns/format'
import { useFreightForm } from 'forms'
import { remove, money } from 'utils/mask'
import {
  useCreateFreight,
  useUpdateFreight,
  usePublishFreight
} from 'mutations'
import { useLazyCompanyFreight } from 'queries'
import { useLazyQuery } from '@apollo/react-hooks'
import { ShouldRender, Spinner } from 'components'
import { Card, CardHeader, CardBody } from 'reactstrap'
import { notify } from 'services'
import { useHistory } from 'react-router-dom'
import getPaymentType from 'utils/payment'

const Freight: React.FC<FreightType.Props> = ({ id }) => {
  const history = useHistory()
  const [getFreight, { data: freights, loading: fetching }] = useLazyQuery(
    useLazyCompanyFreight,
    {
      variables: { id },
      fetchPolicy: 'no-cache'
    }
  )
  const [createFreight, { data, loading }]: any = useCreateFreight()
  const [
    updateFreight,
    { data: updatedFreight, loading: updating }
  ]: any = useUpdateFreight()
  const [
    publishFreight,
    { data: published, loading: publishing }
  ]: any = usePublishFreight()

  useEffect(() => {
    if (id) {
      getFreight()
    }
  }, [id])

  useEffect(() => {
    if (updatedFreight?.updatedFreight?.id) {
      notify({
        type: 'success',
        description: 'Frete atualizado'
      })
    }

    if (published?.publishedFreight?.id) {
      notify({
        type: 'success',
        description: 'Frete publicado'
      })
      getFreight()
    }

    if (data?.createdFreight?.id) {
      notify({
        type: 'success',
        description: 'Frete criado'
      })
      history.push(`/fretes/${data?.createdFreight?.id}`)
    }
  }, [data, updatedFreight, published])

  const fixDateFormat = (date: string) => {
    return new Date(date).toISOString().substring(0, 16)
  }

  const isPublished = id && freights?.list?.[0]?.status !== 'created'

  const extractCurrentFreight = () => {
    const freight = freights?.list?.length === 1 ? freights.list[0] : {}

    if (freight.id) {
      // Remove null values to keep a controlled form
      Object.keys(freight).forEach((key: string) => {
        !freight[key] && (freight[key] = '')
      })

      Array.isArray(freight.paymentAdvance) &&
        (freight.paymentAdvance = freight.paymentAdvance[0])

      freight.cargoValue = money(freight.cargoValue.toString())
      freight.freightFirstValue = money(freight.freightFirstValue.toString())

      if (typeof freight.paymentMethod === 'string') {
        freight.paymentMethod = getPaymentType(freight.paymentMethod)
      }

      const floorOptions = [
        { label: 'Madeira', value: 'wood' },
        { label: 'Ferro', value: 'iron' },
        { label: 'Alumínio', value: 'aluminum' },
        { label: 'Não aplicável', value: 'not_applicable' }
      ]

      const floorIndex = floorOptions.findIndex((x: any) => {
        return x.value === freight?.acceptedVehicleFloor
      })

      freight.floor = {
        value: freight?.acceptedVehicleFloor || '',
        label: floorIndex > -1 ? floorOptions[floorIndex].label : ''
      }
      ;['tracked', 'requireAnalysis'].forEach((field: string) => {
        if (typeof freight[field] === 'boolean') {
          freight[field] = {
            value: freight[field],
            label: freight[field] ? 'Sim' : 'Não'
          }
        }

        if (typeof freight[field] === 'string') {
          freight[field] = { value: false, label: 'Não' }
        }
      })

      freight.waypoints.map((waypoint: Record<string, any>, index: number) => {
        if (typeof waypoint.kind === 'string') {
          const normalizedWaypoint: any = {
            ...waypoint,
            kind: {
              value: waypoint.kind,
              label: waypoint.kind === 'cargo_collect' ? 'Coleta' : 'Entrega'
            }
          }

          if (/( UTC)/g.test(normalizedWaypoint.expectedAt)) {
            normalizedWaypoint.expectedAt = fixDateFormat(
              normalizedWaypoint.expectedAt
            )
          }

          delete normalizedWaypoint['__typename']
          delete normalizedWaypoint['waypointOrder']

          freight.waypoints[index] = normalizedWaypoint
        }
      })
    }

    return freight
  }

  const initialValues: FormTypes.Freight = {
    startDate: '',
    endDate: '',
    acceptedVehicleTypeIds: [],
    acceptedBodyworkTypeIds: [],
    capacityKg: '',
    capacityM3: '',
    capacityL: '',
    capacityPallets: '',
    cargoCategory: '',
    cargoType: '',
    cargoValue: '',
    freightFirstValue: '',
    freightObservations: '',
    paymentAdvance: '',
    paymentMethod: '',
    tracked: undefined,
    requireAnalysis: undefined,
    floor: '',
    operator: '',
    waypoints: [],
    ...extractCurrentFreight()
  }

  const normalizeWaypoints = (waypoints: Record<string, any>[]) => {
    return waypoints.map((waypoint: Record<string, any>) => {
      const copy = { ...waypoint }
      delete copy['id']
      delete copy['city']

      if (copy.expectedAt) {
        copy.expectedAt = format(new Date(copy.expectedAt), 'y-MM-dd H:mm')
      }

      return {
        ...copy,
        cityId: waypoint.city.value,
        kind: waypoint.kind.value
      }
    })
  }

  const formik = useFreightForm({
    onSubmit: (values) => {
      const advance = Number(values.paymentAdvance)

      const variables = {
        ...values,
        requireAnalysis: values.requireAnalysis.value,
        cargoCategoryId: values.cargoCategory.value,
        cargoTypeId: values.cargoType.value,
        capacityKg: Number(values.capacityKg),
        capacityM3: values.capacityM3 ? Number(values.capacityM3) : undefined,
        capacityL: values.capacityL ? Number(values.capacityL) : undefined,
        capacityPallets: values.capacityPallets
          ? Number(values.capacityPallets)
          : undefined,
        cargoValue: Number(remove(values.cargoValue)),
        freightFirstValue: Number(remove(values.freightFirstValue)),
        paymentAdvance: [advance, 100 - advance],
        paymentMethod: values.paymentMethod.value,
        operatorId: values.operator.value,
        tracked: values.tracked.value,
        waypoints: normalizeWaypoints(values.waypoints),
        acceptedVehicleFloor: values?.floor?.value || undefined
      }

      return id ? updateFreight({ variables }) : createFreight({ variables })
    },
    initialValues
  })

  return (
    <Card>
      <CardHeader>{id ? 'Frete' : 'Novo Frete'}</CardHeader>
      <CardBody>
        <ShouldRender if={fetching}>
          <Spinner />
        </ShouldRender>

        <ShouldRender if={!fetching}>
          <Form
            formik={formik}
            published={!!isPublished}
            publishing={publishing}
            publish={() =>
              publishFreight({
                variables: { id }
              })
            }
            loading={loading || updating || publishing}
          />
        </ShouldRender>
      </CardBody>
    </Card>
  )
}

export default Freight
