import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const mutation = gql`
  mutation DestroyFreight($id: ID!) {
    destroyedFreight: destroyFreight(id: $id) {
      success
    }
  }
`

export default () => {
  const [destroyFreight, result] = useMutation(mutation, {
    onError: () => null,
    refetchQueries: ['CompanyFreights']
  })

  return [destroyFreight, result]
}
