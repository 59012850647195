import gql from 'graphql-tag'

export default gql`
  query LazyProfiles($id: ID, $negotiatorRole: Boolean, $search: String) {
    profiles(
      filter: { id: $id, negotiatorRole: $negotiatorRole, search: $search }
    ) {
      id
      name
      document
      email
      mobilePhone
      adminRole
      negotiatorRole
    }
  }
`
