import gql from 'graphql-tag'
import { useApolloClient } from '@apollo/react-hooks'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useFirebaseLogin } from 'mutations'
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from 'reactstrap'
import { logo } from 'assets/images'
import firebase from 'config/firebase'
import { notify } from 'services'

const Login: React.FC<FreightsType.Props> = () => {
  const history = useHistory()
  const client = useApolloClient()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [firebaseLogin, { data, error }]: any = useFirebaseLogin()

  const checkForAuthenticatedUser = async () => {
    const cachedJWT = await client.query({
      query: gql`
        {
          token @client
        }
      `
    })

    if (!!cachedJWT?.data) {
      history.replace('/dashboard')
      history.go(0)
    }
  }

  useEffect(() => {
    checkForAuthenticatedUser()
  }, [])

  useEffect(() => {
    if (error) {
      setLoading(false)
    }

    if (data?.firebaseLogin?.token) {
      setTimeout(() => {
        history.replace('/dashboard')
        history.go(0)
      }, 1100)
    }
  }, [data, error])

  const login = () => {
    if (validateLogin()) {
      setLoading(true)
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(async (authenticated) => {
          const uid = await authenticated.user?.uid
          const idToken = await authenticated.user?.getIdToken()

          return firebaseLogin({
            variables: {
              uid,
              token: idToken
            }
          })
        })
        .catch((e) => handleError(e))
    }
  }

  const handleError = (error: any) => {
    const options = [
      { code: 'auth/user-not-found', message: 'Este usuário não existe' },
      { code: 'auth/wrong-password', message: 'Credenciais incorretas' }
    ]

    const index = options.findIndex((x) => x.code === error?.code)

    setLoading(false)

    return notify({
      type: 'danger',
      description: options[index > -1 ? index : 0].message
    })
  }

  const validateLogin = () => {
    if (!email) {
      alert('Por favor insira seu e-mail.')
      return false
    }
    if (!password) {
      alert('Por favor insira sua senha.')
      return false
    }
    return login
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      login()
    }
  }

  return (
    <div className='app flex-row align-items-center yellow-bg'>
      <Container>
        <Row className='justify-content-center'>
          <Col md='12' lg='5'>
            <Card className='p-4 login-card'>
              <CardBody>
                <Form>
                  <Row>
                    <Col className='text-center mb-4'>
                      <img
                        src={logo}
                        style={{ width: '140px', height: 'auto' }}
                        className='mx-auto'
                      />
                    </Col>
                  </Row>
                  <InputGroup className='mb-3'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='cui-user'></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type='text'
                      placeholder='E-mail'
                      autoComplete='email'
                      disabled={loading}
                      value={email}
                      onChange={(evt) => {
                        setEmail(evt.target.value)
                      }}
                    />
                  </InputGroup>
                  <InputGroup className='mb-4'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='icon-lock'></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type='password'
                      placeholder='Senha'
                      disabled={loading}
                      autoComplete='current-password'
                      value={password}
                      onChange={(evt) => {
                        setPassword(evt.target.value)
                      }}
                      onKeyDown={handleKeyDown}
                    />
                  </InputGroup>
                  <Row>
                    <Col xs='6' className='d-flex align-items-center'>
                      <Button
                        onClick={() => {
                          history.push('/recuperar-senha')
                        }}
                        className='custom-btn green-btn'
                      >
                        Esqueceu sua senha?
                      </Button>
                    </Col>
                    <Col xs='6' className='text-right'>
                      <Button
                        color='success'
                        className='px-4'
                        onClick={login}
                        disabled={loading}
                      >
                        {loading ? 'Entrando' : 'Entrar'}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className='text-center'>
            <Button
              onClick={() => {
                history.push('/cadastro')
              }}
              className='custom-btn white-text-btn'
            >
              Criar conta com e-mail
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Login
