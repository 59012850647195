import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const query = gql`
  query CompanyKinds {
    __type(name: "CompanyKind") {
      name
      enumValues {
        name
      }
    }
  }
`

export default () => useQuery(query)
