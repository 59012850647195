import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const mutation = gql`
  mutation AcceptOffer($offerId: ID!) {
    accepted: acceptOffer(offerId: $offerId) {
      id
    }
  }
`

export default () => {
  const [acceptOffer, result] = useMutation(mutation, {
    refetchQueries: ['LazyFreightOffers', 'Company'],
    onError: () => null
  })

  return [acceptOffer, result]
}
