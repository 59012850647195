import * as y from 'yup'
import { useFormik } from 'formik'
import { select } from 'utils/validation'

const useFreight: FormTypes.Hook = ({ onSubmit, initialValues }) => {
  const formik = useFormik({
    onSubmit,
    initialValues,
    enableReinitialize: true,
    validationSchema: y.object().shape({
      acceptedVehicleTypeIds: y
        .array()
        .required('Selecione pelo menos um tipo de veículo'),
      capacityKg: y.string().required('Obrigatório'),
      capacityM3: y
        .string()
        .nullable()
        .notRequired(),
      cargoType: select,
      cargoValue: y.string().required('Obrigatório'),
      freightFirstValue: y.string().required('Obrigatório'),
      freightObservations: y
        .string()
        .nullable()
        .notRequired(),
      paymentAdvance: y
        .string()
        .nullable()
        .notRequired(),
      paymentMethod: select,
      requireAnalysis: select,
      tracked: select,
      operator: select,
      waypoints: y
        .array()
        .required('Adicione os pontos de coleta/entrega da carga')
      // startDate: y
      //   .date()
      //   .test('not past', 'Data inválida', function(value) {
      //     return value ? value > new Date() : true
      //   })
      //   .required('Obrigatório'),
      // endDate: y
      //   .date()
      //   .when('startDate', (startDate: any, schema: any) => {
      //     return startDate && schema.min(startDate, 'Data inválida')
      //   })
      //   .required('Obrigatório')
    })
  })

  return formik
}

export default useFreight
