import { useViaCep } from 'hooks'
import { useLazyCities } from 'queries'
import React, { useEffect } from 'react'
import { getFormikError } from 'services'
import { Row, Col, Button } from 'reactstrap'
import { phone, cnpj, cep, remove } from 'utils/mask'
import { TextInput, Select } from 'components'
import { useLazyQuery } from '@apollo/react-hooks'

const Form: React.FC<CompanyType.Props> = ({ formik, loading }) => {
  const {
    values,
    getFieldMeta,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
    handleSubmit
  } = formik

  const { loading: loadingAddress, result: viaCepAddress } = useViaCep(
    values.zipCode
  )

  const [
    getCities,
    { data: list, loading: loadingCities }
  ] = useLazyQuery(useLazyCities, { fetchPolicy: 'no-cache' })

  useEffect(() => {
    if (viaCepAddress) {
      !values['address'] && setFieldValue('address', viaCepAddress?.address)
      !values['district'] && setFieldValue('district', viaCepAddress?.district)
    }
  }, [viaCepAddress])

  const searchCities = async (inputValue: string, callback: any) => {
    if (!inputValue || inputValue.trim().length < 3) {
      return callback([])
    }

    if (inputValue.trim().length >= 3) {
      getCities({ variables: { name: inputValue } })

      if (list) {
        const options = list?.cities?.map((city: Record<string, any>) => ({
          label: `${city.label}, ${city.state?.initials}`,
          value: city.value
        }))

        callback(options)
      }
    }
  }

  return (
    <React.Fragment>
      <Row>
        <TextInput
          size={6}
          disabled
          label={remove(values?.document)?.length === 11 ? 'CPF' : 'CNPJ'}
          {...getFieldProps('document', 'text')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('document', cnpj(e.target.value))
          }}
          error={getFormikError(getFieldMeta, 'document')}
        />

        <TextInput
          size={6}
          label='Telefone'
          {...getFieldProps('phone', 'text')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('phone', phone(e.target.value))
          }}
          error={getFormikError(getFieldMeta, 'phone')}
        />

        <TextInput
          size={6}
          label='Razão social'
          {...getFieldProps('name', 'text')}
          error={getFormikError(getFieldMeta, 'name')}
        />

        <TextInput
          size={6}
          label='Nome fantasia'
          {...getFieldProps('fantasyName', 'text')}
          error={getFormikError(getFieldMeta, 'fantasyName')}
        />

        <TextInput
          label='CEP'
          disabled={loadingAddress}
          size={{ xs: 12, sm: 6, md: 3 }}
          {...getFieldProps('zipCode', 'text')}
          error={getFormikError(getFieldMeta, 'zipCode')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('zipCode', cep(e.target.value))
          }}
        />
      </Row>
      <Row>
        <TextInput
          label='Endereço'
          size={{ xs: 12, sm: 6 }}
          {...getFieldProps('address', 'text')}
          error={getFormikError(getFieldMeta, 'address')}
        />

        <TextInput
          label='Número'
          size={{ xs: 12, sm: 6, md: 3 }}
          {...getFieldProps('number', 'text')}
          error={getFormikError(getFieldMeta, 'number')}
        />

        <TextInput
          label='Complemento'
          size={{ xs: 12, sm: 6, md: 3 }}
          {...getFieldProps('complement', 'text')}
          error={getFormikError(getFieldMeta, 'complement')}
        />

        <TextInput
          label='Bairro'
          size={{ xs: 12, sm: 6, md: 3 }}
          {...getFieldProps('district', 'text')}
          error={getFormikError(getFieldMeta, 'district')}
        />

        <Select
          size={{ xs: 12, sm: 6, md: 3 }}
          label='Cidade'
          name='city'
          loading={loadingCities}
          value={values['city']}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          error={getFormikError(getFieldMeta, 'city')}
          loadOptions={searchCities}
        />
      </Row>
      <Row className='flex-row-reverse mb-4'>
        <Col sm={3} xl={2}>
          <Button
            block
            color='success'
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? 'Salvando' : 'Salvar'}
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Form
