import gql from 'graphql-tag'

export default gql`
  query LazyPaginatedCompanyFreights($after: String) {
    list: paginatedCompanyFreights(first: 30, after: $after) {
      nodes {
        id
        startDate
        endDate
        status
        offersCount
        waypoints {
          expectedAt
        }
        startCity {
          name
          state {
            initials
          }
        }
        endCity {
          name
          state {
            initials
          }
        }
        operator {
          name
        }
        publisher {
          company {
            name
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
`
