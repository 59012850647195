import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const mutation = gql`
  mutation AnalyseOffer($offerId: ID!) {
    analysedOffer: analyseOffer(offerId: $offerId, provider: tecnorisk) {
      id
    }
  }
`

export default () => {
  const [analyseOffer, result] = useMutation(mutation, {
    refetchQueries: ['LazyFreightOffers'],
    onError: () => null
  })

  return [analyseOffer, result]
}
