import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useProfile, useCompany, useCurrentUser } from 'queries'
import {
  Nav,
  NavItem,
  NavLink,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap'

import { AppSidebarToggler, AppNavbarBrand } from '@coreui/react'
import { logo, sygnet } from 'assets/images'
import './style.css'
import firebase from 'config/firebase'
import { dropApolloCache } from 'services'
import ShouldRender from '../ShouldRender'

const Header: React.FC = () => {
  const history = useHistory()
  const { data } = useCurrentUser()
  const { data: user } = useProfile()
  const { data: company } = useCompany()
  const [dropdownOpen, setDropdownOpen] = useState(false)

  return (
    <React.Fragment>
      <AppSidebarToggler
        className='d-lg-none white-toggler'
        display='md'
        mobile
      />
      <AppNavbarBrand
        full={{ src: logo, width: 80, height: 39, alt: 'Logo Frete' }}
        minimized={{ src: sygnet, width: 30, height: 30, alt: 'Logo Frete' }}
      />
      <AppSidebarToggler
        className='d-md-down-none white-toggler'
        display='lg'
      />
      <Nav className='ml-auto' navbar>
        <ShouldRender if={data?.currentUser?.role !== 'back_office'}>
          <NavItem className='px-3'>
            <NavLink
              href='#'
              className='white-nav-link'
              onClick={() => {
                history.push('/creditos')
              }}
            >
              {`${company?.company?.balance || '0'} créditos`}
            </NavLink>
          </NavItem>
        </ShouldRender>

        <ButtonDropdown
          className='mr-4'
          isOpen={dropdownOpen}
          toggle={() => {
            setDropdownOpen(!dropdownOpen)
          }}
        >
          <DropdownToggle caret color='white-dropdown'>
            {user?.profile?.name || '...'}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={() => {
                firebase.auth().signOut()
                dropApolloCache()
              }}
            >
              Logout
            </DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      </Nav>
    </React.Fragment>
  )
}

export default Header
