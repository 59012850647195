import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const mutation = gql`
  mutation CreateFreight(
    $acceptedBodyworkTypeIds: [ID!]
    $acceptedVehicleTypeIds: [ID!]!
    $capacityKg: Int!
    $capacityL: Int
    $capacityM3: Int
    $capacityPallets: Int
    $cargoCategoryId: ID!
    $cargoTypeId: ID!
    $cargoValue: Int!
    $freightFirstValue: Int!
    $operatorId: ID!
    $paymentAdvance: [Int!]!
    $paymentMethod: FreightPaymentMethod!
    $tracked: Boolean!
    $waypoints: [InputWaypoint!]!
    $freightObservations: String
    $acceptedVehicleFloor: VehicleFloorKind
    $requireAnalysis: Boolean
  ) {
    createdFreight: createFreight(
      acceptedBodyworkTypeIds: $acceptedBodyworkTypeIds
      acceptedVehicleTypeIds: $acceptedVehicleTypeIds
      capacityKg: $capacityKg
      capacityL: $capacityL
      capacityM3: $capacityM3
      capacityPallets: $capacityPallets
      cargoCategoryId: $cargoCategoryId
      cargoTypeId: $cargoTypeId
      cargoValue: $cargoValue
      freightFirstValue: $freightFirstValue
      operatorId: $operatorId
      paymentAdvance: $paymentAdvance
      paymentMethod: $paymentMethod
      tracked: $tracked
      waypoints: $waypoints
      freightObservations: $freightObservations
      acceptedVehicleFloor: $acceptedVehicleFloor
      requireAnalysis: $requireAnalysis
    ) {
      id
    }
  }
`

export default () => {
  const [createFreight, result] = useMutation(mutation, {
    onError: () => null,
    refetchQueries: ['Company']
  })

  return [createFreight, result]
}
