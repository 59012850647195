import { useSignUpForm } from 'forms'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Col, Container, Row } from 'reactstrap'
import { logo } from 'assets/images'

const Terms: React.FC = () => {
  const history = useHistory()

  return (
    <div className='yellow-bg'>
      <div className='absolute-back-btn'>
        <Button
          onClick={() => {
            history.push('/cadastro')
          }}
          className='custom-btn white-text-btn'
        >
          <i className='fa fa-arrow-left' aria-hidden='true'></i> Voltar
        </Button>
      </div>
      <div className='app'>
        <Container className='signup-header-margin'>
          <Row>
            <Col sm={3} className='text-center'>
              <img className='img-fluid' src={logo} />
            </Col>
            <Col>
              <Row className='mb-3'>
                <Col>
                  <h1>Política de Privacidade</h1>
                  <b>Em conformidade com a LGPD - Lei 13.709/2018</b>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    <b>
                      1. INFORMAÇÕES GERAIS E DEFINIÇÕES DA POLÍTICA DE
                      PRIVACIDADE.
                    </b>
                  </p>
                  A FRETE + valoriza a privacidade de seus usuários e criou esta
                  Política de Privacidade para demonstrar seu compromisso em
                  proteger a sua privacidade e seus dados pessoais, nos termos
                  da Lei Geral de Proteção de Dados (Lei 13.709/2018) e demais
                  leis sobre o tema, bem como descrever de que forma sua
                  privacidade é protegida, ao serem coletadas, tratadas e
                  armazenadas suas informações pessoais.
                  <br />
                  <br />
                  <p>
                    <b>1.1 Definições</b>
                  </p>
                  <b>Usuário:</b> todas as pessoas físicas que utilizarão ou
                  visitarão o Site www.fretemais.com e/ou Aplicativo FRETE +
                  para Android ou IOS, maiores de 18 (dezoito) anos ou
                  emancipadas e totalmente capazes de praticar os atos da vida
                  civil ou os absolutamente ou relativamente incapazes
                  devidamente representados ou assistidos.
                  <br />
                  <br />
                  <b>Dados Pessoais:</b> significa quaisquer informações
                  fornecidas e/ou coletadas pela FRETE + e/ou seus parceiros
                  comerciais, por qualquer meio, ainda que públicos, que: (I)
                  identifiquem, ou que, quando usadas em combinação com outras
                  informações tratadas pela FRETE + identifiquem um indivíduo;
                  ou (II) por meio das quais a identificação ou informações de
                  contato de uma pessoa física possam ser derivadas. Os Dados
                  Pessoais podem estar em qualquer mídia ou formato, inclusive
                  registros eletrônicos ou computadorizados, em nuvem, bem como
                  em arquivos baseados em papel no dia a dia da empresa. Os
                  Dados Pessoais, no entanto, não incluem telefone comercial,
                  número de celular comercial, endereço comercial, e-mail
                  comercial.
                  <br />
                  <br />
                  <b>Finalidade:</b> o objetivo, o propósito que a FRETE +
                  deseja alcançar a partir de cada ato de tratamento das
                  informações pessoais.
                  <br />
                  <br />
                  <b>Necessidade:</b> justificativa pelo qual é estritamente
                  necessário coletar dados pessoais, para atingir a finalidade
                  do uso do Site www.fretemais.com e/ou Aplicativo FRETE + para
                  Android ou IOS, evitando-se a coleta excessiva.
                  <br />
                  <br />
                  <b>Bases legais:</b> fundamentação legal que torna legítimo o
                  tratamento de dados pessoais para uma determinada finalidade
                  prévia por parte da FRETE +.
                  <br />
                  <br />
                  <b>Consentimento:</b> autorização expressa e inequívoca dada
                  pelo Usuário titular do dado pessoal para que a FRETE + trate
                  seus dados pessoais para uma finalidade previamente descrita,
                  na qual a base legal necessária para o ato demande a
                  autorização expressa do titular.
                  <br />
                  <br />
                  Esta política se aplica, em geral, a todos os Usuários e
                  potenciais Usuários dos serviços oferecidos pela FRETE +,
                  incluindo Usuários dos sites ou outros meios operados pela
                  FRETE + e resume como a FRETE + poderá coletar, produzir,
                  receptar, classificar, utilizar, acessar, reproduzir,
                  transmitir, distribuir, processar, arquivar, armazenar,
                  eliminar, avaliar ou controlar a informação, modificar,
                  comunicar, transferir, difundir ou extrair os dados coletados,
                  incluindo as informações de identificação pessoal, de acordo
                  com as bases legais aplicáveis e todas as leis de privacidade
                  e proteção de dados em vigor.
                  <br />
                  <br />
                  Ao acessar e/ou utilizar o Site www.fretemais.com e/ou
                  Aplicativo FRETE + para Android ou IOS, o Usuário declara ter
                  no mínimo 18 (dezoito) anos e ter capacidade plena e expressa
                  para a aceitação dos termos e condições desta Política de
                  Privacidade e do Termo de Consentimento para todos os fins de
                  direito.
                  <br />
                  <br />
                  Caso o Usuário não se enquadre na descrição acima e/ou não
                  concorde, ainda que em parte, com os termos e condições
                  contidos nesta Política de Privacidade, não deverá acessar
                  e/ou utilizar os serviços oferecidos pela FRETE +, bem como os
                  sites e serviços por ela operados.
                  <br />
                  <br />
                  <p>
                    <b>2. COLETA E USOS DE INFORMAÇÕES PESSOAIS</b>
                  </p>
                  O Usuário está ciente de que fornece informação de forma
                  consciente e voluntária por meio de preenchimento de
                  formulário, em Site e/ou aplicativo operados pela FRETE +.
                  <br />
                  <br />
                  Quando o Usuário realiza o cadastro e/ou preenche formulários
                  oferecidos pela FRETE +, determinados Dados Pessoais
                  solicitados serão mantidos em sigilo e serão utilizadas apenas
                  para o propósito que motivou o cadastro.
                  <br />
                  <br />
                  <p>
                    <b>
                      3. COMPARTILHAMENTO E TRATAMENTO DE INFORMAÇÕES PESSOAIS
                    </b>
                  </p>
                  A FRETE + não disponibilizará Dados Pessoais coletados em seu
                  Site www.fretemais.com e/ou Aplicativo FRETE + para Android ou
                  IOS para corretores de lista de e-mail sem seu expresso
                  consentimento.
                  <br />
                  A FRETE + poderá divulgar os Dados Pessoais coletados a
                  terceiros, nas seguintes situações e nos limites exigidos e
                  autorizados pela Lei:
                  <br />
                  <br />
                  <ul>
                    <li>
                      I. Com os seus clientes e parceiros quando necessário e/ou
                      apropriado à prestação de serviços relacionados;
                    </li>
                    <li>
                      II. Com as empresas e indivíduos contratados para a
                      execução de determinadas atividades e serviços em nome da
                      FRETE +;
                    </li>
                    <li>III. Com empresas do grupo;</li>
                    <li>
                      IV. Com fornecedores e parceiros para consecução dos
                      serviços contratados com a FRETE + (como tecnologia da
                      informação, contabilidade, entre outros);
                    </li>
                    <li>
                      V. Para propósitos administrativos como: pesquisa,
                      planejamento, desenvolvimento de serviços, segurança e
                      gerenciamento de risco.
                    </li>
                    <li>
                      VI. Quando necessário em decorrência de obrigação legal,
                      determinação de autoridade competente, ou decisão
                      judicial.
                    </li>
                  </ul>
                  Nas hipóteses de compartilhamento de Dados Pessoais com
                  terceiros, todos os sujeitos mencionados nos itens I a VI
                  deverão utilizar os Dados Pessoais partilhados de maneira
                  consistente e de acordo com os propósitos para os quais foram
                  coletados (ou com os quais o Usuário consentiu previamente) e
                  de acordo com o que foi determinado por esta Política de
                  Privacidade, outras declarações de privacidade de website ou
                  países, e todas as leis de privacidade e proteção de dados
                  aplicáveis.
                  <br />
                  <br />
                  <p>
                    <b>4. MOTIVOS LEGAIS PARA A DIVULGAÇÃO DE SEUS DADOS</b>
                  </p>
                  Em certas circunstâncias, a FRETE + poderá divulgar Dados
                  Pessoais, na medida necessária ou apropriada, para órgãos
                  governamentais, consultores e outros terceiros com o objetivo
                  de cumprir com a legislação aplicável ou com uma ordem ou
                  intimação judicial ou, ainda, se a FRETE + acreditar de boa-fé
                  que tal ação seja necessária para:
                  <br />
                  <br />
                  <ul>
                    <li>
                      I. Cumprir com uma legislação que exija tal divulgação;
                    </li>
                    <li>
                      II. Investigar, impedir ou tomar medidas relacionadas a
                      atividades ilegais suspeitas ou reais ou para cooperar com
                      órgãos públicos ou para proteger a segurança nacional;
                    </li>
                    <li>III. Execução de seus contratos;</li>
                    <li>
                      IV. Investigar e se defender contra quaisquer
                      reivindicações ou alegações de terceiros;
                    </li>
                    <li>
                      V. Proteger a segurança ou a integridade dos serviços (por
                      exemplo, o compartilhamento com empresas que estão
                      sofrendo ameaças semelhantes);
                    </li>
                    <li>
                      VI. Exercer ou proteger os direitos, a propriedade e a
                      segurança da FRETE + e eventuais empresas coligadas;
                    </li>
                    <li>
                      VII. Proteger os direitos e a segurança pessoal de seus
                      funcionários, usuários ou do público;
                    </li>
                    <li>
                      VIII. Em caso de venda, compra, fusão, reorganização,
                      liquidação ou dissolução da FRETE +.
                    </li>
                  </ul>
                  A FRETE + cientificará os respectivos Usuários sobre eventuais
                  demandas legais que resultem na divulgação de informações
                  pessoais, nos termos do que foi exposto no item 4, a menos que
                  tal cientificação seja vedada por lei ou proibida por mandado
                  judicial ou, ainda, se a requisição for emergencial. A FRETE +
                  poderá contestar essas demandas se julgar que as solicitações
                  são excessivas, vagas ou feitas por autoridades incompetentes.
                  <br />
                  <br />
                  <p>
                    <b>5. SEGURANÇA DE INFORMAÇÕES PESSOAIS</b>
                  </p>
                  Todas os Dados Pessoais serão guardados na base de dados da
                  FRETE + mantidas “na nuvem” pelo fornecedor de serviço HEROKU
                  U.S.A. - A SALESFORCE COMPANY, contratado pela FRETE +, os
                  quais estão devidamente de acordo com a legislação de dados
                  vigente. A política de privacidade da HEROKU poderá ser
                  acessada no Site
                  https://www.heroku.com/policy/salesforce-heroku-msa .<br />
                  <br />
                  A FRETE + e seus fornecedores utilizam vários procedimentos de
                  segurança para proteger a confidencialidade, segurança e
                  integridade de seus Dados Pessoais, prevenindo a ocorrência de
                  eventuais danos em virtude do tratamento desses dados.
                  <br />
                  <br />
                  Embora a FRETE + utilize medidas de segurança e monitore seu
                  sistema para verificar vulnerabilidades e ataques para
                  proteger seus Dados Pessoais contra divulgação não autorizada,
                  mau uso ou alteração, o Usuário entende e concorda que não há
                  garantias de que as informações não poderão ser acessadas,
                  divulgadas, alteradas ou destruídas por violação de qualquer
                  uma das proteções físicas, técnicas ou administrativas.
                  <br />
                  <br />
                  <p>
                    <b>6. RETENÇÃO DE DADOS</b>
                  </p>
                  A FRETE + retém todos os dados fornecidos, inclusive os Dados
                  Pessoais, enquanto o cadastro do Usuário estiver ativo e
                  conforme seja necessário para consecução de seus serviços.
                  <br />
                  <br />
                  A FRETE + reterá seus Dados Pessoais e manterá seus dados
                  armazenados até eventual requerimento de exclusão.
                  <br />
                  <br />A FRETE + poderá vir a manter seus Dados Pessoais após
                  receber seu pedido de exclusão caso seja necessário para
                  cumprimento de obrigações legais, resolver disputas, manter a
                  segurança, evitar fraudes e abuso e garantir o cumprimento de
                  contratos.
                  <br />
                  <br />
                  <p>
                    <b>7. BASES LEGAIS PARA PROCESSAMENTO</b>
                  </p>
                  A FRETE + apenas trata Dados Pessoais em situações em que está
                  autorizada legalmente ou mediante seu expresso e inequívoco
                  consentimento do Usuário.
                  <br />
                  <br />
                  Conforme descrito na presente Política, a FRETE + tem bases
                  legais para coletar, produzir, receptar, classificar,
                  utilizar, acessar, reproduzir, transmitir, distribuir,
                  processar, arquivar, armazenar, eliminar, avaliar ou controlar
                  a informação, modificar, comunicar, transferir, difundir ou
                  extrair dados sobre o Usuário.
                  <br />
                  <br />
                  As bases legais incluem seu consentimento (colhido de forma
                  expressa e inequívoca no Termo de Consentimento), contratos e
                  procedimentos preliminares contratuais (em que o processamento
                  é necessário para firmar o contrato com o Usuário) e
                  interesses legítimos, desde que tal processamento não viole
                  seus direitos e liberdades.
                  <br />
                  <br />
                  Tais interesses incluem proteger o Usuário e a FRETE + de
                  ameaças, cumprir a legislação aplicável, o exercício regular
                  de direitos em processo judicial, administrativo ou arbitral,
                  habilitar a realização ou administração dos negócios,
                  incluindo controle de qualidade, relatórios e serviços
                  oferecidos, gerenciar transações empresariais, entender e
                  melhorar os negócios e relacionamentos com os clientes e
                  permitir que os usuários encontrem oportunidades econômicas.
                  <br />
                  <br />
                  O Usuário tem o direito de negar ou retirar o consentimento
                  fornecido à FRETE +, quando esta for a base legal para
                  tratamento dos dados pessoais, podendo a FRETE + encerrar a
                  consecução de seus serviços para este usuário na hipótese de
                  ocorrência de tal solicitação.
                  <br />
                  <br />
                  Caso tenha dúvidas sobre as bases legais para coleta,
                  tratamento e armazenamento de seus dados pessoais, entre em
                  contato com a FRETE + por meio do e-mail{' '}
                  <b>contato@fretemais.com</b>.
                  <br />
                  <br />
                  <p>
                    <b>8. DIREITO DE ACESSAR E CONTROLAR SEUS DADOS PESSOAIS</b>
                  </p>
                  A FRETE + oferece ao Usuário diversas opções do que fazer com
                  seus Dados Pessoais coletados, tratados e armazenados,
                  incluindo sua exclusão e/ou correção. O Usuário pode:
                  <ul>
                    <li>
                      I. <b>Excluir dados:</b> o Usuário pode solicitar a
                      exclusão de alguns dos seus Dados Pessoais (por exemplo,
                      se eles não são mais necessários para lhe fornecer os
                      serviços).
                    </li>
                    <li>
                      II. <b>Alterar ou corrigir dados:</b> o Usuário pode
                      editar ou solicitar a edição de alguns dos seus Dados
                      Pessoais. O Usuário também pode solicitar atualizações,
                      alterações ou correções de seus dados em determinados
                      casos, principalmente se eles estiverem incorretos.
                    </li>
                    <li>
                      III.{' '}
                      <b>
                        Colocar objeções, limites ou restrições ao uso de dados:
                      </b>{' '}
                      o Usuário pode solicitar a interrupção do uso de todos ou
                      alguns de seus Dados Pessoais (por exemplo, se não
                      tivermos o direito de continuar a usá-los), ou limitar a
                      nossa utilização de tais dados (por exemplo, se seus Dados
                      Pessoais estiverem incorretos ou armazenados ilegalmente),
                      destacando-se que a FRETE + poderá tratar os Dados
                      Pessoais de acordo com as bases legais de sua finalidade.
                    </li>
                    <li>
                      IV.{' '}
                      <b>
                        O Usuário tem direito de acessar ou levar seus dados:
                      </b>{' '}
                      o Usuário pode solicitar uma cópia dos seus Dados Pessoais
                      e dos dados que o Usuário forneceu em um formato legível
                      sob a forma impressa ou por meio eletrônico.
                    </li>
                    <li></li>
                  </ul>
                  O Usuário pode fazer as solicitações listadas acima entrando
                  em contato por meio do e-mail contato@fretemais.com, e estes
                  pedidos serão considerados de acordo com as leis aplicáveis.
                  <br />
                  <br />
                  <p>
                    <b>9. REVISÕES DESTA POLÍTICA</b>
                  </p>
                  Caso a FRETE + modifique esta Política de Privacidade, tais
                  alterações serão publicadas de forma visível no site da
                  www.fretemais.com. Esta Política é válida a partir de
                  01/08/2020. Caso o Usuário tenha quaisquer questões a respeito
                  das políticas de privacidade do website, por favor, entre em
                  contato com a FRETE +.
                  <br />
                  <br />
                  <p>
                    <b>10. CONTATO</b>
                  </p>
                  A FRETE + possui departamento próprio para tratar de situações
                  ligadas à proteção de dados, na forma do art. 41 da LGPD (Lei
                  13.709/2018), o qual poderá ser contatado para eventuais
                  dúvidas à respeito e solicitações. O Usuário pode entrar em
                  contato com o departamento por meio do e-mail
                  <b>dpo@fretemais.com</b>.
                  <br />
                  <br />
                  <p>
                    <b>11. MEDIAÇÃO E FORO DE ELEIÇÃO</b>
                  </p>
                  Esta política está sujeita à Lei da República Federativa do
                  Brasil e o Foro da Comarca de Londrina/PR é competente para
                  dirimir qualquer controvérsia com relação à mesma.
                  <br />
                  <br />
                  Em caso de incidente com Dados Pessoais, a FRETE + desde já,
                  se reserva a nomear Câmara de Mediação junto à Comarca de
                  Londrina/PR, apta a dirimir as questões envolvendo os Usuários
                  titulares dos dados, nos termos do artigo 52, §7º da Lei Geral
                  de Proteção de Dados.
                  <br />
                  <br />
                  <br />
                  <br />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Terms
