import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const mutation = gql`
  mutation UpdateCompany(
    $id: ID!
    $phone: String
    $name: String
    $fantasyName: String
    $address: String
    $number: String
    $stateRegistration: String
    $complement: String
    $district: String
    $city: ID
    $email: String
    $zipCode: String
  ) {
    updatedCompany: updateCompany(
      id: $id
      phone: $phone
      name: $name
      fantasyName: $fantasyName
      address: $address
      number: $number
      stateRegistration: $stateRegistration
      complement: $complement
      district: $district
      cityId: $city
      email: $email
      zipCode: $zipCode
    ) {
      id
    }
  }
`

export default () => {
  const [updateCompany, result] = useMutation(mutation, { onError: () => null })

  return [updateCompany, result]
}
