import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const query = gql`
  query ProfileDocumentKinds {
    documents: __type (name: "ProfileDocumentKind") {
      name
      values: enumValues {
        name
      }
    }
  }
`

export default () => useQuery(query, { fetchPolicy: 'no-cache' })
