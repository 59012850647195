export const options = [
  { label: 'Depósito em conta', value: 'bank_deposit' },
  { label: 'Dinheiro', value: 'money' },
  { label: 'Cheque', value: 'bank_check' },
  { label: 'Outros', value: 'other' },
  { label: 'Repom', value: 'repom' },
  { label: 'Roadcard', value: 'roadcard' },
  { label: 'GPS Logística', value: 'gps_logistica' },
  { label: 'DBtrans', value: 'dbtrans' },
  { label: 'Policard', value: 'policard' },
  { label: 'Caruana', value: 'caruana' },
  { label: 'Nddigital', value: 'nddigital' },
  { label: 'Banco do Brasil', value: 'banco_do_brasil' },
  { label: 'Banco Bradesco', value: 'banco_bradesco' },
  { label: 'Fastcred', value: 'fastcred' },
  { label: 'Multisat', value: 'multisat' },
  { label: 'Green Net', value: 'green_net' },
  { label: 'Valecard ProcDados', value: 'valecard_proc_dados' },
  { label: 'Embrated', value: 'embratec' },
  { label: 'IPC Administração', value: 'ipc_administracao' },
  { label: 'CTF Pitstop', value: 'ctf_pitstop' },
  { label: 'Target', value: 'target' },
  { label: 'Senffnet', value: 'senffnet' },
  { label: 'Rodofretex', value: 'rodofretex' },
  { label: 'Retail', value: 'retail' },
  { label: 'Maxmovi', value: 'maxmovi' },
  { label: 'Unik/Wex', value: 'unik_wex' },
  { label: 'Trivale', value: 'trivale' },
  { label: 'JSL', value: 'jsl' },
  { label: 'Novocard', value: 'novocard' },
  { label: 'Sollus', value: 'sollus' },
  { label: 'Pagbem', value: 'pagbem' }
]

const getPaymentType = (input: string) => {
  const index = options.findIndex((x) => x.value === input)

  return options[index > -1 ? index : 0]
}

export default getPaymentType
