import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const mutation = gql`
  mutation UpdateDocumentStatus($id: ID!, $status: DocumentStatus!) {
    document: updateProfileDocument(id: $id, status: $status) {
      id
      status
    }
  }
`

export default () => {
  const [updateProfileDocument, result] = useMutation(mutation, {
    onError: () => null,
    refetchQueries: ['Profiles', 'DriverProfile', 'ProfileDocuments']
  })

  return [updateProfileDocument, result]
}
