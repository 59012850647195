import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const query = gql`
  query Profile {
    profile {
      adminRole
      id
      name
      negotiatorRole
    }
  }
`

export default () => useQuery(query, { fetchPolicy: 'no-cache' })
