import React from 'react'
import { Row } from 'reactstrap'
import { TextInput } from 'components'

const DriverForm: React.FC<{ fields: any }> = ({ fields }) => {
  return (
    <React.Fragment>
      <Row>
        <TextInput
          size={4}
          disabled
          label='Categoria'
          name='category'
          value={fields['category']}
        />

        <TextInput
          size={4}
          disabled
          label='Marca'
          name='brand'
          value={fields['brand']}
        />

        <TextInput
          size={4}
          disabled
          label='Modelo'
          name='model'
          value={fields['model']}
        />

        <TextInput
          size={4}
          disabled
          label='Ano'
          name='year'
          value={fields['year']}
        />

        <TextInput
          size={4}
          disabled
          label='Carroceria'
          name='bodywork'
          value={fields['bodywork']}
        />

        <TextInput
          size={4}
          disabled
          label='Assoalho'
          name='vehicleFloor'
          value={fields['vehicleFloor']}
        />

        <TextInput
          size={4}
          disabled
          label='Cor'
          name='color'
          value={fields['color']}
        />

        <TextInput
          size={4}
          disabled
          label='Placa'
          name='licensePlate'
          value={fields['licensePlate']}
        />

        <TextInput
          size={4}
          disabled
          label='Chassi'
          name='chassis'
          value={fields['chassis']}
        />

        <TextInput
          size={4}
          disabled
          label='Renavam'
          name='renavam'
          value={fields['renavam']}
        />

        <TextInput
          size={4}
          disabled
          label='RNTRC'
          name='anttRntrc'
          value={fields['anttRntrc']}
        />

        <TextInput
          size={4}
          disabled
          label='Vencimento ANTT'
          name='anttDueDate'
          value={fields['anttDueDate']}
        />

        <TextInput
          size={4}
          disabled
          label='Nacionalidade'
          name='nationality'
          value={fields['nationality']}
        />

        <TextInput
          size={4}
          disabled
          label='Ano'
          name='year'
          value={fields['year']}
        />

        <TextInput
          size={4}
          disabled
          label='Tara'
          name='tara'
          value={fields['tara']}
        />

        <TextInput
          size={4}
          disabled
          label='Capacidade Kg'
          name='capacityKg'
          value={fields['capacityKg']}
        />

        <TextInput
          size={4}
          disabled
          label='Capacidade ㎥'
          name='capacityM3'
          value={fields['capacityM3']}
        />

        <TextInput
          size={4}
          disabled
          label='Rastreador'
          name='tracker'
          value={fields['tracker']}
        />
      </Row>
    </React.Fragment>
  )
}

export default DriverForm
