import React, { useState, useEffect } from 'react'
import { useProfile, useCurrentUser, useLazyPaginatedProfiles } from 'queries'
import { useDestroyProfile } from 'mutations'
import { useHistory } from 'react-router-dom'
import { Table, Spinner, ShouldRender, WarningModal } from 'components'
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { notify } from 'services'
import { useLazyQuery } from '@apollo/react-hooks'

const Users: React.FC<UsersType.Props> = () => {
  const history = useHistory()
  const { data: user } = useProfile()
  const { data: currentUser, loading: loadingCurrentUser } = useCurrentUser()
  const [modalData, setModalData] = useState() as any
  const [
    destroyProfile,
    { data: destroyed, loading: destroying, error }
  ]: any = useDestroyProfile()
  const [getProfiles, { data, loading }] = useLazyQuery(
    useLazyPaginatedProfiles,
    {
      fetchPolicy: 'no-cache'
    }
  )

  const [profiles, setProfiles] = useState<UsersType.User[]>([])
  const [nextPageCursor, setNextPageCursor] = useState()

  useEffect(() => {
    getProfiles()
  }, [])

  useEffect(() => {
    if (data?.paginatedProfiles?.nodes) {
      setProfiles((prevState: any) => [
        ...prevState,
        ...normalizeData(data?.paginatedProfiles?.nodes)
      ])
    }

    if (data?.paginatedProfiles?.pageInfo) {
      setNextPageCursor(
        data?.paginatedProfiles?.pageInfo?.endCursor || undefined
      )
    }
  }, [data])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [nextPageCursor])

  useEffect(() => {
    if (destroyed?.destroyedProfile?.success) {
      notify({
        type: 'success',
        description: 'Usuário removido'
      })
    }

    setModalData(undefined)
  }, [destroyed, error])

  function handleScroll() {
    if (
      !loading &&
      !!nextPageCursor &&
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight
    )
      return getProfiles({
        variables: {
          after: nextPageCursor
        }
      })
  }

  const normalizeData = (data: any) =>
    data?.reduce((acc: UsersType.TableUser[], iteratee: UsersType.User) => {
      acc.push({
        id: iteratee.id,
        name: iteratee.name,
        role: handleRole(iteratee.adminRole, iteratee.negotiatorRole),
        company: iteratee?.company?.name
      })

      return acc
    }, [])

  const handleRole: UsersType.HandleRole = (admin, negotiator) => {
    return admin || negotiator
      ? `${admin ? 'Administrador' : ''}${admin && negotiator ? '/' : ''}${
          negotiator ? 'Negociador' : ''
        }`
      : 'Nenhum'
  }

  const handleColumns = () => {
    const columns: TableType.ColumnsType[] = [
      { title: 'Nome', dataField: 'name', isKey: true, sortable: true },
      { title: 'Papel', dataField: 'role', sortable: true },
      {
        actions: [
          {
            classname: 'icon-note',
            action: (row) => history.push(`/usuario/${row.id}`)
          },
          {
            classname: 'icon-trash ml-2',
            action: (row) => {
              if (user?.profile?.id !== row.id) {
                setModalData({
                  title: 'Remover usuário?',
                  message: 'Esta ação não pode ser revertida.',
                  onCancel: () => setModalData(undefined),
                  onConfirm: () => {
                    setModalData({
                      ...modalData,
                      loading: destroying,
                      title: 'Removendo usuário'
                    })

                    destroyProfile({ variables: { id: row.id } })
                  }
                })
              }

              if (user?.profile?.id === row.id) {
                alert('Você não pode remover sua própria conta')
              }
            }
          }
        ]
      }
    ]

    if (currentUser?.currentUser?.role === 'back_office') {
      columns.splice(1, 0, {
        title: 'Empresa',
        dataField: 'company',
        sortable: true
      })
    }

    return columns
  }

  return (
    <Card>
      <CardHeader>Usuários</CardHeader>
      <CardBody>
        <ShouldRender if={!loadingCurrentUser}>
          <React.Fragment>
            <ShouldRender if={currentUser?.currentUser?.role !== 'back_office'}>
              <Row className='flex-row-reverse mb-4'>
                <Col sm={3} xl={2}>
                  <Button
                    block
                    color='success'
                    onClick={() => history.push('/usuario')}
                  >
                    Adicionar
                  </Button>
                </Col>
              </Row>
            </ShouldRender>
          </React.Fragment>
        </ShouldRender>
        <ShouldRender if={loadingCurrentUser}>
          <Spinner />
        </ShouldRender>

        <ShouldRender if={!loadingCurrentUser}>
          <Table
            data={profiles as any}
            columns={handleColumns()}
            loading={loading}
          />
        </ShouldRender>
      </CardBody>

      <WarningModal modalData={modalData} />
    </Card>
  )
}

export default Users
