import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const mutation = gql`
  mutation UploadImage(
    $checkQuality: Boolean!
    $file: String!
    $kind: ImageKind!
  ) {
    uploadedImage: uploadImage(
      checkQuality: $checkQuality
      file: $file
      kind: $kind
    )
  }
`

export default () => {
  const [uploadImage, result] = useMutation(mutation, { onError: () => null })

  return [uploadImage, result]
}
