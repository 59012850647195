import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const query = gql`
  query Company {
    company {
      address
      balance
      complement
      district
      document
      city {
        value: id
        label: name
      }
      email
      id
      kind
      name
      fantasyName
      number
      phone
      stateRegistration
      zipCode
    }
  }
`

export default () => {
  return useQuery(query, { fetchPolicy: 'no-cache' })
}
