import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const query = gql`
  query CompactVehicles {
    list: vehicles {
      id
      company {
        name
      }
      licensePlate
      brand: vehicleBrand {
        description
      }
      model: vehicleModel {
        description
      }
      category: vehicleCategory {
        description
      }
    }
  }
`

export default () =>
  useQuery(query, {
    fetchPolicy: 'no-cache'
  })
