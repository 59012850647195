import React from 'react'
import { cellphone, cpf } from 'utils/mask'
import { getFormikError } from 'services'
import { Row, Col, Button } from 'reactstrap'
import { TextInput, CheckboxGroup, ShouldRender, Spinner } from 'components'
import { useProfile } from 'queries'

const Form: React.FC<UserType.Props> = ({ id, formik, loading }) => {
  const { data: user, loading: loadingUser } = useProfile()

  const {
    errors,
    values,
    getFieldMeta,
    getFieldProps,
    setFieldValue,
    setFieldError,
    handleSubmit
  } = formik

  return (
    <React.Fragment>
      <ShouldRender if={loadingUser}>
        <Spinner />
      </ShouldRender>

      <ShouldRender if={!loadingUser}>
        <React.Fragment>
          <Row>
            <TextInput
              size={6}
              label='Nome completo'
              {...getFieldProps('name', 'text')}
              error={getFormikError(getFieldMeta, 'name')}
            />

            <TextInput
              size={6}
              label='CPF'
              disabled={id === user?.profile?.id}
              {...getFieldProps('document', 'text')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('document', cpf(e.target.value))
              }}
              error={getFormikError(getFieldMeta, 'document')}
            />

            <TextInput
              size={6}
              label='E-mail'
              {...getFieldProps('email', 'text')}
              error={getFormikError(getFieldMeta, 'email')}
            />

            <TextInput
              size={6}
              label='Celular'
              {...getFieldProps('mobilePhone', 'text')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('mobilePhone', cellphone(e.target.value))
              }}
              error={getFormikError(getFieldMeta, 'mobilePhone')}
            />
            <ShouldRender if={id !== user?.profile?.id}>
              <CheckboxGroup
                name='roles'
                label='Papel'
                disabled={id === user?.profile?.id}
                value={values['roles']}
                setFieldError={setFieldError}
                setFieldValue={setFieldValue}
                error={errors['roles']}
                options={[
                  { label: 'Administrador', value: 'admin' },
                  { label: 'Negociador', value: 'negotiator' }
                ]}
              />
            </ShouldRender>
          </Row>
          <Row className='flex-row-reverse mb-4'>
            <Col sm={3} xl={2}>
              <Button
                block
                color='success'
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? 'Salvando' : 'Salvar'}
              </Button>
            </Col>
          </Row>
        </React.Fragment>
      </ShouldRender>
    </React.Fragment>
  )
}

export default Form
