import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const query = gql`
  query CurrentRoles {
    current: profile {
      id
      adminRole
      driverRole
      negotiatorRole
    }
  }
`

export default () => useQuery(query, { fetchPolicy: 'network-only' })
