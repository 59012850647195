import React, { useState, Fragment, useEffect } from 'react'
import { Card, CardHeader, CardBody, Col, Container, Row } from 'reactstrap'
import FreightMenu from './FreightMenu'
import {
  FreightEvaluation,
  FreightNegotiation,
  FreightTrip
} from './FreightScreens'
import { Freight } from 'screens'
import { useParams, useHistory } from 'react-router-dom'
import { useLazyCompanyFreightStatus, useLazyFreightOffers } from 'queries'
import { useLazyQuery } from '@apollo/react-hooks'

const FreightDetails: React.FC = () => {
  const { id, currentArea = 'frete' } = useParams()
  const [getFreight, { data: freights, loading: fetching }] = useLazyQuery(
    useLazyCompanyFreightStatus,
    {
      variables: { id },
      fetchPolicy: 'no-cache'
    }
  )

  const [getOffers, { data }] = useLazyQuery(useLazyFreightOffers, {
    variables: { freightId: id },
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (id) {
      getFreight()
      getOffers()
    }
  }, [id])

  const isTripDisabled = () => {
    const freight = freights?.list?.length === 1 ? freights.list[0] : {}
    if (['negotiated', 'ongoing', 'finished'].indexOf(freight.status) > -1)
      return false
    return true
  }
  const isEvaluationDisabled = () => {
    const freight = freights?.list?.length === 1 ? freights.list[0] : {}
    if (freight.status === 'finished') return false
    return true
  }

  const areas: Record<string, FreightsType.AreaType> = {
    frete: { name: 'frete', text: 'Frete', component: <Freight id={id} /> },
    negociacao: {
      name: 'negociacao',
      text: 'Negociação',
      component: <FreightNegotiation id={id} />,
      count: data?.offers?.length
    },
    viagem: {
      name: 'viagem',
      text: 'Viagem',
      component: <FreightTrip id={id!} />,
      disabled: isTripDisabled(),
      disabledMessage:
        'Este item estará acessível quando a negociação for fechada'
    },
    avaliacao: {
      name: 'avaliacao',
      text: 'Avaliação',
      component: <FreightEvaluation id={id!} />,
      disabled: isEvaluationDisabled(),
      disabledMessage:
        'Este item estará acessível quando a viagem for concluída'
    }
  }

  const renderComponent = () => {
    return areas[currentArea].component
  }

  return (
    <Fragment>
      <Row>
        <Col sm={2}>
          <FreightMenu areas={areas} />
        </Col>
        <Col sm={10}>{renderComponent()}</Col>
      </Row>
    </Fragment>
  )
}

export default FreightDetails
