import React from 'react'
import { ListGroup } from 'reactstrap'
import FreightMenuItem from './FreightMenuItem'
import { useParams, useHistory } from 'react-router-dom'
import './style.css'

const FreightMenu: React.FC<FreightsType.FreightMenuType> = ({ areas }) => {
  const { id, currentArea } = useParams()
  const history = useHistory()

  const setCurrentArea = (name: string) => {
    history.push(`/fretes/${id}/${name}`)
  }

  const groupItems = () => {
    return Object.values(areas).map((area: any, index: number) => (
      <FreightMenuItem
        key={index}
        index={index}
        name={area.text}
        active={currentArea == area.name}
        setCurrentArea={() => {
          setCurrentArea(area.name)
        }}
        disabled={area.disabled}
        disabledMessage={area.disabledMessage}
        count={area?.count || undefined}
      />
    ))
  }

  return <ListGroup>{groupItems()}</ListGroup>
}

export default FreightMenu
