import React from 'react'
import Spinner from '../Spinner'
import ShouldRender from '../ShouldRender'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import './style.css'

const WarningModal: React.FC<WarningModalType.Props> = ({ modalData }) => {
  return (
    <Modal
      isOpen={!!modalData}
      toggle={modalData?.onCancel}
      className='lineless-modal'
    >
      <ModalHeader toggle={modalData?.onCancel}>{modalData?.title}</ModalHeader>
      <ShouldRender if={!!modalData?.message}>
        <React.Fragment>
          <ModalBody>{modalData?.message}</ModalBody>

          <ShouldRender if={!!modalData?.loading}>
            <Spinner />
          </ShouldRender>
        </React.Fragment>
      </ShouldRender>
      <ShouldRender if={!modalData?.loading}>
        <ModalFooter>
          <Button onClick={modalData?.onCancel} className='btn-secondary-light'>
            Cancelar
          </Button>
          <Button onClick={modalData?.onConfirm} className='btn-primary-yellow'>
            Confirmar
          </Button>
        </ModalFooter>
      </ShouldRender>
    </Modal>
  )
}

export default WarningModal
