import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const query = gql`
  query ProfileDocumentsStatus {
    list: profileDocuments {
      status
    }
  }
`

export default () => useQuery(query, {
  fetchPolicy: 'no-cache'
})
