import { backOfficeRoutes, regularRoutes } from 'config/routes'
import { Container } from 'reactstrap'
import React from 'react'
import 'react-toastify/dist/ReactToastify.min.css'
import { useCurrentUser } from 'queries'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Header, Sidebar, ShouldRender } from 'components'
import { AppBreadcrumb, AppHeader, AppSidebar } from '@coreui/react'

const MainApp: React.FC = () => {
  const { data } = useCurrentUser()

  const selectRoutes = () => {
    const backOfficeUser = data?.currentUser?.role === 'back_office'

    return backOfficeUser ? backOfficeRoutes : regularRoutes
  }

  return (
    <div className='app'>
      <AppHeader fixed>
        <Header />
      </AppHeader>
      <div className='app-body'>
        <AppSidebar fixed display='lg'>
          <Sidebar />
        </AppSidebar>
        <ShouldRender if={!!data?.currentUser?.role}>
          <main className='main'>
            <AppBreadcrumb appRoutes={selectRoutes()} />
            <Container fluid>
              <Switch>
                {selectRoutes().map((route: any) => {
                  return route.component ? (
                    <Route
                      key={route.path}
                      path={route.path}
                      exact={route.exact}
                      render={(props) => <route.component {...props} />}
                    />
                  ) : null
                })}
                <Redirect
                  to={
                    data?.currentUser?.role === 'back_office'
                      ? '/dashboard'
                      : '/fretes'
                  }
                />
              </Switch>
            </Container>
          </main>
        </ShouldRender>
      </div>
    </div>
  )
}

export default MainApp
