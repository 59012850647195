import * as y from 'yup'
import { useFormik } from 'formik'
import { cpf, cnpj, select } from 'utils/validation'

const useCompany = ({
  onSubmit,
  initialValues,
  documentType
}: FormTypes.Props & { documentType?: string }) => {
  const formik = useFormik({
    onSubmit,
    initialValues,
    enableReinitialize: true,
    validationSchema: y.object().shape({
      document: y
        .string()
        .test(
          'valid-document',
          `${documentType === 'cpf' ? 'CPF' : 'CNPJ'} Inválido`,
          function(value) {
            return value
              ? documentType === 'cpf'
                ? cpf(value)
                : cnpj(value)
              : true
          }
        )
        .required('Obrigatório'),
      phone: y.string().required('Obrigatório'),
      name: y.string().required('Obrigatório'),
      zipCode: y.string().required('Obrigatório'),
      address: y.string().required('Obrigatório'),
      number: y.string().required('Obrigatório'),
      district: y.string().required('Obrigatório'),
      city: select
    })
  })

  return formik
}

export default useCompany
