import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const query = gql`
  query DriverProfile($id: ID) {
    profile: profiles(filter: { id: $id }) {
      id
      name
      city {
        id
        name
        state {
          initials
        }
      }
      completedDriverData
      address
      birthDate
      complement
      district
      document
      driverLicense
      driverLicenseDueDate
      driverLicenseFirstDate
      driverLicenseIssuingState
      driverLicenseRenach
      driverLicenseType {
        id
        description
      }
      email
      employeeTime
      employeeTimeKind
      fatherName
      mobilePhone
      motherName
      nationality
      number
      personalContactKind
      personalContactName
      personalContactPhone
      phone
      professionalContactCompany
      professionalContactKind
      professionalContactName
      professionalContactPhone
      rating
      zipCode
      identityCard
      issuingState
    }
  }
`

export default (params?: any) =>
  useQuery(query, { fetchPolicy: 'no-cache', ...params })
