import React from 'react'
import { Row } from 'reactstrap'
import { TextInput } from 'components'

const CompanyForm: React.FC<{ formik: any }> = ({ formik }) => {
  const { getFieldProps, values } = formik

  return (
    <React.Fragment>
      <Row>
        <TextInput
          size={6}
          disabled
          label={values?.document?.length === '14' ? 'CPF' : 'CNPJ'}
          {...getFieldProps('document', 'text')}
        />
      </Row>
      <Row>
        <TextInput
          size={4}
          disabled
          label='Razão Social'
          {...getFieldProps('name', 'text')}
        />
        <TextInput
          size={4}
          disabled
          label='Nome Fantasia'
          {...getFieldProps('fantasyName', 'text')}
        />

        <TextInput
          size={4}
          disabled
          label='Telefone'
          {...getFieldProps('phone', 'text')}
        />
        <TextInput
          size={6}
          disabled
          label='Endereço'
          {...getFieldProps('address', 'text')}
        />
        <TextInput
          size={4}
          disabled
          label='Complemento'
          {...getFieldProps('complement', 'text')}
        />

        <TextInput
          size={2}
          disabled
          label='Número'
          {...getFieldProps('number', 'text')}
        />
        <TextInput
          size={6}
          disabled
          label='Bairro'
          {...getFieldProps('district', 'text')}
        />

        <TextInput
          size={6}
          disabled
          label='Cidade'
          {...getFieldProps('city', 'text')}
        />

        <TextInput
          size={6}
          disabled
          label='CEP'
          {...getFieldProps('zipCode', 'text')}
        />
      </Row>
    </React.Fragment>
  )
}

export default CompanyForm
