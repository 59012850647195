import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const mutation = gql`
  mutation UpdateProfile(
    $id: ID!
    $address: String
    $birthDate: ISODate
    $city: ID
    $complement: String
    $district: String
    $driverLicense: String
    $document: String
    $driverLicenseDueDate: ISODate
    $driverLicenseFirstDate: ISODate
    $driverLicenseIssuingState: String
    $driverLicenseRenach: String
    $driverLicenseType: ID
    $email: String
    $employeeTime: Int
    $employeeTimeKind: EmployeeTimeKind
    $fatherName: String
    $mobilePhone: String
    $motherName: String
    $name: String
    $nationality: NationalityKind
    $identityCard: String
    $issuingState: String
    $number: String
    $personalContactKind: ProfilePersonalContactKindEnum
    $personalContactName: String
    $personalContactPhone: String
    $phone: String
    $professionalContactCompany: String
    $professionalContactKind: ProfileProfessionalContactKindEnum
    $professionalContactName: String
    $professionalContactPhone: String
    $zipCode: String
  ) {
    updated: updateProfile(
      id: $id
      address: $address
      birthDate: $birthDate
      cityId: $city
      complement: $complement
      district: $district
      driverLicense: $driverLicense
      document: $document
      driverLicenseDueDate: $driverLicenseDueDate
      driverLicenseFirstDate: $driverLicenseFirstDate
      driverLicenseIssuingState: $driverLicenseIssuingState
      driverLicenseRenach: $driverLicenseRenach
      driverLicenseTypeId: $driverLicenseType
      email: $email
      employeeTime: $employeeTime
      employeeTimeKind: $employeeTimeKind
      fatherName: $fatherName
      mobilePhone: $mobilePhone
      motherName: $motherName
      name: $name
      nationality: $nationality
      identityCard: $identityCard
      issuingState: $issuingState
      number: $number
      personalContactKind: $personalContactKind
      personalContactName: $personalContactName
      personalContactPhone: $personalContactPhone
      phone: $phone
      professionalContactCompany: $professionalContactCompany
      professionalContactKind: $professionalContactKind
      professionalContactName: $professionalContactName
      professionalContactPhone: $professionalContactPhone
      zipCode: $zipCode
    ) {
      id
    }
  }
`

export default () => {
  const [updateProfile, result] = useMutation(mutation, {
    onError: () => null,
    refetchQueries: ['LazyDriversList']
  })

  return [updateProfile, result]
}
