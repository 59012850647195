import { company, config, dashboard, freight, users } from 'assets/images'

export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: dashboard
    },
    {
      name: 'Fretes',
      url: '/fretes',
      icon: freight
    },
    {
      name: 'Empresa',
      url: '/empresa',
      icon: company
    },
    {
      name: 'Usuários',
      url: '/usuarios',
      icon: users
    },
    {
      name: 'Configurações',
      url: '/configuracoes',
      icon: config
    },
    {
      name: 'Motoristas',
      url: '/motoristas',
      icon: config
    },
    {
      name: 'Veículos',
      url: '/veiculos',
      icon: config
    },
    {
      name: 'Análises de risco',
      url: '/analises-de-risco',
      icon: config
    }
  ]
}
