import React, { useEffect } from 'react'
import { FormGroup, Input, Label, FormFeedback, Col, Row } from 'reactstrap'

const CheckboxGroup: React.FC<CheckboxGroupType.Props> = ({
  name,
  label,
  value,
  options,
  disabled,
  error,
  setFieldValue,
  setFieldError
}) => {
  const isChecked = (checkboxValue: string) => value.indexOf(checkboxValue) > -1

  const toggleChecked = (checkboxValue: string) => {
    const output = [...value]
    const index = output.indexOf(checkboxValue)
    index > -1 ? output.splice(index, 1) : output.push(checkboxValue)
    setFieldValue(name, output)
  }

  useEffect(() => {
    setFieldError(name, undefined)
  }, [value])

  return (
    <Col sm={12}>
      <FormGroup className='custom-form-group'>
        <Label>{label}</Label>

        <Row>
          {options.map((option: Record<string, any>) => (
            <Col md={4} sm={6} key={option.value}>
              <FormGroup check>
                <FormGroup>
                  <Label check>
                    <Input
                      disabled={disabled}
                      type='checkbox'
                      checked={isChecked(option.value)}
                      onChange={() => toggleChecked(option.value)}
                    />
                    {option.label}
                  </Label>
                </FormGroup>
              </FormGroup>
            </Col>
          ))}
        </Row>

        <FormFeedback className={`form-message ${error ? 'show-error' : ''}`}>
          {error}
        </FormFeedback>
      </FormGroup>
    </Col>
  )
}

export default CheckboxGroup
