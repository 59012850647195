import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const query = gql`
  query Cities($name: String, $id: ID) {
    cities(filter: { name: $name }, id: $id) {
      value: id
      label: name
      state {
        country {
          name
        }
      }
    }
  }
`

export default () => {
  return useQuery(query, { fetchPolicy: 'cache-first' })
}
