import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const mutation = gql`
  mutation ResendAnalysis($id: ID!) {
    resendRiskAnalysis(id: $id) {
      id
    }
  }
`

export default () => {
  const [resendRiskAnalysis, result] = useMutation(mutation, {
    refetchQueries: ['RiskAnalysis'],
    onError: () => null
  })

  return [resendRiskAnalysis, result]
}
