import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Table, ShouldRender, Spinner } from 'components'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { AppSwitch } from '@coreui/react'
import { useLazyPaginatedDriversList } from 'queries'
import { useQuery } from '@apollo/react-hooks'
import { cpf } from 'utils/mask'

const Drivers: React.FC = () => {
  const history = useHistory()
  const [drivers, setDrivers] = useState<any[]>([])
  const [refetching, setRefetching] = useState(false)
  const [nextPageCursor, setNextPageCursor] = useState()
  const [showIncompleteData, setShowIncompleteData] = useState(false)
  const { data, loading, refetch } = useQuery(useLazyPaginatedDriversList, {
    fetchPolicy: 'no-cache',
    variables: {
      after: undefined,
      completedDriverData: showIncompleteData ? false : undefined
    }
  })

  useEffect(() => {
    refetch({
      after: undefined,
      completedDriverData: showIncompleteData ? false : undefined
    })
  }, [refetching])

  useEffect(() => {
    if (data?.list?.nodes) {
      setDrivers((prevState: any) => [
        ...(refetching ? [] : prevState),
        ...normalizeData(data?.list?.nodes)
      ])

      setTimeout(() => setRefetching(false), 500)
    }

    if (data?.list?.pageInfo) {
      setNextPageCursor(data?.list?.pageInfo?.endCursor || undefined)
    }
  }, [data])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [nextPageCursor])

  function handleScroll() {
    if (
      !loading &&
      !!nextPageCursor &&
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight
    ) {
      refetch({
        after: nextPageCursor,
        completedDriverData: showIncompleteData ? false : undefined
      })
    }
  }

  const normalizeData = (data: any) => {
    return data?.map((driver: Record<string, any>) => ({
      ...driver,
      company: driver?.company?.name,
      document: cpf(driver?.document || ''),
      completedDriverData: driver?.completedDriverData ? 'Sim' : 'Não'
    }))
  }

  const columns: TableType.ColumnsType[] = [
    { title: 'Nome', dataField: 'name', isKey: true, sortable: true },
    { title: 'CPF', dataField: 'document', sortable: true },
    { title: 'Empresa', dataField: 'company', sortable: true },
    {
      title: 'Cadastro completo',
      dataField: 'completedDriverData',
      sortable: true
    },
    {
      actions: [
        {
          classname: 'icon-note',
          action: (row) => history.push(`/motoristas/${row.id}`)
        }
      ]
    }
  ]

  return (
    <Card>
      <CardHeader>Motoristas</CardHeader>
      <CardBody>
        <ShouldRender if={refetching}>
          <Spinner />
        </ShouldRender>
        <ShouldRender if={!refetching}>
          <React.Fragment>
            <Row className='mb-4'>
              <Col className='d-flex align-items-center'>
                <AppSwitch
                  className={'mr-2'}
                  variant={'pill'}
                  color={loading ? 'light' : 'warning'}
                  onChange={() => {
                    if (!loading) {
                      setDrivers([])
                      setShowIncompleteData(!showIncompleteData)
                      setRefetching(true)
                    }
                  }}
                  checked={showIncompleteData}
                />
                Exibir apenas cadastros incompletos
              </Col>
            </Row>

            <Table columns={columns} data={drivers} loading={loading} />
          </React.Fragment>
        </ShouldRender>
      </CardBody>
    </Card>
  )
}

export default Drivers
