import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const query = gql`
  query CargoCategories($description: String, $id: ID) {
    categories: cargoCategories(
      filter: { description: $description }
      id: $id
    ) {
      value: id
      label: description
    }
  }
`

export default () => {
  return useQuery(query, { fetchPolicy: 'no-cache' })
}
