import React from 'react'
import { Row, Col, Button } from 'reactstrap'
import { getFormikError } from 'services'
import { TextInput, Select, ShouldRender } from 'components'
import { useDriverLicenseTypes, useLazyCities, useProvinces } from 'queries'
import { cpf, phone, cep, number } from 'utils/mask'
import { useLazyQuery } from '@apollo/react-hooks'

const DriverForm: React.FC<{
  formik: any
  editable?: boolean
  loading?: boolean
}> = ({ formik, editable = false, loading = false }) => {
  const {
    handleSubmit,
    getFieldProps,
    getFieldMeta,
    values,
    setFieldTouched,
    setFieldValue
  } = formik

  const { data: provinces } = useProvinces()
  const { data: driverLicenseTypes } = useDriverLicenseTypes()
  const [
    getCities,
    { data: list, loading: loadingCities }
  ] = useLazyQuery(useLazyCities, { fetchPolicy: 'no-cache' })

  const searchCities = async (inputValue: string, callback: any) => {
    if (!inputValue || inputValue.trim().length < 3) {
      return callback([])
    }

    if (inputValue.trim().length >= 3) {
      getCities({ variables: { name: inputValue } })

      if (list) {
        const options = list?.cities?.map((city: Record<string, any>) => ({
          label: `${city.label}, ${city.state?.initials}`,
          value: city.value
        }))

        callback(options)
      }
    }
  }

  const employeeTimeKindOptions = [
    { label: 'Ano(s)', value: 'year' },
    { label: 'Mês(es)', value: 'month' },
    { label: 'Viagem(ns)', value: 'shipment' }
  ]

  const personalContactKindOptions = [
    { label: 'Esposo(a)', value: 'partner' },
    { label: 'Tio(a)', value: 'uncle' },
    { label: 'Filho(a)', value: 'son' },
    { label: 'Sobrinho(a)', value: 'nephew' },
    { label: 'Vizinho(a)', value: 'neighbor' },
    { label: 'Cunhado(a)', value: 'brother_in_law' },
    { label: 'Amigo(a)', value: 'friend' },
    { label: 'Sogro(a)', value: 'parent_in_law' },
    { label: 'Irmã(o)', value: 'brother' },
    { label: 'Avô(ó)', value: 'grandparent' },
    { label: 'Genro', value: 'son_in_law' },
    { label: 'Nora', value: 'daughter_in_law' },
    { label: 'Pai', value: 'father' },
    { label: 'Mãe', value: 'mother' },
    { label: 'Outro', value: 'others' }
  ]

  const professionalContactKindOptions = [
    { label: 'Administração', value: 'administration' },
    { label: 'Portaria', value: 'lobby' },
    { label: 'Funcionário(a)', value: 'operator' },
    { label: 'Proprietário(a)', value: 'owner' },
    { label: 'Outro', value: 'others' }
  ]

  return (
    <React.Fragment>
      <Row>
        <Col xs='12'>
          <legend>Informações gerais</legend>
        </Col>
      </Row>
      <Row>
        <TextInput
          size={4}
          disabled={!editable}
          label='Nome'
          {...getFieldProps('name', 'text')}
          error={getFormikError(getFieldMeta, 'name')}
        />

        <Select
          size={4}
          label='Nacionalidade'
          name='nationality'
          disabled={!editable}
          value={values['nationality']}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          error={getFormikError(getFieldMeta, 'nationality')}
          options={[
            { label: 'Brasileiro(a)', value: 'national' },
            { label: 'Estrangeiro(a)', value: 'foreign' }
          ]}
        />

        <TextInput
          size={4}
          disabled={!editable}
          label='Telefone'
          {...getFieldProps('mobilePhone', 'text')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('mobilePhone', phone(e.target.value))
          }}
          error={getFormikError(getFieldMeta, 'mobilePhone')}
        />

        <TextInput
          size={4}
          disabled={!editable}
          label='E-mail'
          {...getFieldProps('email', 'text')}
          error={getFormikError(getFieldMeta, 'email')}
        />

        <TextInput
          size={4}
          disabled={!editable}
          type='date'
          label='Data de nascimento'
          {...getFieldProps('birthDate', 'text')}
          error={getFormikError(getFieldMeta, 'birthDate')}
        />

        <TextInput
          size={4}
          disabled
          label='Avaliação média'
          {...getFieldProps('rating', 'text')}
        />
      </Row>
      <Row>
        <Col xs='12'>
          <legend>Tempo na empresa atual</legend>
        </Col>
      </Row>

      <Row>
        <TextInput
          size={4}
          disabled={!editable}
          label='Tempo'
          {...getFieldProps('employeeTime', 'text')}
          error={getFormikError(getFieldMeta, 'employeeTime')}
        />

        <Select
          size={4}
          label='Unidade de medida'
          name='employeeTimeKind'
          disabled={!editable}
          value={values['employeeTimeKind']}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          error={getFormikError(getFieldMeta, 'employeeTimeKind')}
          options={employeeTimeKindOptions}
        />
      </Row>
      <hr className='my-3' />
      <Row>
        <Col xs='12'>
          <legend>Documentos</legend>
        </Col>
      </Row>
      <Row>
        <TextInput
          size={3}
          disabled={!editable}
          label='CPF'
          {...getFieldProps('document', 'text')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('document', cpf(e.target.value))
          }}
          error={getFormikError(getFieldMeta, 'document')}
        />

        <TextInput
          size={3}
          disabled={!editable}
          label='CNH'
          {...getFieldProps('driverLicense', 'text')}
          error={getFormikError(getFieldMeta, 'driverLicense')}
        />
        <TextInput
          size={3}
          disabled={!editable}
          type='date'
          label='Vencimento da CNH'
          {...getFieldProps('driverLicenseDueDate', 'text')}
          error={getFormikError(getFieldMeta, 'driverLicenseDueDate')}
        />

        <TextInput
          size={3}
          disabled={!editable}
          type='date'
          label='Primeira habilitação'
          {...getFieldProps('driverLicenseFirstDate', 'text')}
          error={getFormikError(getFieldMeta, 'driverLicenseFirstDate')}
        />

        <Select
          size={3}
          label='Estado emissor da CNH'
          name='driverLicenseIssuingState'
          disabled={!editable}
          value={values['driverLicenseIssuingState']}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          error={getFormikError(getFieldMeta, 'driverLicenseIssuingState')}
          options={provinces?.list?.map((province: any) => ({
            label: province.name,
            value: province.name
          }))}
        />

        <Select
          size={4}
          label='Tipo de CNH'
          name='driverLicenseType'
          disabled={!editable}
          value={values['driverLicenseType']}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          error={getFormikError(getFieldMeta, 'driverLicenseType')}
          options={driverLicenseTypes?.types || []}
        />

        <TextInput
          size={3}
          disabled={!editable}
          label='Renach'
          {...getFieldProps('driverLicenseRenach', 'text')}
          error={getFormikError(getFieldMeta, 'driverLicenseRenach')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('driverLicenseRenach', number(e.target.value))
          }}
        />

        <TextInput
          size={3}
          disabled={!editable}
          label='RG'
          {...getFieldProps('identityCard', 'text')}
          error={getFormikError(getFieldMeta, 'identityCard')}
        />

        <TextInput
          size={3}
          disabled={!editable}
          label='UF emissora do RG'
          {...getFieldProps('issuingState', 'text')}
          error={getFormikError(getFieldMeta, 'issuingState')}
        />

        <TextInput
          size={4}
          disabled={!editable}
          label='Nome da mãe'
          {...getFieldProps('motherName', 'text')}
          error={getFormikError(getFieldMeta, 'motherName')}
        />

        <TextInput
          size={4}
          disabled={!editable}
          label='Nome do pai'
          {...getFieldProps('fatherName', 'text')}
          error={getFormikError(getFieldMeta, 'fatherName')}
        />
      </Row>
      <hr className='my-3' />
      <Row>
        <Col xs='12'>
          <legend>Endereço</legend>
        </Col>
      </Row>
      <Row>
        <TextInput
          size={6}
          disabled={!editable}
          label='Logradouro'
          {...getFieldProps('address', 'text')}
          error={getFormikError(getFieldMeta, 'address')}
        />
        <TextInput
          size={4}
          disabled={!editable}
          label='Complemento (opcional)'
          {...getFieldProps('complement', 'text')}
        />

        <TextInput
          size={2}
          disabled={!editable}
          label='Número'
          {...getFieldProps('number', 'text')}
          error={getFormikError(getFieldMeta, 'number')}
        />
        <TextInput
          size={4}
          disabled={!editable}
          label='Bairro'
          {...getFieldProps('district', 'text')}
          error={getFormikError(getFieldMeta, 'district')}
        />

        <Select
          size={{ xs: 12, sm: 6, md: 4 }}
          label='Cidade'
          name='city'
          loading={loadingCities}
          disabled={!editable}
          value={values['city']}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          error={getFormikError(getFieldMeta, 'city')}
          loadOptions={searchCities}
        />

        <TextInput
          size={4}
          disabled={!editable}
          label='CEP'
          {...getFieldProps('zipCode', 'text')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('zipCode', cep(e.target.value))
          }}
          error={getFormikError(getFieldMeta, 'zipCode')}
        />
      </Row>

      <hr className='my-3' />
      <Row>
        <Col xs='12'>
          <legend>Contato pessoal</legend>
        </Col>
      </Row>
      <Row>
        <Select
          size={4}
          label='Tipo de contato'
          disabled={!editable}
          name='personalContactKind'
          value={values['personalContactKind']}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          error={getFormikError(getFieldMeta, 'personalContactKind')}
          options={personalContactKindOptions}
        />

        <TextInput
          size={4}
          disabled={!editable}
          label='Nome'
          {...getFieldProps('personalContactName', 'text')}
          error={getFormikError(getFieldMeta, 'personalContactName')}
        />

        <TextInput
          size={4}
          disabled={!editable}
          label='Telefone'
          {...getFieldProps('personalContactPhone', 'text')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('personalContactPhone', phone(e.target.value))
          }}
          error={getFormikError(getFieldMeta, 'personalContactPhone')}
        />
      </Row>

      <hr className='my-3' />
      <Row>
        <Col xs='12'>
          <legend>Contato profissional</legend>
        </Col>
      </Row>
      <Row>
        <Select
          size={3}
          label='Tipo de contato'
          disabled={!editable}
          name='professionalContactKind'
          value={values['professionalContactKind']}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          error={getFormikError(getFieldMeta, 'professionalContactKind')}
          options={professionalContactKindOptions}
        />

        <TextInput
          size={3}
          disabled={!editable}
          label='Empresa'
          {...getFieldProps('professionalContactCompany', 'text')}
          error={getFormikError(getFieldMeta, 'professionalContactCompany')}
        />

        <TextInput
          size={3}
          disabled={!editable}
          label='Nome'
          {...getFieldProps('professionalContactName', 'text')}
          error={getFormikError(getFieldMeta, 'professionalContactName')}
        />

        <TextInput
          size={3}
          disabled={!editable}
          label='Telefone'
          {...getFieldProps('professionalContactPhone', 'text')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('professionalContactPhone', phone(e.target.value))
          }}
          error={getFormikError(getFieldMeta, 'professionalContactPhone')}
        />
      </Row>
      <Row className='flex-row-reverse mb-4'>
        <ShouldRender if={editable}>
          <Col sm={3} xl={2}>
            <Button
              block
              color='success'
              disabled={loading}
              onClick={handleSubmit}
            >
              {loading ? 'Salvando' : 'Salvar'}
            </Button>
          </Col>
        </ShouldRender>
      </Row>
    </React.Fragment>
  )
}

export default DriverForm
