import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const query = gql`
  query Rating($freightId: ID) {
    list: offers(freightId: $freightId) {
      id
      freight {
        rating {
          driverComment
          driverRating
          companyRating
          companyComment
        }
        status
      }
    }
  }
`

export default (params?: any) =>
  useQuery(query, { fetchPolicy: 'no-cache', ...params })
