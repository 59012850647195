import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const query = gql`
  query RiskAnalysis($status: RiskAnalysisStatus) {
    list: riskAnalyses(filter: { status: $status }) {
      id
      data
      description
      dueDate
      kind
      status
      provider
      profile {
        id
      }
      vehicle {
        id
      }
      offer {
        freight {
          id
        }
      }
    }
  }
`
export default (params?: Record<string, any>) =>
  useQuery(query, { fetchPolicy: 'no-cache', ...params })
