import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const query = gql`
  query ProfileDocuments($profileId: ID) {
    list: profileDocuments(profileId: $profileId) {
      id
      kind
      status
      url
    }
  }
`

export default (params?: Record<string, any>) =>
  useQuery(query, {
    fetchPolicy: 'no-cache',
    ...params
  })
