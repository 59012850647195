import React, { Fragment, useState } from 'react'
import { ListGroupItem, Tooltip } from 'reactstrap'
import { ShouldRender } from 'components'
import './style.css'

const FreightMenuItem: React.FC<FreightsType.FreightMenuItemType> = ({
  index,
  name,
  active,
  setCurrentArea,
  disabledMessage,
  disabled,
  count
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)
  return (
    <Fragment>
      <ShouldRender if={!!disabled && !!disabledMessage}>
        <Tooltip
          placement={'right'}
          isOpen={tooltipOpen}
          target={'tooltip-' + index}
          toggle={toggle}
        >
          {disabledMessage}
        </Tooltip>
      </ShouldRender>
      <ListGroupItem
        key={index}
        active={active}
        action
        onClick={() => {
          if (!disabled) setCurrentArea(index)
        }}
        id={'tooltip-' + index}
        className={`d-flex justify-content-between ${
          disabled ? 'unavailable' : ''
        }`}
      >
        {name}
        <ShouldRender if={!!count}>
          <span className='badge badge-primary badge-pill pt-1 text-light bg-dark'>
            {count ? (count > 9 ? '9+' : count) : ''}
          </span>
        </ShouldRender>
      </ListGroupItem>
    </Fragment>
  )
}

export default FreightMenuItem
