import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const query = gql`
  query VehicleCategories($description: String, $id: ID) {
    list: vehicleCategories(filter: { description: $description }, id: $id) {
      value: id
      label: description
      kind
    }
  }
`

export default () => useQuery(query, { fetchPolicy: 'no-cache' })
