import * as y from 'yup'
import { useFormik } from 'formik'

const useTecnoriskConfig: FormTypes.Hook = ({ onSubmit, initialValues }) => {
  const formik = useFormik({
    onSubmit,
    initialValues,
    enableReinitialize: true,
    validationSchema: y.object().shape({
      login: y.string().required('Obrigatório'),
      transporter: y.string().required('Obrigatório'),
      transportingUnit: y.string().required('Obrigatório')
    })
  })

  return formik
}

export default useTecnoriskConfig
